import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  LoadScript,
  GoogleMap,
  Marker,
  DrawingManager,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";

const SearchMap = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userLocation } = useContext(GlobalContext);
  const [lonlat, setlonLat] = useState({
    lat: Number(props.userLocation["lat"]),
    lng: Number(props.userLocation["lng"]),
  });
  const [latlngBound, setlatlngBound] = useState({});
  const [direction, setDirection] = useState(null);
  const [zoom, setZoom] = useState(13);
  const [polygons, setPolygons] = useState(undefined);
  // console.log("what is a polygon??", polygons);
  const mapRef = useRef();
  const currentUrlLocation = useRef();
  const [changeZoom, setChangeZoom] = useState(false);
  const [initChange, setInitChange] = useState(false);
  const [reload, setReload] = useState(false);

  const [myLibraries] = useState(["drawing"]);
  const store_id = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (polygons !== undefined && props.isDraw === false) {
      polygons.setMap(null);
      searchPropertyById([], [userLocation.get["title"]]);
      setlonLat({
        lat: Number(userLocation.get["lat"]),
        lng: Number(userLocation.get["lon"]),
      });
    }
  }, [props.isDraw]);

  useEffect(() => {
    if (props.userLocation && Object.keys(props.userLocation).length !== 0) {
      // console.log(props.userLocation, "props.userLocation");
      let query = new URLSearchParams(location.search);
      if (
        location.pathname.includes(`/search`) &&
        props.userLocation &&
        Object.keys(props.userLocation).length !== 0 &&
        props.userLocation["radius"] &&
        query.get("zoom") != "true"
      ) {
        function getBaseLog(x, y) {
          return Math.log(y) / Math.log(x);
        }
        let km = parseInt(props.userLocation["radius"], 10) * 1.609344;
        let zoom = getBaseLog(2, 40000 / (km / 2));
        // console.log(
        //   "zoom 1111 onmap",
        //   "Miles: ",
        //   parseInt(props.userLocation["radius"], 10),
        //   "Km: ",
        //   km,
        //   "Zoom: ",
        //   zoom
        // );
        setZoom(parseInt(zoom));
      }
      setlonLat({
        lat: Number(props.userLocation["lat"]),
        lng: Number(props.userLocation["lng"]),
      });
    }
  }, [props.userLocation]);

  useEffect(() => {
    if (
      props.selectedLocationBound &&
      Object.keys(props.selectedLocationBound).length !== 0
    ) {
      // console.log(props.selectedLocationBound, "props.selectedLocationBound");
      setlatlngBound({
        south: Number(props.selectedLocationBound.south),
        west: Number(props.selectedLocationBound.west),
        north: Number(props.selectedLocationBound.north),
        east: Number(props.selectedLocationBound.east),
      });
    }
  }, [props.selectedLocationBound]);
  // const googleLocation = async () => {
  //   await axios
  //     .get(
  //       `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${"Salem"}&inputtype=textquery&key=${
  //         process.env.REACT_APP_GOOGLE_API_KEY
  //       }`,
  //       function (req, res) {
  //         res.header("Access-Control-Allow-Origin", "*");
  //       }
  //     )
  //     .then((res) => console.log(res, "this is the data from the location"))
  //     .catch((err) => {
  //       console.log(err, "this is location er");
  //     });
  // };

  useEffect(() => {
    let currentUrl = new URLSearchParams(window.location.search.substring(1));

    // googleLocation();
  }, []);

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
    if (!!Object.keys(latlngBound).length) {
      mapRef.current.fitBounds(latlngBound);
    }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  const getMarker = (polygon) => {
    // console.log("what is a polygon?? : ", polygon);
    if (polygons !== undefined) {
      polygons.setMap(null);
    }
    setPolygons(polygon);
    let ptsWithin = props.location.filter((data) =>
      window.google.maps.geometry.poly.containsLocation(
        data["latlng"],
        polygon,
      ),
    );
    // console.log("what is a polygon?? : ", polygon);

    searchPropertyById(
      _.map(ptsWithin, "id"),
      _.uniq(_.map(ptsWithin, "title")),
    );
  };

  const searchPropertyById = (id, city) => {
    if (props.width) {
      let query = new URLSearchParams(location.search);
      query.set("ids", id.toString());
      //query.set("cities", city.toString());
      //query.set("page", 1);
      history.replace({
        pathname: props.location.pathname,
        search: "?" + query.toString(),
      });
      return false;
    } else {
      props.setMobileSelectedPropetyId(id);
      props.setMobilePropertyPopupOpen(true);
    }
  };

  const handleMapClick = (e) => {
    if (props.width) {
      let query = new URLSearchParams(location.search);
      let ids = query.get("ids");
      if (ids) {
        query.set("ids", "");
        history.replace({
          pathname: props.location.pathname,
          search: "?" + query.toString(),
        });
      }
    } else {
      props.setMobilePropertyPopupOpen(false);
      props.setMobileSelectedPropetyId("");
    }
  };

  const onChange = () => {
    if (initChange == true) {
      // console.log("onchange Executeddd!!!!!!!!!!!!");
      if (!mapRef.current) return;
      // let zoom = mapRef.current.getZoom();
      // setZoom(zoom);
      let newPos = mapRef.current.getCenter()?.toJSON();
      let newBounds = mapRef.current.getBounds()?.toJSON();
      // console.log("onchange consoleeeeee===>", newPos, newBounds);
      setlonLat(newPos);
      // console.log(newPos, newBounds, "latLang");
      setChangeZoom(true);
      props.handleMapCenterChange(newPos, newBounds, changeZoom);
      setTimeout(setChangeZoom(false), 5000);
    } else {
      setInitChange(true);
    }
  };

  const getNewLocation = async (latitude, longitude) => {
    try {
      // console.log("google api call executed");
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${
          global?.storeDetails?.g_map_key ||
          process.env.REACT_APP_GOOGLE_API_KEY
        }`,
        function (req, res) {
          res.header("Access-Control-Allow-Origin", "*");
        },
      );
    } catch (err) {
      console.log(err, "this is location er");
    }
  };

  const onBeforeChange = () => {
    setInitChange(true);
  };
  // console.log(props.location, "props.location");
  return (
    <>
      {/* {console.log(
        'on map load check inside search map',
        'lonlat====>',
        lonlat,
        'props.isDraw ===>',
        props.isDraw
      )} */}
      <LoadScript
        id="google-map-script"
        googleMapsApiKey={
          global?.storeDetails?.g_map_key ||
          process.env.REACT_APP_GOOGLE_API_KEY
        }
        libraries={myLibraries}
      >
        {initChange ? (
          <GoogleMap
            center={lonlat}
            mapContainerStyle={{ height: "100%" }}
            zoom={zoom}
            options={{
              zoomControl:
                location.pathname.split("/")[1] === "property" ? true : false,
              fullscreenControl:
                location.pathname.split("/")[1] === "property" ? true : false,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onChange}
            onDragEnd={onChange}
            onClick={handleMapClick}
          >
            {props.isDraw && (
              <DrawingManager
                drawingMode="polygon"
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    drawingModes: ["polygon"],
                  },
                }}
                onPolygonComplete={getMarker}
              />
            )}
            {props.location.map((loc, index) => (
              <Marker
                key={index}
                position={loc.latlng}
                icon={
                  loc["id"] == props.mobileSelectedPropetyId
                    ? "/images/marker.png"
                    : location.pathname.split("/")[1] === "property"
                      ? "/images/outline_location_on_black.png"
                      : "/images/marker.png"
                }
                onClick={(e) => searchPropertyById(loc["id"], loc["title"])}
              />
            ))}
          </GoogleMap>
        ) : (
          <>
            <span
              className="loaderState"
              onClick={onBeforeChange}
              onChange={onBeforeChange}
              onTouchMove={onBeforeChange}
            >
              <GoogleMap
                center={lonlat}
                mapContainerStyle={{ height: "100%" }}
                zoom={zoom}
                options={{
                  zoomControl:
                    location.pathname.split("/")[1] === "property"
                      ? true
                      : false,
                  fullscreenControl:
                    location.pathname.split("/")[1] === "property"
                      ? true
                      : false,
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={handleMapClick}
              >
                {props.isDraw && (
                  <DrawingManager
                    drawingMode="polygon"
                    options={{
                      drawingControl: true,
                      drawingControlOptions: {
                        drawingModes: ["polygon"],
                      },
                    }}
                    onPolygonComplete={getMarker}
                  />
                )}
                {props.location.map((loc, index) => (
                  <Marker
                    key={index}
                    position={loc.latlng}
                    icon={
                      loc["id"] == props.mobileSelectedPropetyId
                        ? "/images/marker.png"
                        : location.pathname.split("/")[1] === "property"
                          ? "/images/outline_location_on_black.png"
                          : "/images/marker.png"
                    }
                    onClick={(e) => searchPropertyById(loc["id"], loc["title"])}
                  />
                ))}
              </GoogleMap>
            </span>
          </>
        )}
      </LoadScript>
    </>
  );
};

export default SearchMap;
