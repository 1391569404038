import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import GridCard from "../../atoms/SearchCard";

const SimilarHomes = (props) => {
  return (
    <div className="recently-added-rapper">
      <h2 className="pp-head mt-5 mb-3">Similar Homes</h2>
      <div className="row grid-row similar-homes">
        {props.similarProperty.map((item, index) => {
          return (
            <div className="col-sm-6 col-md-4 grid-col" key={item.id}>
              <GridCard item={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SimilarHomes;
