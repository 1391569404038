import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../../utils/axiosconfig";

const EmailVerification = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    if (id) {
      Swal.fire({
        title: "Verifing User...",
        didOpen: async () => {
          Swal.showLoading();
          let { data } = await axios.post(`accountactivate/${id}`, {
            headers: { site_id },
          });
          if (data.success === "yes") {
            Swal.fire({
              title: data.reason,
              icon: "success",
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
              onClose: () => {
                history.push(`/login`);
              },
            });
          }
        },
      });
    } else {
      history.push("/");
      console.log("email verification");
    }
  }, []);
  return <></>;
};

export default EmailVerification;
