import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import Checkbox from "../../../components/atoms/CheckBox";
import NotificationsList from "../../../components/molecules/Dashboard/NotificationsList";
import useToggle from "../../../utils/useToggle";

const Notifications = (props) => {
  const [edit, toggleEdit] = useToggle(false);

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">Notifications</h2>
        <div className="float-right">
          <a className={`glv-link ${edit && "edit"}`} onClick={toggleEdit}>
            {edit ? (
              <>
                <i className="fa fa-angle-left"></i> Back
              </>
            ) : (
              <>
                <i className="fa fa-edit"></i> Edit
              </>
            )}
          </a>
        </div>
      </div>
      {!edit ? (
        <NotificationsList />
      ) : (
        <table className="table table-inverse notifications">
          <thead>
            <th>Sl.No</th>
            <th>Notification</th>
            <th>Email</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Bid Submitted.</td>
              <td>
                <Checkbox />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Item Successfully Added to your Watchlist.</td>
              <td>
                <Checkbox />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Please make the payment for the product you won.</td>
              <td>
                <Checkbox />
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>You are outbid on Product.</td>
              <td>
                <Checkbox />
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>New Product Added.</td>
              <td>
                <Checkbox />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </DBLayout>
  );
};

export default Notifications;
