import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import AuctionWonActive from "../../../components/molecules/Dashboard/AuctionWonActive";
import axios from "../../../utils/axiosconfig";

const AuctionsWon = (props) => {
  const [data, setData] = useState([]);
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let { data } = await axios.get("wonAuction", {
      headers: { site_id },
    });
    if (data.status === true) {
      setData(data.result);
    }
  };

  return (
    <DBLayout>
      <div className="auctions-won-wrapper clearfix">
        <h2 className="pp-head float-left">
          Properties Won <span>{data.length} Properties</span>
        </h2>
      </div>
      <div className="ms-wrapper">
        <AuctionWonActive data={data} />
        {/* <RecentlyAdded /> */}
      </div>
    </DBLayout>
  );
};

export default AuctionsWon;
