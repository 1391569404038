import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import AuctionWonCard from "../../atoms/AuctionWonCard";

const AuctionWonActive = ({ data }) => {
  const listings = [
    {
      description:
        "The state of Utah in the United States is home to lots of beautiful National Parks, & Bryce Canyon National Park ranks as three of the most magnificent & awe inspiring.",
      image: "/images/home-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      description:
        "The state of Utah in the United States is home to lots of beautiful National Parks, & Bryce Canyon National Park ranks as three of the most magnificent & awe inspiring.",
      image: "/images/home-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
  ];

  return (
    <>
      {data.map((item, index) => {
        return <AuctionWonCard item={item} key={index} />;
      })}
    </>
  );
};

export default AuctionWonActive;
