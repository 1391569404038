import React from "react";
import MuiPhoneInput from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
      marginBottom: 5,
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "none",
      position: "relative",
      color: "#595959",
      fontFamily: "Avenir-Book",
      paddingBottom: 5,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "none",
    },
    "& label.Mui-focused": {
      color: "#595959",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    "& .MuiOutlinedInput-root": {
      // height: "40px",

      "& input": {
        zIndex: 110,
      },

      "& fieldset": {
        borderColor: "#CFCFCF",
        background: "#fff",

        "& legend": {
          display: "none",
        },
      },
      "&:hover fieldset": {
        borderColor: "var(--primColor)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--primColor)",
      },
    },
  },
}));

const CustomPhone = (props) => {
  const classes = useStyles();
  const shrink = props.shrink ? props.shrink.toString() : "false";
  return (
    <div className={classes.root}>
      <MuiPhoneInput
        value={props.value}
        autoFocus={props.autoFocus}
        name={props.name}
        InputProps={{
          inputProps: props.inputProps,
        }}
        onChange={props.onChange || props.onChange}
        countryCodeEditable={false}
        onBlur={props.onBlur}
        defaultCountry={props.defaultCountry}
        placeholder={props.placeholder}
        autoFormat={true}
        id={props.id}
        label={props.label}
        type="phone"
        size={props.size}
        disabled={props.disabled}
        variant="outlined"
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default CustomPhone;
