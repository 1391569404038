import React, { useEffect, useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Store } from "@material-ui/icons";
import GlobalContext from "../../../context/GlobalContext";

const Footer = ({ store_data }) => {
  const { storeDetails } = useContext(GlobalContext);

  useEffect(() => {
    if (
      window &&
      document &&
      storeDetails?.store?.storeDetails?.hubspot_portal_id &&
      storeDetails?.store?.storeDetails?.hubspot_form_id
    ) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = "//js.hsforms.net/forms/v2.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: storeDetails?.store?.storeDetails?.hubspot_portal_id || "",
          formId: storeDetails?.store?.storeDetails?.hubspot_form_id || "",
          target: "#hubspotForm",
        });
      });
    }
  }, []);

  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="row">
            <div className="col-6 col-md-3 col-lg-2">
              <h4 className="d-none d-md-block">Company</h4>
              <ul className="text-left text-md-left">
                {/* <li>
                  <a href="https://www.doorsey.com/about-us">About Us</a>
                </li> 
                <li>
                  <a href="https://www.doorsey.com/careers">Careers</a>
                </li>
                <li>
                  <a href="https://www.doorsey.com/press">Press</a>
                </li>*/}
                {store_data != (null || undefined) &&
                  storeDetails?.footerCompany?.map((item) => {
                    return (
                      <li key={item.id}>
                        <a href={item.url}>{item.label}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="col-6 col-md-3 col-lg-2 d-none d-md-block">
              <h4>Connect</h4>
              <ul className="list-inline social-icons-list">
                {storeDetails !== (null || undefined) ? (
                  <>
                    {store_data?.store?.fb_url != "" && (
                      <li className="list-inline-item">
                        <a href={store_data?.store?.fb_url} target="_blank">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    )}{" "}
                    {store_data?.store?.twitter_url != "" && (
                      <li className="list-inline-item">
                        <a
                          href={store_data?.store?.twitter_url}
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                    {store_data?.store?.insta_url != "" && (
                      <li className="list-inline-item">
                        <a href={store_data?.store?.insta_url} target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                    {store_data?.store?.linkedin_url != "" && (
                      <li className="list-inline-item">
                        <a
                          href={store_data?.store?.linkedin_url}
                          target="_blank"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    )}
                    {store_data?.store?.pinterest_url != "" && (
                      <li className="list-inline-item">
                        <a
                          href={store_data?.store?.pinterest_url}
                          target="_blank"
                        >
                          <i className="fab fa-pinterest-p"></i>
                        </a>
                      </li>
                    )}
                    {store_data?.store?.yt_url != "" && (
                      <li className="list-inline-item">
                        <a href={store_data?.store?.yt_url} target="_blank">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
            <div className="col-md-6 col-lg-2">
              <>
                <h4 className="d-none d-md-block">Contact</h4>
                <ul>
                  <li>
                    <a href={`tel:${store_data?.store?.phone}`}>
                      {store_data?.store?.phone}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${store_data?.store?.contact_email}`}>
                      {store_data?.store?.contact_email}
                    </a>
                  </li>
                  <li>{store_data?.store?.address}</li>
                </ul>
              </>
            </div>
            <div className="col-lg-2">
              {storeDetails?.store?.storeDetails?.hubspot_portal_id &&
              storeDetails?.store?.storeDetails?.hubspot_form_id ? (
                <div>
                  <h4 className="d-none d-md-block">Updates</h4>
                  <ul>
                    <li>
                      <span>Join our mailing list</span>
                    </li>
                    <li>
                      <div id="hubspotForm"></div>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-2 col-12">
              <img
                src={
                  store_data?.store?.id
                    ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                    : "/images/doorseylogo.png"
                }
                alt=""
                className="footLogo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <ul className="list-inline">
                {store_data?.store?.terms_link ? (
                  <li className="list-inline-item">
                    <a href={store_data?.store?.terms_link} target="_blank">
                      Terms and conditions
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {store_data?.store?.privacy_link ? (
                  <li className="list-inline-item">
                    <a href={store_data?.store?.privacy_link} target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="col-md-5 text-md-right copy-right">
              <span>
                © {new Date().getFullYear()} - {store_data?.store?.name}
              </span>
              <span>
                &nbsp;&nbsp;|&nbsp;&nbsp;All Rights
                Reserved&nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
