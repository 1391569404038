import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { toast } from "react-toastify";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import Layout from "../../../components/molecules/Layout";
import axios from "../../../utils/axiosconfig";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CheckBox from "../../../components/atoms/CheckBox";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import { getPropertyDetails } from "../../../API/propertyDetails";
import GlobalContext from "../../../context/GlobalContext";
import {
  numberWithCommas,
  setDecimalPoint,
  getTimeInterval,
  onImageError,
  onPropertyImageError,
} from "../../../utils";
import { useDropzone } from "react-dropzone";
import { includes } from "lodash";
import isAuthenticate from "../../../utils/auth";

const RegisterToBidAgent = () => {
  const history = useHistory();
  const location = useLocation();
  const financeFile = useRef(null);
  const [filesUpload, setFilesUload] = useState([]);
  const [fileStatus, setFileStatus] = useState([]);
  const [propData, setPropData] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const { userDetails, userLocation } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  const individualFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("uploads", data);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        return res.data.result;
      }
    } catch (err) {
      console.log(err, " file upload err");
    }
  };

  const FinancingFormSchema = Yup.object({
    listingTier: Yup.number(),
    profileType: Yup.string(),
    agent: Yup.string().when(["listingTier", "profileType"], {
      is: (listingTier, profileType) => {
        if (
          (profileType === "customer" && listingTier === 2) ||
          (profileType === "builder" && listingTier === 2) ||
          (profileType === "builder" && listingTier === 3) ||
          (profileType === "customer" && listingTier === 3)
        ) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.string().required("Answer is required"),
      otherwise: Yup.string(),
    }),
    agentEmail: Yup.string().when("agent", {
      is: (agent) => (agent === "yes" ? true : false),
      then: Yup.string()
        .email("Enter Valid Email")
        .max(100, "Maximum 100 characters")
        .required("Agent Email is required"),
    }),
    downPayment: Yup.number().when(["listingTier", "profileType"], {
      is: (listingTier, profileType) => {
        if (
          (profileType === "customer" && listingTier === 3) ||
          (profileType === "builder" && listingTier === 3) ||
          (profileType === "agent" && listingTier === 3)
        ) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.number()
        .typeError("Enter valid percent")
        .required("Down Payment in % is required")
        .test("amount", "% must be greater than zero", (value) => {
          if (value > 0) {
            return true;
          } else {
            return false;
          }
        })
        .test("amount", "% must be smaller than 100", (value) => {
          if (value <= 100) {
            return true;
          } else {
            return false;
          }
        }),
      otherwise: Yup.number(),
    }),
    cashlimit: Yup.number().when(["listingTier", "profileType"], {
      is: (listingTier, profileType) => {
        if (
          (profileType === "customer" && listingTier === 3) ||
          (profileType === "builder" && listingTier === 3) ||
          (profileType === "agent" && listingTier === 3)
        ) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.number()
        .typeError("Enter valid number")
        .required("Cash limit is required")
        .test("amount", "Cash limit must be greater then zero", (value) => {
          if (value > 0) {
            return true;
          } else {
            return false;
          }
        })
        .test("length", "Maximum Amount 100,000,000", (value) => {
          if (value <= 100000000) {
            return true;
          } else {
            return false;
          }
        }),
      otherwise: Yup.number(),
    }),
    financing: Yup.array().when(["listingTier", "profileType"], {
      is: (listingTier, profileType) => {
        if (
          (profileType === "customer" && listingTier === 3) ||
          (profileType === "builder" && listingTier === 3) ||
          (profileType === "agent" && listingTier === 3)
        ) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.array()
        .min(1, "Minimum one financing type is required")
        .required("Financing type is required"),
      otherwise: Yup.array(),
    }),
    file: Yup.array().when(["listingTier", "profileType"], {
      is: (listingTier, profileType) => {
        if (
          (profileType === "customer" && listingTier === 3) ||
          (profileType === "builder" && listingTier === 3) ||
          (profileType === "agent" && listingTier === 3)
        ) {
          return true;
        } else {
          return false;
        }
      },
      then: Yup.array()
        .min(1, "No file selected")
        .required("Select a document"),
      otherwise: Yup.array(),
    }),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      file: [],
      cashlimit: "",
      financing: [],
      agentEmail: "",
      downPayment: "",
      agent: "",
      profileType: "",
      listingTier:
        propData && !!Object.keys(propData).length
          ? propData?.listing_tier
          : "",
    },
    validationSchema: FinancingFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      let updateValue = { ...values };
      if (values.financing === "Cash") {
        updateValue.agentEmail = "";
      }
      registerToBidForm({ ...updateValue, uploadDocs: values.file });
    },
  });

  /*const getPropertyData = async (id) => {
    let data = await getPropertyDetails(id);
    if (data.status === true) {
      setPropData(data.result.propertyDetails);
    }
  };*/
  const registerToBidForm = async (data) => {
    let rtbData = {
      propertyID: propData && !!Object.keys(propData).length ? propData.id : "",
      listing_tier:
        propData && !!Object.keys(propData).length ? propData.listing_tier : "",
    };

    if (
      userDetails &&
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      ((userDetails.get.profiletype === "customer" &&
        rtbData.listing_tier === 2) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 2) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "customer" &&
          rtbData.listing_tier === 3))
    ) {
      rtbData.have_agent = data.agent === "yes" ? true : false;
      rtbData.agent_email = data.agentEmail;
    }
    if (
      userDetails &&
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      ((userDetails.get.profiletype === "customer" &&
        rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "agent" && rtbData.listing_tier === 3))
    ) {
      rtbData.down_payment = data.downPayment;
      rtbData.fin_type =
        data.financing.length === 1 ? data.financing[0] : data.financing;
      rtbData.pre_approved = data.cashlimit;
      rtbData.no_fin_type = data?.financing?.includes(`no_fin_type`)
        ? true
        : false;
      data.uploadDocs.map((ele, i) => (rtbData[`fin_doc${i + 1}`] = ele.name));
    }
    try {
      let res = await axios.put("userproperty/Rtb", rtbData, {
        headers: { site_id },
      });
      if (res && res.data && res.data.success) {
        toast.success(res.data.message);
        history.push({
          pathname: `/registertobid-success`,
          state: {
            path: `/property/${propData?.id}`,
            propertyDtls: propData,
            values: location?.state?.values,
            productType: location?.state?.productType,
            from: location?.state?.from,
          },
        });
      }
    } catch (err) {
      console.log(err, "this is reg to bid form error");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles;
      let currentFileList = [...values.file];
      // setFieldTouched("file", true);
      if (files.length > 0 && files.length + currentFileList.length <= 5) {
        setFieldValue("file", [{}]);
        setUploadedFileCount(files.length + currentFileList.length);
        if (currentFileList.length < 5) {
          let temp = [...currentFileList];
          files.map(async (file) => {
            if (currentFileList.length < 5) {
              let receivedData = await finacialDocsUpload(file);
              temp.push(receivedData);
            }
          });
          setUploadLoading(true);
          setFieldValue("file", temp);
        }
        // setUploadedFiles(currentFileList);
        // setFieldValue("file", true, true);
        // setFieldError("file", "");
        // setFieldTouched("file", true, true);
      } else {
        // setFieldValue("file", false, false);
        // setFieldTouched("file", true, true);
        // setFieldError("file", "Select a document");
        if (files.length + currentFileList.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
    },
    [values.file],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.map((fileObject) => {
      fileObject.errors.map((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Please only upload supported file types: .pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,
            .csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip`,
          );
        } else if (error.code === "file-too-large") {
          toast.error(
            `Please review the size of ${fileObject.file.name}. File size limit is 20MB`,
          );
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept:
      ".pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,.csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip",
    maxSize: 20971520,
  });

  useEffect(() => {
    if (values.file.length === uploadedFileCount) {
      setUploadLoading(false);
    }
  }, [values.file.length]);

  const finacialDocsUpload = async (file) => {
    // let existingFiles = [...filesUpload];
    let formData = new FormData();
    // file.map((ele) => formData.append("fin_doc", ele));
    formData.append("fin_doc", file);
    try {
      // setUploadLoading(true);
      let res = await axios.post("api/upload_finDoc", formData, {
        headers: { site_id },
      });
      if (res && res.data && res.data.success) {
        return res.data.result;
        // if (!!res.data.result.length) {
        //   existingFiles = [...filesUpload, ...res.data.result];
        // } else {
        //   existingFiles.push(res.data.result);
        // }
        // setFileStatus(existingFiles);
        // // createFileObjects(existingFiles);
        // setUploadLoading(false);
      }
    } catch (err) {
      console.log(err, "file upload err");
      // setUploadLoading(false);
    }
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber.length) {
        return (
          <>
            <div className="addressLine1">
              {`${addressArray[0]}, ${unitNumber}, ${addressArray[1]},`}
              <br />
              {`${addressArray[2]?.trim().split(" ")[0] || ""} ${
                zipcode || ""
              }`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">
            {`${addressArray[0]},`}
            <br />
            {`${city}, ${state}, ${zipcode || ""}`}
          </div>
        );
      } else {
        return (
          <>
            <div className="addressLine1" style={{ width: "100%" }}>
              {`${addressArray[0]},`}
              <br />
              {`${addressArray[1]},`}
              {`${addressArray[2]?.trim().split(" ")[0] || ""} ${
                zipcode || ""
              }`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      }
    } else {
      return address;
    }
  };

  const financeFileUploadBtn = () => {
    financeFile.current.click();
  };
  const financeFileUpload = async (e) => {
    if (e.target.files.length > 0)
      if (filesUpload.length + e.target.files.length <= 5) {
        setUploadLoading(true);
        await finacialDocsUpload([...e.target.files]);
        financeFile.current.value = "";
      } else {
        if (e.target.files.length + filesUpload.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
  };

  // const handleFileRemoval = (index) => {
  //   let existFile = [...filesUpload];
  //   let fileIndex = existFile.findIndex((ele, i) => i === index);
  //   existFile.splice(fileIndex, 1);
  //   setFilesUload(existFile);
  // };

  const handleFileRemoval = (index) => {
    let currentFiles = [...values.file];
    currentFiles.splice(index, 1);
    setFieldValue("file", currentFiles);
  };

  // const createFileObjects = (fileNameArray) => {
  //   let documents = [];
  //   fileNameArray.map(async (doc) => {
  //     if (doc) {
  //       let file = await fetch(
  //         `${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${doc.name}`
  //       )
  //         .then((r) => r.blob())
  //         .then((blobFile) => new File([blobFile], doc, { type: "image/png" }));

  //       documents.push({ name: file.name, originalname: file.name });
  //     }
  //   });
  //   setFileStatus(documents);
  // };

  useEffect(() => {
    if (
      location &&
      location.state &&
      !!Object.keys(location.state.propertyDtls).length
    ) {
      setFieldValue("listingTier", location.state.propertyDtls.listing_tier);
      setPropData(location?.state?.propertyDtls);
    }
  }, [location]);

  useEffect(() => {
    if (
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      propData &&
      !!Object.keys(propData).length
    ) {
      if (propData.exisingRtb && !!Object.keys(propData.exisingRtb).length) {
        let alrdyUploadFile = Object.keys(propData.exisingRtb)
          .filter((ele) => ele.slice(0, 7) === "fin_doc")
          .map((ele) => {
            if (propData.exisingRtb[ele] !== null) {
              return {
                originalname: propData.exisingRtb[ele],
                name: propData.exisingRtb[ele],
              };
            }
          })
          .filter((ele) => ele !== undefined);

        // setFileStatus(alrdyUploadFile);
        // createFileObjects(alrdyUploadFile);
        setFieldValue("file", alrdyUploadFile);
        setFieldValue("listingTier", propData.listing_tier);
        setFieldValue(
          "financing",
          propData?.exisingRtb?.fin_type !== null
            ? [...propData.exisingRtb.fin_type.split(",")]
            : [],
        );
        setFieldValue(
          "cashlimit",
          propData.exisingRtb.pre_approved !== null
            ? propData.exisingRtb.pre_approved
            : "",
        );
        setFieldValue(
          "agentEmail",
          propData.exisingRtb.agent_email !== null &&
            propData.exisingRtb.have_agent === "true"
            ? propData.exisingRtb.agent_email
            : "",
        );
        setFieldValue(
          "agent",
          propData.exisingRtb.have_agent !== null &&
            propData.exisingRtb.have_agent === "true"
            ? "yes"
            : propData.exisingRtb.have_agent !== null &&
                propData.exisingRtb.have_agent === "false"
              ? "no"
              : "",
        );
      }
    }
  }, [propData]);

  // useEffect(() => {
  //   if (fileStatus && !!fileStatus.length) {
  //     setFieldValue("file", fileStatus);
  //   }
  // }, [fileStatus]);

  console.log(values, "thisis values");

  useEffect(() => {
    if (userDetails && !!Object.keys(userDetails.get).length) {
      setFieldValue("profileType", userDetails.get.profiletype);
    }
  }, [userDetails]);

  const handleFinanceChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      if (name === `no_fin_type`) {
        setFieldValue("financing", [name]);
      } else {
        setFieldValue("financing", [
          ...values.financing.filter((ele) => ele !== `no_fin_type`),
          name,
        ]);
      }
    } else {
      setFieldValue(
        "financing",
        values.financing.filter((v) => v !== name),
      );
    }
  };

  const handleImageClick = async (imageName) => {
    let image = {
      images: imageName,
    };
    try {
      let res = await axios.post(
        "api/get_image",
        { images: imageName },
        {
          headers: { site_id },
        },
      );
      if (res.data?.success === true) {
        window.open(res?.data?.url[0][imageName]);
      }
    } catch (e) {
      console.log("error on Image click secure image", e);
    }
  };

  return (
    <Layout>
      <div className="container auth-container">
        {console.log("propData", propData, location.state)}
        <div className="auth-wrapper  reg-to-bid-wrapper reg-bid-success-wrp">
          {propData && !!Object.keys(propData).length && (
            <>
              <div>
                <a
                  className="back-to-list"
                  onClick={() => {
                    history.push(`/property/${propData.id}`);
                  }}
                >
                  {" "}
                  <i className="fa fa-angle-left"></i> Back to listing
                </a>
                <p className="reg-bid-title">Register to Purchase</p>
              </div>
              <div className="back-to-prop reg-bid-prop">
                <img
                  className="prop-img"
                  src={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${propData.avatar}`}
                  onError={(e) => onPropertyImageError(e)}
                />
                {propData.house_number
                  ? constructAddressComponent(
                      propData.loc_address,
                      propData.zipcode,
                      propData.house_number,
                    )
                  : constructAddressComponent(
                      propData.loc_address,
                      propData.zipcode,
                      "",
                      propData.loc_city,
                      propData.state,
                    )}
              </div>
            </>
          )}
          <div className="back-to-prop reg-bid-form">
            <form onSubmit={handleSubmit}>
              {userDetails &&
                userDetails.get &&
                !!Object.keys(userDetails.get).length &&
                propData &&
                !!Object.keys(propData).length &&
                ((userDetails.get.profiletype === "customer" &&
                  propData.listing_tier === 2) ||
                  (userDetails.get.profiletype === "builder" &&
                    propData.listing_tier === 2) ||
                  (userDetails.get.profiletype === "builder" &&
                    propData.listing_tier === 3) ||
                  (userDetails.get.profiletype === "customer" &&
                    propData.listing_tier === 3)) && (
                  <>
                    <div className="reg-bid-files">
                      <div className="rtg-agent">
                        <p>Do you already have a agent?</p>
                        <div className="form-group">
                          <div className="finance-checkboxes">
                            <CheckBox
                              label="Yes"
                              value="yes"
                              checked={values.agent === "yes"}
                              onchange={(e) => setFieldValue("agent", "yes")}
                            />
                            <CheckBox
                              label="No"
                              value="no"
                              checked={values.agent === "no"}
                              onchange={(e) => setFieldValue("agent", "no")}
                            />
                          </div>
                        </div>
                        {errors.agent && touched.agent ? (
                          <div className="invalid-feedback">{errors.agent}</div>
                        ) : null}
                      </div>
                    </div>
                    {values.agent === "yes" && (
                      <div className="form-group mt-2">
                        <CustomInput
                          label="Enter your agent's email address"
                          value={values.agentEmail}
                          placeholder="Agent Email"
                          name="agentEmail"
                          size="small"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.agentEmail &&
                            touched.agentEmail &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.agentEmail && touched.agentEmail ? (
                          <div className="invalid-feedback">
                            {errors.agentEmail}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </>
                )}
              {userDetails &&
                userDetails.get &&
                !!Object.keys(userDetails.get).length &&
                propData &&
                !!Object.keys(propData).length &&
                ((userDetails.get.profiletype === "customer" &&
                  propData.listing_tier === 3) ||
                  (userDetails.get.profiletype === "builder" &&
                    propData.listing_tier === 3) ||
                  (userDetails.get.profiletype === "agent" &&
                    propData.listing_tier === 3)) && (
                  <>
                    <div className="priv-policy">
                      <p>
                        {" "}
                        <b>Privacy Notice: </b>The information submitted below
                        is <br />
                        not disclosed to the listing agent or seller. <br />
                        Doorsey uses this information to verify a buyer’s <br />
                        ability to purchase the home they intend to bid on.
                      </p>
                    </div>
                    <div className="reg-bid-files">
                      {/* <div className="upload-files">
                        <p>
                          Upload a pre-approval letter or proof of funds
                          statement.*{" "}
                        </p>

                        <PrimaryButton onClick={financeFileUploadBtn}>
                          Choose up to 5 files
                        </PrimaryButton>
                        <input
                          ref={financeFile}
                          onChange={financeFileUpload}
                          type="file"
                          style={{ display: "none" }}
                          multiple="multiple"
                        />
                      </div>
                      <div className="uploaded-files-list">
                        {console.log("secure image filesUpload", filesUpload)}
                        {uploadLoading ? (
                          <span className="update">
                            <CircularProgress className="loading-hover" />
                          </span>
                        ) : filesUpload.length > 0 ? (
                          filesUpload.map((ele, i) => (
                            <div className="uploaded-files-box">
                              <div
                                className="row text-center mb-2 uploaded-files-box-item"
                                key={i}
                              >
                                <div className="col-10 uploaded-files">
                                  <p>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleImageClick(ele.name)}
                                      // href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${ele.name}`}
                                      // target="_blank"
                                    >
                                      {ele.originalname}
                                    </a>
                                  </p>
                                </div>
                                <span
                                  className="material-icons col-2"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => handleFileRemoval(i)}
                                >
                                  close
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-file"> No file Selected</div>
                        )}
                      </div>
                      {errors.file && touched.file ? (
                        <div className="invalid-feedback">{errors.file}</div>
                      ) : null} */}
                      <div className="mt-2 mb-4 text-center file-upload-reg">
                        <p>
                          Upload a pre-approval letter or proof of funds
                          statement.*{" "}
                        </p>
                        <div style={{ color: "gray", fontSize: "13px" }}>
                          Max capacity is 5 documents
                        </div>
                        <div className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <p className="drgFlsWrpr">
                                <span className="plus-icon">+</span>Drag files
                                or <span className="browse-text">browse</span>
                              </p>
                            )}
                          </div>
                        </div>
                        {uploadLoading ? (
                          <span className="update">
                            <CircularProgress className="loading-hover" />
                          </span>
                        ) : (
                          values.file.length > 0 &&
                          values.file.map((file, index) => (
                            <>
                              {" "}
                              {file && !!Object.keys(file).length && (
                                <div className="uploaded-files-box">
                                  <div
                                    className="row text-center mb-2 uploaded-files-box-item"
                                    key={index}
                                  >
                                    <div className="col-10 uploaded-files">
                                      <p>
                                        <a
                                          // href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${file.name}`}
                                          // target="_blank"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleImageClick(file.name)
                                          }
                                        >
                                          {file.originalname}
                                        </a>
                                      </p>
                                    </div>
                                    <span
                                      className="material-icons col-2"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleFileRemoval(index)}
                                    >
                                      close
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          ))
                        )}
                        <div className="choose-file">
                          {/* <input
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={(e) => handleFileUploadChange(e)}
                      />
                      <label>Choose Documents</label> */}
                          {errors.file && touched.file && !uploadLoading ? (
                            <div className="invalid-feedback">
                              {errors.file}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="typeof-finance">
                        <p>What type of financing will be used?*</p>
                        <div className="form-group">
                          <div className="finance-checkboxes">
                            <CheckBox
                              label="Cash"
                              value="Cash"
                              name="Cash"
                              checked={values.financing.includes("Cash")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Conventional"
                              value="Conventional"
                              name="Conventional"
                              checked={values.financing.includes(
                                "Conventional",
                              )}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="FHA"
                              value="FHA"
                              name="FHA"
                              checked={values.financing.includes("FHA")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="VA"
                              value="VA"
                              name="VA"
                              checked={values.financing.includes("VA")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Bridge loan"
                              value="Bridge_Loan"
                              name="Bridge_Loan"
                              checked={values.financing.includes("Bridge_Loan")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Other"
                              value="Other"
                              name="Other"
                              checked={values.financing.includes("Other")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="I don't know the type of financing"
                              value="no_fin_type"
                              name="no_fin_type"
                              checked={values.financing.includes("no_fin_type")}
                              onchange={handleFinanceChange}
                            />
                          </div>
                          {errors.financing && touched.financing ? (
                            <div className="invalid-feedback">
                              {errors.financing}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mt-2">
                        <CustomInput
                          label="Enter your Downpayment in %"
                          value={values.downPayment}
                          placeholder="Down Payment in %"
                          name="downPayment"
                          size="small"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.downPayment &&
                            touched.downPayment &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.downPayment && touched.downPayment ? (
                          <div className="invalid-feedback">
                            {errors.downPayment}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group mt-2 climit">
                        <PriceInput
                          label="Maximum pre-approval amount or cash limit*"
                          value={values.cashlimit}
                          name="cashlimit"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.cashlimit &&
                            touched.cashlimit &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.cashlimit && touched.cashlimit ? (
                          <div className="invalid-feedback">
                            {errors.cashlimit}
                          </div>
                        ) : null}
                      </div>
                      {/* <p className="m-0">
                        Compare your mortgage rate!{" "}
                        <a
                          href="https://link.doorsey.com/HTL"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click here to get started
                        </a>
                      </p> */}
                    </div>
                  </>
                )}
              <div className="reg-bid-files">
                <div>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
                <div className="rtb-contact-info">
                  <p>
                    For questions or concerns about entering this <br />
                    information, please contact our support team by
                    <br />{" "}
                    <a href={`mailto:${global?.storeDetails?.admin_email}`}>
                      clicking here{" "}
                    </a>{" "}
                    or calling {global?.storeDetails?.phone}.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RegisterToBidAgent;
