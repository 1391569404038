import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import Layout from "../../../components/molecules/Layout";
import GlobalContext from "../../../context/GlobalContext";
import "./landing.css";

const Home = () => {
  const { storeDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const history = useHistory();

  return (
    <Layout>
      <div
        dangerouslySetInnerHTML={{
          __html: storeDetails?.store?.home_page,
        }}
      />
    </Layout>
  );
};

export default Home;
