import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/molecules/Layout";

const SubmitListingSuccess = (props) => {
  return (
    <Layout>
      <div className="sls-wrapper">
        <h1 className="sls-head">Sell Your Home with Doorsey</h1>
        <div className="clearfix aw-steps">
          <div className="clearfix aw-step completed">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Submit House</div>
          </div>
          <div className="clearfix aw-step active">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Approved</div>
          </div>
          <div className="clearfix aw-step">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Payment</div>
          </div>
          <div className="clearfix aw-step">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Create Listing</div>
          </div>
          <div className="clearfix aw-step">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Seller Approval</div>
          </div>
          <div className="clearfix">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Auction Live</div>
          </div>
        </div>
        <div className="thank-you-box">
          <div className="ty-check">
            <i className="fa fa-check"></i>
          </div>
          <div className="ty-head">Thank You!</div>
          <p className="ty-note">
            Your home has been submitted and we will be in touch to discuss the
            next steps shortly.
          </p>
          <Link to="listings" className="go-to-link">
            Go to Dashboard <i className="fa fa-angle-right"></i>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default SubmitListingSuccess;
