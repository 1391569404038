import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";

const SoldAuctions = (props) => {
  const auctions = [
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      sold_on: "21/01/2021",
      sold_price: "$ 556781",
      won_bidder: "Amanda Valdez",
      status: "Paid",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      sold_on: "21/01/2021",
      sold_price: "$ 556781",
      won_bidder: "Amanda Valdez",
      status: "Unpaid",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      sold_on: "21/01/2021",
      sold_price: "$ 556781",
      won_bidder: "Amanda Valdez",
      status: "Paid",
    },
  ];

  return (
    <DBLayout>
      <div className="table-responsive">
        <table className="table table-inverse mt-34">
          <thead>
            <th>Auction Id</th>
            <th>Auction Name</th>
            <th>Sold On</th>
            <th>Sold Price</th>
            <th>Won Bidder</th>
            <th>Status</th>
          </thead>
          <tbody>
            {auctions.map((item, index) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.auction_name}</td>
                  <td>{item.sold_on}</td>
                  <td>{item.sold_price}</td>
                  <td>{item.won_bidder}</td>
                  <td
                    className={`status-text ${item.status === "Paid" ? "text-green" : "text-red"}`}
                  >
                    {item.status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DBLayout>
  );
};

export default SoldAuctions;
