import React, { useRef, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../../components/molecules/AuthLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import RadioBox from "../../../components/atoms/RadioBox";
import axios from "../../../utils/axiosconfig";
import Constants from "../../../utils/constants";
import { showSuccess, showWaring } from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import { checkUser } from "../../../API/userData";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";

const Login = (props) => {
  const history = useHistory();

  const location = useLocation();

  const formRef = useRef();

  const { userLoggedIn, loading, userDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  const userCheckSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .max(100, "Maximum 100 characters")
      .required("Enter Email or User Name"),
  });

  const signinSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .max(100, "Maximum 100 characters")
      .required("Enter Email or User Name"),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .required("Enter Password"),
  });

  const handleEmailData = async (formData) => {
    const body = {
      email: formData.email.trim(),
    };
    loading.set(true);
    if (site_id != (null || undefined)) {
      let data = await checkUser(body, site_id);
      console.log("check user data", data);
      if (data.success === true) {
        if (data.profileCompleted == false) {
          history.push({
            pathname: `/signup`,
            state: {
              action: "userUpdate",
              user_id: data.user_id,
              profileType: data.profiletype,
              values: {
                email: formData.email,
              },
            },
          });
        } else {
          history.push({
            pathname: `/login_auth`,
            state: {
              values: {
                email: formData.email,
              },
            },
          });
        }

        loading.set(false);
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            values: {
              email: formData.email,
            },
          },
        });
        toast.warning(data.message);
        loading.set(false);
      }
      return true;
    }
  };

  const handleLogin = async (formData) => {
    const body = {
      autologin: false,
      username: formData.email.trim()
        ? formData.email.trim()
        : props.location.state?.values?.email?.trim(),
      password: formData.password,
      role: "not required",
    };
    loading.set(true);
    let { data } = await axios.post("/userLogin", body, {
      headers: {
        site_id,
      },
    });
    if (data.success === true) {
      showSuccess("Sucessfully Signin");
      localStorage.setItem("userToken", data.token);
      // setTimeout(() => {
      userLoggedIn.set(true);
      loading.set(false);
      // userDetails.set({
      //   email: data.email,
      //   first_name: data.first_name,
      //   last_name: data.last_name,
      //   user_name: data.username,
      //   profiletype: data.profiletype,
      //   role: data.role,
      //   id: data.userid,
      //   phone: data.phone,
      //   address: data.address1,
      //   companyName: data.companyName,
      //   serviceType: data.serviceType,
      //   created: data.created_at,
      //   aboutme: data.aboutme,
      //   avatar: data.avatar,
      //   limit: data.reserve_amount,
      //   customerid: data.customerid,
      // });
      // console.log(props.location.state, "this is props locaitons");
      history.push(
        // props.location.state ? props.location.state["pathname"] : "/watchlist"
        // props.location.state
        //   ? props.location.state["pathname"] !== "/"
        //     ? props.location.state["pathname"]
        //     : "/getting-started"
        //   : "/getting-started"

        location &&
          location.state &&
          !!Object.keys(location.state).length &&
          location.state?.pathname?.split("/")[1] === "property"
          ? location.state.pathname
          : `/search?${process.env.REACT_APP_DEFAULT_LOCATION}`,
      );
      // }, 3000);
    } else {
      showWaring(data.reason || "Something went wrong");
      loading.set(false);
    }
  };

  // useEffect(() => {
  //   if (location.state?.values?.email != "") {
  //     setFieldvalue("email", location.state?.values?.email);
  //   }
  // }, []);

  return (
    <AuthLayout title="Login">
      <Formik
        initialValues={{
          email: location.state?.values?.email
            ? location.state?.values?.email
            : "",
          password: "",
        }}
        validationSchema={
          window.location.pathname.includes(`/login`) &&
          !window.location.pathname.includes(`/login_auth`)
            ? userCheckSchema
            : window.location.pathname.includes(`/login_auth`)
              ? signinSchema
              : ""
        }
        enableReinitialize
        validateOnChange={true}
        innerRef={formRef}
        onSubmit={
          window.location.pathname.includes(`/login`) &&
          !window.location.pathname.includes(`/login_auth`)
            ? (values) => handleEmailData(values)
            : window.location.pathname.includes(`/login_auth`)
              ? (values) => handleLogin(values)
              : ""
        }
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            className="form m-0"
            onSubmit={handleSubmit}
            noValidate
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            {window.location.pathname.includes(`/login`) &&
            !window.location.pathname.includes(`/login_auth`) ? (
              <>
                <div className="form-group mt-3">
                  <CustomInput
                    label="E-Mail"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.email && touched.email && "is-invalid"
                    }`}
                    placeholder="E-Mail*"
                    required
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
              </>
            ) : window.location.pathname.includes(`/login_auth`) ? (
              <>
                <div className="form-group pos-relative">
                  <p className="usrEml">
                    <Tooltip
                      title={
                        location.state?.values?.email
                          ? location.state?.values?.email
                          : values.email
                      }
                      placement="top"
                      arrow
                    >
                      <span>
                        {location.state?.values?.email
                          ? location.state?.values?.email
                          : values.email}
                      </span>
                    </Tooltip>
                    <Link to={`/login`}>Change Email</Link>
                  </p>
                </div>
                <div className="form-group pos-relative">
                  <CustomInput
                    label="Password"
                    value={values.password}
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.password && touched.password && "is-invalid"
                    }`}
                    placeholder="Enter Password"
                    required
                  />
                  {errors.password && touched.password ? (
                    <div className="invalid-feedback">{errors.password}</div>
                  ) : null}
                  <div className="rm-fpassword">
                    <Link
                      to={`/forgot-password`}
                      className="blue-link float-right"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* <div className="form-group mt-3">
              <RadioBox
                name="role"
                onchange={handleChange}
                value={values.role}
                items={Constants.ROLE}
              />
            </div> */}
            <PrimaryButton
              label={
                window.location.pathname.includes(`/login`) &&
                !window.location.pathname.includes(`/login_auth`)
                  ? "Next"
                  : "Login"
              }
              type="submit"
              disabled={loading.get}
            />
            {window.location.pathname.includes(`/login`) &&
              !window.location.pathname.includes(`/login_auth`) && (
                <>
                  <div className="auth-bottom mt-4 mb-2 text-center">
                    Don't have an account?{" "}
                    <Link to={`/signup`} className="blue-link">
                      Create an account
                    </Link>
                  </div>
                </>
              )}
            <div className="auth-bottom mt-4 mb-2 text-center">
              Missing your activation email? Check spam or{" "}
              <Link to={`/resend-activation`} className="blue-link">
                Resend
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default Login;
