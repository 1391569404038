import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme, maxWidth) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    maxWidth: "46rem",
  },
}));

const RuumrModal = ({
  open,
  handleClose,
  children,
  modaltitle,
  modalClass,
  maxwidth,
}) => {
  const classes = useStyles();

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      maxWidth={maxwidth}
      open={open}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <div className={`modal fade show custom-modal ${modalClass}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              {modaltitle ? (
                <div className="modal-header">
                  <h4 className="modal-title">{modaltitle}</h4>
                  <button type="button" className="close" onClick={handleClose}>
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="close rm-close"
                  onClick={handleClose}
                >
                  <i className="fa fa-times"></i>
                </button>
              )}
              <div className="modal-body cm-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RuumrModal;
