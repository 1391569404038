import React, { useContext } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout } from "../../../API/userData";
import "./Header.css";

const MobileLinks = ({ store_data, props }) => {
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const {
    userLoggedIn,
    userDetails,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    agentVerificationDetails,
  } = useContext(GlobalContext);
  const location = useLocation();
  return (
    <>
      {/* //  userLoggedIn.get === true ? ( 
        <>
          <MenuLinks /> 
           <Link
            onClick={async () => {
              logout();
              localStorage.clear();
              userLoggedIn.set(false);
              userDetails.set({});
              sentInvites.set([]);
              receivedInvites.set([]);
              financeDetails.set({});
              billingDetails.set([]);
              agentVerificationDetails.set({});
              history.push("/");
            }}
            className="lo-link-xs"
          >
            Logout
          </Link>
          <a
            href="https://www.doorsey.com/submit-a-home"
            className="btn btn-primary"
          >
            Submit a Home
          </a>
        </>
      ) : ( */}

      <ul className="navbar-nav ml-auto align-items-md-center nvOptns">
        {/* <li className="nav-item">
        <NavLink to="/" activeClassName="active">
          Home
        </NavLink>
      </li> */}
        {/* <li className="nav-item">
        <a
        href=" https://www.doorsey.com/submit-a-home"
        activeClassName="active"
        >
          Sell
        </a>
      </li> */}
        <li className="nav-item">
          <NavLink to={`/search?${process.env.REACT_APP_DEFAULT_LOCATION}`}>
            View listings
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <a href="https://www.doorsey.com/agents" activeClassName="active">
            Agents
          </a>
        </li>
        <li className="nav-item">
          <a href="https://www.doorsey.com/buy" activeClassName="active">
            Buy
          </a>
        </li>
        <li className="nav-item">
          <a href="https://www.doorsey.com/community" activeClassName="active">
            Community
          </a>
        </li> */}
        <li className="nav-item">
          {userLoggedIn.get === true ? (
            <Link
              to=""
              onClick={async () => {
                logout();
                localStorage.clear();
                userLoggedIn.set(false);
                userDetails.set({});
                sentInvites.set([]);
                receivedInvites.set([]);
                financeDetails.set({});
                billingDetails.set([]);
                agentVerificationDetails.set({});
                history.push("/");
              }}
            >
              Logout
            </Link>
          ) : (
            <NavLink
              to={{ pathname: `/login`, state: location }}
              activeClassName="active"
            >
              Login
            </NavLink>
          )}
        </li>
        {/* <li className="nav-item">
        <Link to="/signup" className="btn btn-rg borderViolet">
          Signup
        </Link>
      </li> */}
      </ul>
      <ul className=" nvOptns nav-mob-btns">
        <li className="nav-item">
          {userLoggedIn.get === true ? (
            store_data?.store?.phone ? (
              <button className="phone-no">
                <a href={`tel:${store_data?.store?.phone}`}>
                  Talk to us! <span>{store_data?.store?.phone}</span>
                </a>
              </button>
            ) : null
          ) : (
            ""
            // <button
            //   className="phone-no"
            //   onClick={() =>
            //     history.push({ pathname: "/signup", state: location })
            //   }
            // >
            //   Sign up
            // </button>
          )}
        </li>
        {/* <li className="nav-item">
          <button className="submit-home">
            <a href="https://www.doorsey.com/submit-a-home">Submit home</a>
          </button>
        </li> */}
      </ul>

      {/* ); */}
    </>
  );
};

export default MobileLinks;
