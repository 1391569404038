import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { onAvatarError, numberWithCommas } from "../../../utils";
import { useLocation, useHistory } from "react-router-dom";

const MyInviteList = ({
  type,
  from,
  sentInviteList,
  receivedInviteList,
  handleInviteAction,
  handleOfferAction,
  width,
}) => {
  const [inviteList, setInviteList] = useState([]);

  const compareByDate = (objectA, objectB) => {
    const dateObjectA = Date.parse(objectA.updated_at);
    const dateObjectB = Date.parse(objectB.updated_at);

    let comparison = 0;
    if (dateObjectA > dateObjectB) {
      comparison = -1;
    } else if (dateObjectA < dateObjectB) {
      comparison = 1;
    }
    return comparison;
  };

  useEffect(() => {
    let allInvites = [...sentInviteList, ...receivedInviteList].sort(
      compareByDate,
    );
    setInviteList(allInvites);
  }, [sentInviteList, receivedInviteList]);

  const getButtonLeftAction = (invite) => {
    if (type === "Agent") {
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "grant_to_access_offer" ||
          invite.offer_status === "Bid Access Requested")
      ) {
        return "grant_to_access_offer";
      }
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "Bid Access Approved" ||
          invite.offer_status === "access_to_offer")
      ) {
        return "revoke_to_access_offer";
      }
    } else {
      if (
        invite.relation_status === "invite_accepted" &&
        invite.offer_status === "request_to_access_offer"
      ) {
        return invite.offer_status;
      }
    }
  };

  const getButtonLeftLabel = (invite) => {
    if (type === "Agent") {
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "grant_to_access_offer" ||
          invite.offer_status === "Bid Access Requested")
      ) {
        return "Grant Access To Bid";
      }
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "Bid Access Approved" ||
          invite.offer_status === "access_to_offer")
      ) {
        return "Revoke Access to Bid";
      }
      return "";
    } else {
      if (
        invite.relation_status === "invite_accepted" &&
        invite.offer_status === "request_to_access_offer"
      ) {
        return "Request Access to Bid";
      }
      return "";
    }
  };

  const handleInviteActionButtonClick = (e, action, relation_id) => {
    handleInviteAction({
      action,
      relation_id,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOfferActionButtonClick = (e, action, relation_id) => {
    handleOfferAction({
      action,
      relation_id,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      {inviteList.length > 0
        ? inviteList.map((invite) => (
            <>
              <div className="card prflCmntWrpr client-card">
                {width ? (
                  <>
                    <div className="row">
                      <div className="col-2 media-left client-avatar">
                        <img
                          src={
                            invite.avatar
                              ? process.env.REACT_APP_IMAGE_URL +
                                "uploads/profile/" +
                                invite.avatar
                              : ""
                          }
                          alt={invite.avatar || "no image"}
                          onError={onAvatarError}
                          className="profile-media-img"
                        />
                      </div>
                      <div className="col-5">
                        <div className="client-info">
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {`${invite.first_name} ${invite.last_name}`}
                          </span>
                          <br />
                          <span style={{ fontSize: "11px" }}>
                            {invite.relation_status === "invite_sent" &&
                            invite.offer_status === "Invite Not Accepted Yet"
                              ? "Invite Pending"
                              : type === "Agent"
                                ? invite.AgentCompany
                                : invite.purchasing_power
                                  ? `Purchasing Power: $${numberWithCommas(
                                      invite.purchasing_power,
                                    )}`
                                  : "Purchasing Power: Not Verified"}
                          </span>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="action-button">
                          <div style={{ marginBottom: "5px" }}>
                            <span style={{ fontSize: "13px", color: "gray" }}>
                              {type === "Agent"
                                ? invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                  ? "This agent has invited you as their client"
                                  : ""
                                : invite.relation_status ===
                                      "invite_received" &&
                                    invite.offer_status ===
                                      "Invite Not Accepted Yet"
                                  ? "This buyer has invited you as their agent"
                                  : ""}
                            </span>
                          </div>
                          <div className="row myclients-buttons">
                            <div className="buttonLeft">
                              <>
                                {getButtonLeftLabel(invite) !== "" ? (
                                  <button
                                    className={
                                      getButtonLeftLabel(invite) ===
                                      "Revoke Access to Bid"
                                        ? "btn btn-border register-to-comment revoke-button"
                                        : "btn btn-border register-to-comment"
                                    }
                                    onClick={(e) =>
                                      handleOfferActionButtonClick(
                                        e,
                                        getButtonLeftAction(invite),
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                  >
                                    {getButtonLeftLabel(invite)}
                                  </button>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status === "invite_received" &&
                                invite.offer_status ===
                                  "Invite Not Accepted Yet" ? (
                                  <button
                                    className="btn btn-border register-to-comment"
                                    onClick={(e) =>
                                      handleInviteActionButtonClick(
                                        e,
                                        "accept",
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                  >
                                    Accept
                                  </button>
                                ) : (
                                  ""
                                )}
                                {type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Bid Access Requested" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    {invite.offer_status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Bid Access Approved" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    <span className="material-icons">
                                      check
                                    </span>{" "}
                                    <span>{invite.offer_status}</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                            <div className="buttonRight">
                              <>
                                <button
                                  className="btn btn-border register-to-comment"
                                  onClick={(e) =>
                                    handleInviteActionButtonClick(
                                      e,
                                      invite.relation_status ===
                                        "invite_received" &&
                                        invite.offer_status ===
                                          "Invite Not Accepted Yet"
                                        ? "decline"
                                        : "remove",
                                      invite.relation_api
                                        ? invite.relation_api
                                        : invite.relation_id,
                                    )
                                  }
                                >
                                  {invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                    ? "DECLINE"
                                    : "REMOVE"}
                                </button>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-3 media-left client-avatar">
                        <img
                          src={
                            invite.avatar
                              ? process.env.REACT_APP_IMAGE_URL +
                                "uploads/profile/" +
                                invite.avatar
                              : ""
                          }
                          alt={invite.avatar || "no image"}
                          onError={onAvatarError}
                          className="profile-media-img"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </div>
                      <div className="col-9">
                        <div className="client-info">
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {`${invite.first_name} ${invite.last_name}`}
                          </span>
                          <br />
                          <span style={{ fontSize: "11px" }}>
                            {invite.relation_status === "invite_sent" &&
                            invite.offer_status === "Invite Not Accepted Yet"
                              ? "Invite Pending"
                              : type === "Agent"
                                ? invite.AgentCompany
                                : invite.purchasing_power
                                  ? `Purchasing Power: $${numberWithCommas(
                                      invite.purchasing_power,
                                    )}`
                                  : "Purchasing Power: Not Verified"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="action-button">
                          <div
                            style={{ marginBottom: "5px", textAlign: "center" }}
                          >
                            <span style={{ fontSize: "13px", color: "gray" }}>
                              {type === "Agent"
                                ? invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                  ? "This agent has invited you as their client"
                                  : ""
                                : invite.relation_status ===
                                      "invite_received" &&
                                    invite.offer_status ===
                                      "Invite Not Accepted Yet"
                                  ? "This buyer has invited you as their agent"
                                  : ""}
                            </span>
                          </div>
                          <div className="row myclients-buttons">
                            <div
                              className={
                                getButtonLeftLabel(invite) ===
                                  "Grant Access To Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Revoke Access to Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Request Access to Bid" ||
                                getButtonLeftLabel(invite) === ""
                                  ? "buttonLeft col-8"
                                  : "buttonLeft col-6"
                              }
                            >
                              <>
                                {getButtonLeftLabel(invite) !== "" ? (
                                  <button
                                    className={
                                      getButtonLeftLabel(invite) ===
                                      "Revoke Access to Bid"
                                        ? "btn btn-border register-to-comment revoke-button"
                                        : "btn btn-border register-to-comment"
                                    }
                                    onClick={(e) =>
                                      handleOfferActionButtonClick(
                                        e,
                                        getButtonLeftAction(invite),
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    {getButtonLeftLabel(invite)}
                                  </button>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status === "invite_received" &&
                                invite.offer_status ===
                                  "Invite Not Accepted Yet" ? (
                                  <button
                                    className="btn btn-border register-to-comment"
                                    onClick={(e) =>
                                      handleInviteActionButtonClick(
                                        e,
                                        "accept",
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    Accept
                                  </button>
                                ) : (
                                  ""
                                )}
                                {type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Bid Access Requested" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    {invite.offer_status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Bid Access Approved" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    <span className="material-icons">
                                      check
                                    </span>{" "}
                                    <span>{invite.offer_status}</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                            <div
                              className={
                                getButtonLeftLabel(invite) ===
                                  "Grant Access To Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Revoke Access to Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Request Access to Bid" ||
                                getButtonLeftLabel(invite) === ""
                                  ? "buttonRight col-4"
                                  : "buttonRight col-6"
                              }
                              style={{ marginLeft: "0px", marginRight: "0px" }}
                            >
                              <>
                                <button
                                  className="btn btn-border register-to-comment"
                                  onClick={(e) =>
                                    handleInviteActionButtonClick(
                                      e,
                                      invite.relation_status ===
                                        "invite_received" &&
                                        invite.offer_status ===
                                          "Invite Not Accepted Yet"
                                        ? "decline"
                                        : "remove",
                                      invite.relation_api
                                        ? invite.relation_api
                                        : invite.relation_id,
                                    )
                                  }
                                >
                                  {invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                    ? "DECLINE"
                                    : "REMOVE"}
                                </button>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ))
        : from === "editBidder"
          ? ""
          : "No Invites Sent Or Received"}
    </>
  );
};

export default MyInviteList;
