import React, { useRef, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import CustomInput from "../../atoms/Inputs/CustomInput";
import CheckBox from "../../atoms/CheckBox";
import CustomPhone from "../../atoms/Inputs/CustomPhone";
import PrimaryButton from "../../atoms/PrimaryButton";
import PriceInput from "../../atoms/Inputs/PriceInput";
import MapInput from "../../atoms/Inputs/MapInput";
import GlobalContext from "../../../context/GlobalContext";
import axios from "../../../utils/axiosconfig";
import {
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import { showWaring } from "../../../utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "./submitListing.css";
const SubmitListingForm = (props) => {
  const [currentCountry, setCurrentCountry] = useState("");
  const { userLocation, userDetails } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const dateTimeFormat = "yyyy-MM-DD"; //Thh:mm"

  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const PropertySchema = Yup.object().shape({
    // title: Yup.string().required("Enter Property Name"),
    street: Yup.string().required("Enter Address"),
    // city: Yup.string().required('Enter City'),
    // state: Yup.string().required('Enter State'),
    // zip: Yup.string().required('Enter Zip'),
    lat: Yup.string(),
    lng: Yup.string(),
    home_type: Yup.string(),
    phone: Yup.string()
      .required("Enter Mobile Number")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    target_reverse_price: Yup.string().required(),
    reserve_price: Yup.string().when("target_reverse_price", {
      is: (target_reverse_price) =>
        target_reverse_price === "yes" ? true : false,
      then: Yup.string().test(
        "reserve_price",
        "Enter valid reserve price",
        (value) => {
          if (value > 0) {
            return true;
          } else {
            return false;
          }
        },
      ),
    }),
  });

  const handlePost = async (body) => {
    let formData = new FormData();
    formData.append(
      "userDetails",
      JSON.stringify({
        first_name: userDetails.get && userDetails.get.first_name,
        last_name: userDetails.get && userDetails.get.last_name,
        email: userDetails.get && userDetails.get.email,
        phone: body.phone,
      }),
    );
    formData.append(
      "agentOfProperty",
      body.are_you_listing_agent === "yes"
        ? JSON.stringify({
            first_name: userDetails.get && userDetails.get.first_name,
            last_name: userDetails.get && userDetails.get.last_name,
            email: userDetails.get && userDetails.get.email,
          })
        : "",
    );
    formData.append("projectstatus", "moderate");
    body.target_reverse_price === "yes" &&
      formData.append(
        "rprice",
        body.target_reverse_price === "yes" ? body.reserve_price : 0,
      );
    formData.append("latmap", body.lat);
    formData.append("lngmap", body.lng);
    formData.append("locationaddress", body.street);
    formData.append("locationcity", body.city);
    formData.append("state", body.state);
    formData.append("zipcode", body.zip);
    formData.append(
      "title",
      body.street + ", " + body.city + ", " + body.state,
    );
    formData.append("protype", body.home_type);
    // formData.append('home_page_listing_fee', location.state.selected);
    formData.append("findAgent", body.are_you_listing_agent === "no" ? 0 : 1);
    formData.append("customerDate", body.customerDate);
    let { data } = await axios.post("postproperty", formData, {
      headers: { site_id },
    });
    if (data.success === "no") {
      history.push(`/listings`);
    } else {
      showWaring("Something went wrong");
    }
  };

  useEffect(() => {
    Object.keys(userLocation.get).length !== 0 &&
      setCurrentCountry(userLocation.get["countryCode"].toLowerCase());
  }, [userLocation.get]);

  return (
    <div className="syh-form-wrapper" aria-autocomplete="none">
      <h4>Tell us about your property</h4>
      <Formik
        initialValues={{
          // title: "",
          street: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          are_you_listing_agent: "no",
          realtor_representation: "no",
          customerDate: "2 weeks",
          home_type: "Single Family",
          target_reverse_price: "no",
          reserve_price: "",
          lat: "",
          lng: "",
        }}
        validationSchema={PropertySchema}
        enableReinitialize
        validateOnChange={true}
        onSubmit={(values) => handlePost(values)}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
        }) => (
          <form
            className="form m-0"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            {" "}
            {/* <div className="form-group">
              <CustomInput
                label="Property Name"
                value={values.title}
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.title && touched.title && "is-invalid"
                }`}
                placeholder="Property Name*"
                required
              />
              {errors.title && touched.title ? (
                <div className="invalid-feedback">{errors.title}</div>
              ) : null}
            </div> */}
            <div className="form-group">
              <MapInput
                label="Listing Address *"
                value={values.street}
                name="street"
                onChange={(data) => {
                  setFieldValue("street", data.address);
                  setFieldValue("lat", data.lat);
                  setFieldValue("lng", data.lng);
                  setFieldValue("city", data.city);
                  setFieldValue("state", data.state);
                  setFieldValue("zip", data.zip);
                }}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.street && touched.street && "is-invalid"
                }`}
                placeholder="Address"
                required
              />
              {errors.street && touched.street ? (
                <div className="invalid-feedback">{errors.street}</div>
              ) : null}
            </div>
            <div className="row syf-row d-none">
              <div className="col-md-6 form-group">
                <CustomInput
                  label=""
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.city && touched.city && "is-invalid"
                  }`}
                  placeholder="City*"
                  required
                />
                {errors.city && touched.city ? (
                  <div className="invalid-feedback">{errors.city}</div>
                ) : null}
              </div>
              <div className="col-md-3 form-group">
                <CustomInput
                  label=""
                  value={values.state}
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.state && touched.state && "is-invalid"
                  }`}
                  placeholder="State*"
                  required
                />
                {errors.state && touched.state ? (
                  <div className="invalid-feedback">{errors.state}</div>
                ) : null}
              </div>
              <div className="col-md-3 form-group">
                <CustomInput
                  label=""
                  value={values.zip}
                  name="zip"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.zip && touched.zip && "is-invalid"
                  }`}
                  placeholder="Zip*"
                  required
                />
                {errors.zip && touched.zip ? (
                  <div className="invalid-feedback">{errors.zip}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group syh-pt-10">
              {/* <CustomPhone
                label='Main Contact Phone'
                value={values.phone}
                name='phone'
                defaultCountry={currentCountry}
                onChange={(value) => setFieldValue('phone', value)}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.phone && touched.phone && 'is-invalid'
                }`}
                placeholder='Enter Phone'
                required
              /> */}
              <CustomInput
                label="Main Contact Phone"
                type="tel"
                value={values.phone}
                name="phone"
                inputProps={{ maxlength: 14 }}
                onChange={(e) => {
                  setFieldValue(
                    "phone",
                    normalizePhoneNumberInput(e.target.value, values.phone),
                  );
                }}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.phone && touched.phone && "is-invalid"
                }`}
                placeholder="(555) 123-4567"
                required
              />
              {errors.phone && touched.phone ? (
                <div className="invalid-feedback">{errors.phone}</div>
              ) : null}
            </div>
            <div className="form-group syh-pt-10 syh-radio-group">
              <label className="pl-label">Are you a listing agent?</label>
              <CheckBox
                label="Yes"
                value="yes"
                checked={values.are_you_listing_agent === "yes"}
                onchange={(e) => setFieldValue("are_you_listing_agent", "yes")}
              />
              <CheckBox
                label="No"
                value="no"
                checked={values.are_you_listing_agent === "no"}
                onchange={(e) => setFieldValue("are_you_listing_agent", "no")}
              />
            </div>
            <div className="form-group syh-pt-10">
              <label className="pl-label">
                How soon would you like the listing to go live?
              </label>
              <div className="selectWrapper">
                <select
                  className="form-control"
                  name="customerDate"
                  value={values.customerDate}
                  onChange={handleChange}
                >
                  <option value="2 weeks">Within 2 weeks</option>
                  <option value="30 days">Within 30 days</option>
                  <option value="60 days">Within 60 days</option>
                </select>
              </div>
            </div>
            <div className="form-group syh-pt-10">
              <label className="pl-label sblist-label ">Select Home type</label>
              <div className="selectWrapper">
                <select
                  className="form-control"
                  name="home_type"
                  value={values.home_type}
                  onChange={handleChange}
                >
                  <option value="Single Family">Single Family</option>
                  <option value="Multi Family">Multi Family</option>
                  <option value="Condominium / Townhome">
                    Condominium / Townhome
                  </option>
                  <option value="Mobile / Manufactured">
                    Mobile / Manufactured
                  </option>
                </select>
              </div>
            </div>
            <div className="form-group syh-pt-10 syh-radio-group">
              <label className="reserve-price sblist-label pl-label">
                Do you know the targeted sale price?
                <InfoOutlinedIcon className="refund-icon" />
              </label>
              <CheckBox
                label="Yes"
                value="yes"
                checked={values.target_reverse_price === "yes"}
                onchange={(e) => setFieldValue("target_reverse_price", "yes")}
              />
              <CheckBox
                label="No"
                value="no"
                checked={values.target_reverse_price === "no"}
                onchange={(e) => setFieldValue("target_reverse_price", "no")}
              />
            </div>
            {values.target_reverse_price === "yes" && (
              <div className="form-group syh-pt-10">
                <PriceInput
                  name="reserve_price"
                  label="Targeted Sale Price"
                  value={values.reserve_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.reserve_price &&
                    touched.reserve_price &&
                    "is-invalid"
                  }`}
                  placeholder="Enter Price"
                  required={
                    values.target_reverse_price === "yes" ? true : false
                  }
                />
                {errors.reserve_price && touched.reserve_price ? (
                  <div className="invalid-feedback">{errors.reserve_price}</div>
                ) : null}
              </div>
            )}
            {/* <div className="view-sample mb-3">
              <a
                href="http://www.doorsey.com/doorsey-crash-course"
                target="_blank"
              >
                View Sample Listing
              </a>
            </div> */}
            <PrimaryButton label="Submit" type="submit" />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SubmitListingForm;
