import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import ListingCard from "../../atoms/ListingCard";

const BidsListing = ({ data, from }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <ListingCard item={item} key={index} from={from ? from : "close"} />
        );
      })}
    </>
  );
};

export default BidsListing;
