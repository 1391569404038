import React, { useRef, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../../components/molecules/Layout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomPhone from "../../../components/atoms/Inputs/CustomPhone";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import GlobalContext from "../../../context/GlobalContext";
import axios from "../../../utils/axiosconfig";
import { showSuccess, showWaring, alertWithButton } from "../../../utils";
import { normalizePhoneNumberInput } from "../../../utils/FormikUtils";
import moment from "moment";
import Dialog from "../../../components/atoms/Dialog";
import MapInput from "../../../components/atoms/Inputs/MapInput";
import { DropzoneArea } from "material-ui-dropzone";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import { getPropertyDetails } from "../../../API/propertyDetails";
import isAuthenticate from "../../../utils/auth";
import Swal from "sweetalert2";

const PropertyListing = (props) => {
  const [modalopen, setModalOpen] = useState(false);
  const { userDetails, userLocation } = useContext(GlobalContext);
  const [currentCountry, setCurrentCountry] = useState("");
  const dateTimeFormat = "yyyy-MM-DD"; //Thh:mm"
  const { id } = props.match.params;
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const init = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    isRealEstateAgent: "1",
    rea_first_name: "",
    rea_last_name: "",
    rea_email: "",
    no_agency: "",
    findAgent: "1",
    address: "",
    alreadySelled: "1",
    startDate: moment().add(1, "d").format(dateTimeFormat),
    // title: "",
    protype: "Independent House",
    reserve_price: "",
    isListedBefore: "1",
    closeDate: moment().add(2, "d").format(dateTimeFormat),
    financingTypes: "cash",
    historical_significance: "",
    arch_significance: "",
    affect_selling: "",
    lat: "",
    lng: "",
    city: "",
    state: "",
    product_images: [],
    // assertType: "1",
    matterportLink: "",
    links: "",
    description: "",
    homestyle: "",
    garage: "",
    lotsf: "",
    beds: "",
    bath: "",
    stories: "",
    heating: "",
    cooling: "",
    views: "",
    roof: "",
    hoa: "",
    lastSold: "",
    newConstruction: "",
  };
  const [initialValues, setInitialValues] = useState(init);
  const PropertySchema = Yup.object().shape(
    {
      first_name: Yup.string().required("Enter First Name"),
      last_name: Yup.string().required("Enter Last Name"),
      email: Yup.string().email("Invalid Email Format").required("Enter Email"),
      phone: Yup.string().required("Enter Phone Number"),
      isRealEstateAgent: Yup.string(),
      rea_first_name: Yup.string().when("isRealEstateAgent", {
        is: (isRealEstateAgent) => isRealEstateAgent === "1",
        then: Yup.string().required("Enter First Name"),
      }),
      rea_last_name: Yup.string().when("isRealEstateAgent", {
        is: (isRealEstateAgent) => isRealEstateAgent === "1",
        then: Yup.string().required("Enter Last Name"),
      }),
      rea_email: Yup.string().when("isRealEstateAgent", {
        is: (isRealEstateAgent) => isRealEstateAgent === "1",
        then: Yup.string()
          .email("Invalid email format")
          .required("Enter Email"),
      }),
      no_agency: Yup.string().when("isRealEstateAgent", {
        is: (isRealEstateAgent) => isRealEstateAgent === "1",
        then: Yup.string().required("Enter Real Estate Agency Name"),
      }),
      findAgent: Yup.string(),
      address: Yup.string().required("Enter Address"),
      alreadySelled: Yup.string(),
      startDate: Yup.string().when("closeDate", (closeDate, schema) => {
        return schema.test({
          test: (startDate) =>
            moment(startDate, dateTimeFormat).isSameOrBefore(
              moment(closeDate, dateTimeFormat),
            ),
          message: "Enter valid start date",
        });
      }),
      // title: Yup.string().required("Enter Title"),
      reserve_price: Yup.number()
        .typeError("Enter only number")
        .required("Enter Reserved Price"),
      isListedBefore: Yup.string(),
      protype: Yup.string(),
      closeDate: Yup.string().when("startDate", (startDate, schema) => {
        return schema.test({
          test: (closeDate) => {
            return moment(closeDate, dateTimeFormat).isAfter(
              moment(startDate, dateTimeFormat),
            );
          },
          message: "Enter valid end date",
        });
      }),
      city: Yup.string(),
      state: Yup.string(),
      financingTypes: Yup.string(),
      historical_significance: Yup.string(),
      arch_significance: Yup.string(),
      affect_selling: Yup.string(),
      lat: Yup.string(),
      lng: Yup.string(),
      product_images: Yup.array(),
      // assertType: Yup.string(),
      links: Yup.string().when("isListedBefore", {
        is: (isListedBefore) => isListedBefore === "2",
        then: Yup.string()
          .url("Enter valid assert link")
          .required("Enter Assert Link"),
      }),
      matterportLink:
        id !== undefined ||
        (id === undefined && props.location.state.selected === 299)
          ? Yup.string()
              .url("Enter valid matterport link")
              .required("Enter Matterport Link")
          : Yup.string(),
      description: id
        ? Yup.string().required("Enter Description")
        : Yup.string(),
      homestyle: Yup.string(),
      Ybuild: id
        ? Yup.number()
            .typeError("Enter only number")
            .required("Enter year of build")
        : Yup.string(),
      garage: Yup.string(),
      lotsf: id
        ? Yup.number().typeError("Enter only number").required("Enter Lot SF")
        : Yup.string(),
      beds: Yup.string(),
      bath: Yup.string(),
      stories: Yup.string(),
      heating: Yup.string(),
      cooling: Yup.string(),
      views: Yup.string(),
      roof: Yup.string(),
      hoa: Yup.string(),
      lastSold: id
        ? Yup.number()
            .typeError("Enter only number")
            .required("Enter Last Sold")
        : Yup.string(),
      newConstruction: Yup.string(),
      // matterportLink: Yup.string().when("assertType", {
      //   is: (assertType) => assertType === "2",
      //   then: Yup.string()
      //     .url("Enter valid matterport link")
      //     .required("Enter Matterport Link"),
      // }),
    },
    ["startDate", "closeDate"],
  );

  const handlePost = async (body) => {
    let formData = new FormData();
    formData.append(
      "userDetails",
      JSON.stringify({
        first_name: body.first_name,
        last_name: body.last_name,
        email: body.email,
        phone: `+1 ${body.phone}`,
      }),
    );
    formData.append("projectstatus", "moderate");
    formData.append("rprice", body.reserve_price);
    formData.append("protype", body.protype);
    formData.append("title", body.loc_address);
    formData.append(
      "startDate",
      moment(body.startDate, dateTimeFormat).format("yyyy-MM-DDThh:mm"),
    );
    formData.append(
      "closeDate",
      moment(body.closeDate, dateTimeFormat).format("yyyy-MM-DDThh:mm"),
    );
    formData.append("locationaddress", body.address);
    !id &&
      formData.append("home_page_listing_fee", props.location.state.selected);
    formData.append("financingTypes", body.financingTypes);
    formData.append("listingLink", body.links);
    formData.append("latmap", body.lat);
    formData.append("lngmap", body.lng);
    formData.append("locationcity", body.city);
    formData.append("state", body.state);
    if (body.isRealEstateAgent === "1") {
      formData.append("findAgent", "0");
      formData.append(
        "agentOfProperty",
        JSON.stringify({
          agentfName: body.rea_first_name,
          agentLName: body.rea_last_name,
          agentEmail: body.rea_email,
          agentCompany: body.no_agency,
        }),
      );
    } else {
      formData.append("findAgent", body.findAgent);
    }
    body.product_images.forEach((file) => {
      formData.append(`product_image`, file);
    });
    formData.append("video_url", body.matterportLink);
    // if (body.assertType === "1") {
    //   body.product_images.forEach((file) => {
    //     formData.append(`bedroom`, file);
    //   });
    // } else {
    //   formData.append("video_url", body.matterportLink);
    // }
    if (!id) {
      let { data } = await axios.post("postproperty", formData, {
        headers: { site_id },
      });
      if (data.success === "no") {
        setModalOpen(true);
      } else if (data.error === true) {
        showWaring("Not have permission to post property");
      } else {
        showWaring("Something went wrong");
      }
    } else {
      showSuccess("Property updated successfully");
      setTimeout(() => {
        props.history.push(`/buy`);
      }, 2300);
    }
  };

  useEffect(() => {
    if (!isAuthenticate() && id) {
      props.history.push({
        pathname: `/login`,
        state: props.location,
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticate() && id) {
      Object.keys(userDetails.get).length !== 0 && getPropertyData(site_id);
    } else {
      setInitialValues({
        first_name: userDetails.get && userDetails.get.first_name,
        last_name: userDetails.get && userDetails.get.last_name,
        email: userDetails.get && userDetails.get.email,
        phone: "",
        isRealEstateAgent: "1",
        rea_first_name: "",
        rea_last_name: "",
        rea_email: "",
        no_agency: "",
        findAgent: "1",
        address: "",
        alreadySelled: "1",
        startDate: moment().add(1, "d").format(dateTimeFormat),
        // title: "",
        protype: "Independent House",
        reserve_price: "",
        isListedBefore: "1",
        closeDate: moment().add(2, "d").format(dateTimeFormat),
        financingTypes: "cash",
        historical_significance: "",
        arch_significance: "",
        affect_selling: "",
        lat: "",
        lng: "",
        city: "",
        state: "",
        product_images: [],
        matterportLink: "",
        links: "",
        description: "",
        homestyle: "",
        garage: "",
        lotsf: "",
        beds: "",
        bath: "",
        stories: "",
        heating: "",
        cooling: "",
        views: "",
        roof: "",
        hoa: "",
        lastSold: "",
        newConstruction: "",
      });
    }
  }, [userDetails.get]);

  const getPropertyData = async () => {
    let data = await getPropertyDetails(id, site_id);
    if (data.status === true) {
      let propertyDetails = data.result.propertyDetails;
      if (propertyDetails.user_id == userDetails.get.id) {
        if (propertyDetails.admin_approved !== 1) {
          alertWithButton("Admin is not yet approve to edit", () => {
            props.history.push(``);
          });
        } else if (
          propertyDetails.listing_status == "for_sale" &&
          propertyDetails.market_status === "open"
        ) {
          alertWithButton(
            "Property is in live can't update the property!",
            () => {
              props.history.push(``);
            },
          );
        } else {
          let agent = propertyDetails.agentOfProperty
            ? JSON.parse(propertyDetails.agentOfProperty)
            : undefined;
          setInitialValues({
            first_name: userDetails.get && userDetails.get.first_name,
            last_name: userDetails.get && userDetails.get.last_name,
            email: userDetails.get && userDetails.get.email,
            phone: "",
            isRealEstateAgent: agent ? "1" : "2",
            rea_first_name: agent && agent.agentfName,
            rea_last_name: agent && agent.agentLName,
            rea_email: agent && agent.agentEmail,
            no_agency: agent && agent.agentCompany,
            findAgent: propertyDetails.findAgent,
            address: propertyDetails.loc_address,
            alreadySelled: propertyDetails.listingLink ? "2" : "1",
            startDate: moment(propertyDetails.date_added).format(
              dateTimeFormat,
            ),
            // title: propertyDetails.title,
            protype: propertyDetails.property_type,
            reserve_price: propertyDetails.rprice,
            isListedBefore: "1",
            closeDate: moment(propertyDetails.date_closed).format(
              dateTimeFormat,
            ),
            financingTypes: propertyDetails.financingTypes,
            historical_significance: "",
            arch_significance: "",
            affect_selling: "",
            lat: propertyDetails.lat,
            lng: propertyDetails.lng,
            city: propertyDetails.loc_city,
            state: propertyDetails.state,
            product_images: [],
            // assertType: "1",
            matterportLink: propertyDetails.video_url || "",
            links: propertyDetails.listingLink || "",
            description: propertyDetails.description || "",
            homestyle: propertyDetails.homestyle || "Mid-Century",
            Ybuild: propertyDetails.Ybuild || "",
            garage: propertyDetails.garage || "1",
            lotsf: propertyDetails.built_area || "",
            beds: propertyDetails.bedroom_type || "1",
            bath: propertyDetails.bath || "1",
            stories: propertyDetails.stories || "1",
            heating: propertyDetails.heating || "yes",
            cooling: propertyDetails.cooling || "yes",
            views: propertyDetails.facing || "ocean",
            roof: propertyDetails.roof || "Tile",
            hoa: propertyDetails.hoa || "yes",
            lastSold: propertyDetails.LSYear || "",
            newConstruction: propertyDetails.newConstruction || "yes",
          });
        }
      } else {
        alertWithButton("Can't update the property!", () => {
          props.history.push(``);
        });
      }
    }
  };

  useEffect(() => {
    Object.keys(userLocation.get).length !== 0 &&
      setCurrentCountry(userLocation.get["countryCode"].toLowerCase());
  }, [userLocation.get]);

  return (
    <Layout>
      <div className="post-listing-wrapper">
        <div className="pl-banner">
          <img src="/images/hero-img.png" alt="" className="plb-img" />
          <h1 className="plb-head">Tell Us About Your Home</h1>
        </div>
        <div className="pl-form-wrapper" aria-autocomplete="none">
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={PropertySchema}
            enableReinitialize
            validateOnChange={true}
            onSubmit={(values) => handlePost(values)}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
            }) => (
              <form
                className="form m-0"
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <div className="form-group">
                  <CustomInput
                    label="First Name"
                    value={values.first_name}
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.first_name && touched.first_name && "is-invalid"
                    }`}
                    placeholder="Enter First Name"
                    required
                  />
                  {errors.first_name && touched.first_name ? (
                    <div className="invalid-feedback">{errors.first_name}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <CustomInput
                    label="Last Name"
                    value={values.last_name}
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.last_name && touched.last_name && "is-invalid"
                    }`}
                    placeholder="E-Mail*"
                    required
                  />
                  {errors.last_name && touched.last_name ? (
                    <div className="invalid-feedback">{errors.last_name}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <CustomInput
                    label="E-Mail"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.email && touched.email && "is-invalid"
                    }`}
                    placeholder="E-Mail*"
                    required
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  {/* <CustomPhone
                    label="Phone Number"
                    value={values.phone}
                    name="phone"
                    defaultCountry={currentCountry}
                    onChange={(value) => setFieldValue("phone", value)}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.phone && touched.phone && "is-invalid"
                    }`}
                    placeholder="Enter Phone"
                    required
                  /> */}
                  <CustomInput
                    label="Phone Number"
                    type="tel"
                    value={values.phone}
                    name="phone"
                    inputProps={{ maxlength: 14 }}
                    onChange={(e) => {
                      setFieldValue(
                        "phone",
                        normalizePhoneNumberInput(e.target.value, values.phone),
                      );
                    }}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.phone && touched.phone && "is-invalid"
                    }`}
                    placeholder="Enter Phone"
                    required
                  />
                  {errors.phone && touched.phone ? (
                    <div className="invalid-feedback">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="">
                    Are you working with a Real Estate Agent?
                  </label>
                  <div className="selectWrapper">
                    <select
                      className="form-control"
                      value={values.isRealEstateAgent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="isRealEstateAgent"
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                </div>
                {values.isRealEstateAgent === "1" && (
                  <div className="form-group">
                    <CustomInput
                      label="First Name"
                      value={values.rea_first_name}
                      name="rea_first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.rea_first_name &&
                        touched.rea_first_name &&
                        "is-invalid"
                      }`}
                      placeholder="Enter First Name"
                      required
                    />
                    {errors.rea_first_name && touched.rea_first_name ? (
                      <div className="invalid-feedback">
                        {errors.rea_first_name}
                      </div>
                    ) : null}
                  </div>
                )}
                {values.isRealEstateAgent === "1" && (
                  <div className="form-group">
                    <CustomInput
                      label="Last Name"
                      value={values.rea_last_name}
                      name="rea_last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.rea_last_name &&
                        touched.rea_last_name &&
                        "is-invalid"
                      }`}
                      placeholder="Enter Last Name"
                      required
                    />
                    {errors.rea_last_name && touched.rea_last_name ? (
                      <div className="invalid-feedback">
                        {errors.rea_last_name}
                      </div>
                    ) : null}
                  </div>
                )}
                {values.isRealEstateAgent === "1" && (
                  <div className="form-group">
                    <CustomInput
                      label="E-Mail"
                      value={values.rea_email}
                      name="rea_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.rea_email && touched.rea_email && "is-invalid"
                      }`}
                      placeholder="Enter E-Mail"
                      required
                    />
                    {errors.rea_email && touched.rea_email ? (
                      <div className="invalid-feedback">{errors.rea_email}</div>
                    ) : null}
                  </div>
                )}
                {values.isRealEstateAgent === "1" && (
                  <div className="form-group">
                    <CustomInput
                      label="Name of the Agency"
                      value={values.no_agency}
                      name="no_agency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.no_agency && touched.no_agency && "is-invalid"
                      }`}
                      placeholder="Enter Name of the Agency"
                      required
                    />
                    {errors.no_agency && touched.no_agency ? (
                      <div className="invalid-feedback">{errors.no_agency}</div>
                    ) : null}
                  </div>
                )}
                {values.isRealEstateAgent === "2" && (
                  <div className="form-group">
                    <label className="pl-label">
                      Would you like help finding a Real Estate Agent?
                    </label>
                    <div className="selectWrapper">
                      <select
                        className="form-control"
                        name="findAgent"
                        value={values.findAgent}
                        onChange={handleChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label className="pl-label">Home Style</label>
                  <div className="selectWrapper">
                    <select
                      className="form-control"
                      name="protype"
                      value={values.protype}
                      onChange={handleChange}
                    >
                      <option value="Independent House">
                        Independent House
                      </option>
                      <option value="Apartment">Apartment</option>
                      <option value="Villa">Villa</option>
                      <option value="Individual Home">Individual Home</option>
                      <option value="Raw House">Raw House</option>
                    </select>
                  </div>
                </div>
                <div className="form-group mapInput">
                  <MapInput
                    label="What is the Address?"
                    placeholder="Address*"
                    value={values.address}
                    onChange={(data) => {
                      setFieldValue("address", data.address);
                      setFieldValue("lat", data.lat);
                      setFieldValue("lng", data.lng);
                      setFieldValue("city", data.city);
                      setFieldValue("state", data.state);
                    }}
                  />
                  {errors.address && touched.address ? (
                    <div className="invalid-feedback">{errors.address}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="pl-label">
                    Is this your first time selling a home with Doorsey?
                  </label>
                  <div className="selectWrapper">
                    <select
                      className="form-control"
                      name="alreadySelled"
                      value={values.alreadySelled}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="pl-label">
                    When would you like to list the home
                  </label>
                  <input
                    type="date"
                    value={values.startDate}
                    name="startDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={moment().add(1, "d").format(dateTimeFormat)}
                    className={`form-control hh-input ${
                      errors.startDate && touched.startDate && "is-invalid"
                    }`}
                    required
                  />
                  {errors.startDate && touched.startDate ? (
                    <div className="invalid-feedback">{errors.startDate}</div>
                  ) : null}
                </div>
                {/* <div className="form-group">
                  <CustomInput
                    label="Is the title free of any leins?"
                    value={values.title}
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.title && touched.title && "is-invalid"
                    }`}
                    placeholder="Enter Title"
                    required
                  />
                  {errors.title && touched.title ? (
                    <div className="invalid-feedback">{errors.title}</div>
                  ) : null}
                </div> */}
                <div className="form-group">
                  <PriceInput
                    name="reserve_price"
                    label="Reserve Price"
                    value={values.reserve_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.reserve_price &&
                      touched.reserve_price &&
                      "is-invalid"
                    }`}
                    required
                  />
                  {errors.reserve_price && touched.reserve_price ? (
                    <div className="invalid-feedback">
                      {errors.reserve_price}
                    </div>
                  ) : null}
                </div>
                {values.alreadySelled === "2" && (
                  <div className="form-group">
                    <label className="pl-label">
                      Has the home been listed in the last 90 days?
                    </label>
                    <div className="selectWrapper">
                      <select
                        className="form-control"
                        value={values.isListedBefore}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="isListedBefore"
                      >
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                  </div>
                )}
                {values.alreadySelled === "2" &&
                  values.isListedBefore === "1" && (
                    <div className="form-group">
                      <CustomInput
                        label="Please provide links to listings"
                        value={values.links}
                        name="links"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.links && touched.links && "is-invalid"
                        }`}
                        placeholder=""
                        required
                      />
                      {errors.links && touched.links ? (
                        <div className="invalid-feedback">{errors.links}</div>
                      ) : null}
                    </div>
                  )}
                <div className="form-group">
                  <label className="pl-label">Close Date on or Before</label>
                  <input
                    type="date"
                    value={values.closeDate}
                    name="closeDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={moment(values.startDate, dateTimeFormat)
                      .add(1, "d")
                      .format(dateTimeFormat)} //"2021-02-15T16:15:23"
                    className={`form-control hh-input ${
                      errors.closeDate && touched.closeDate && "is-invalid"
                    }`}
                    required
                  />
                  {errors.closeDate && touched.closeDate ? (
                    <div className="invalid-feedback">{errors.closeDate}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="pl-label">Financing Types</label>
                  <div className="selectWrapper">
                    <select
                      className="form-control"
                      value={values.financingTypes}
                      name="financingTypes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="cash">Cash</option>
                      <option value="va">VA</option>
                      <option value="loan">Loan</option>
                    </select>
                  </div>
                </div>
                {/* <>
                  {Object.keys(errors).map((val) => (
                    <p>{val}</p>
                  ))}
                </> */}
                {/* {props.location.state.isNew !== true && (
                  <div className="form-group">
                    <label className="pl-label">
                      Does the home have any historical significance?
                    </label>
                    <textarea
                      value={values.historical_significance}
                      name="historical_significance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${
                        errors.historical_significance &&
                        touched.historical_significance &&
                        "is-invalid"
                      }`}
                      rows="2"
                      placeholder=""
                      required
                    ></textarea>
                    {errors.historical_significance &&
                    touched.historical_significance ? (
                      <div className="invalid-feedback">
                        {errors.historical_significance}
                      </div>
                    ) : null}
                  </div>
                )} */}
                {/* {props.location.state.isNew !== true && (
                  <div className="form-group">
                    <label className="pl-label">
                      Does the home have any architectural significance?
                    </label>
                    <textarea
                      value={values.arch_significance}
                      name="arch_significance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${
                        errors.arch_significance &&
                        touched.arch_significance &&
                        "is-invalid"
                      }`}
                      rows="2"
                      placeholder=""
                      required
                    ></textarea>
                    {errors.arch_significance && touched.arch_significance ? (
                      <div className="invalid-feedback">
                        {errors.arch_significance}
                      </div>
                    ) : null}
                  </div>
                )} */}
                {/* {props.location.state.isNew !== true && (
                  <div className="form-group">
                    <label className="pl-label">
                      Anything we should know that may affect selling this home?
                    </label>
                    <textarea
                      value={values.affect_selling}
                      name="affect_selling"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${
                        errors.affect_selling &&
                        touched.affect_selling &&
                        "is-invalid"
                      }`}
                      rows="2"
                      placeholder=""
                      required
                    ></textarea>
                    {errors.affect_selling && touched.affect_selling ? (
                      <div className="invalid-feedback">
                        {errors.affect_selling}
                      </div>
                    ) : null}
                  </div>
                )} */}
                {(id !== undefined ||
                  (id === undefined &&
                    props.location.state.selected === 299)) && (
                  <>
                    <div className="form-group">
                      <label className="pl-label">Property Images</label>
                      <DropzoneArea
                        onChange={(files) =>
                          setFieldValue("product_images", files)
                        }
                        acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
                        filesLimit={200}
                        showAlerts={false}
                        showPreviews={false}
                      />
                    </div>
                    <div className="form-group">
                      <CustomInput
                        label="Please provide Matterport Link"
                        value={values.matterportLink}
                        name="matterportLink"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.matterportLink &&
                          touched.matterportLink &&
                          "is-invalid"
                        }`}
                        placeholder="Matterport Link"
                        required
                      />
                      {errors.matterportLink && touched.matterportLink ? (
                        <div className="invalid-feedback">
                          {errors.matterportLink}
                        </div>
                      ) : null}
                      {values.matterportLink && (
                        <iframe
                          allowFullScreen
                          className="iframeStyle"
                          src={values.matterportLink}
                        ></iframe>
                      )}
                    </div>
                    {/* <div className="form-group">
                      <label className="pl-label">
                        Do you need help gathering assets for your listing?
                      </label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          name={"assertType"}
                          value={values.assertType}
                          onChange={handleChange}
                        >
                          <option value={1}>Professional Level Photos</option>
                          <option value={2}>Matterport</option>
                        </select>
                      </div>
                    </div> */}
                    {/* {values.assertType === "1" ? (
                      <div className="form-group">
                        <label className="pl-label">Property Images</label>
                        <DropzoneArea
                          onChange={(files) =>
                            setFieldValue("product_images", files)
                          }
                          acceptedFiles={[
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ]}
                          filesLimit={200}
                          showAlerts={false}
                          showPreviews={false}
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <CustomInput
                          label="Please provide Matterport Link*"
                          value={values.matterportLink}
                          name="matterportLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.matterportLink &&
                            touched.matterportLink &&
                            "is-invalid"
                          }`}
                          placeholder="Matterport Link"
                          required
                        />
                        {errors.matterportLink && touched.matterportLink ? (
                          <div className="invalid-feedback">
                            {errors.matterportLink}
                          </div>
                        ) : null}
                        {values.matterportLink && (
                          <iframe
                            allowFullScreen
                            className="iframeStyle"
                            src={values.matterportLink}
                          ></iframe>
                        )}
                      </div>
                    )} */}
                  </>
                )}
                {/* <div className="form-group">
                  <label className="pl-label">Bedroom</label>
                  <div className="cif-wrapper">
                    <input
                      name="beds"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        onChangeFiles(
                          "beds",
                          setFieldValue,
                          values.beds,
                          e.currentTarget.files
                        )
                      }
                    />
                    <label className="cif-label">
                      <i className="fa fa-upload"></i> Upload Bedroom Images
                    </label>
                  </div>
                  <div className="row pdtVewWrpr">
                    {values.beds.map((fileName, index) => (
                      <div className="col-4" key={index}>
                        <div className="vwImWrpr">
                          <button
                            onClick={(e) =>
                              removeImage(
                                index,
                                "beds",
                                setFieldValue,
                                values.beds
                              )
                            }
                            className="btn material-icons clrBtn"
                          >
                            clear
                          </button>
                          <img src={URL.createObjectURL(fileName)} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
                {/* <div className="form-group">
                  <label className="pl-label">Kitchen</label>
                  <div className="cif-wrapper">
                    <input
                      name="kitchen"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        onChangeFiles(
                          "kitchen",
                          setFieldValue,
                          values.kitchen,
                          e.currentTarget.files
                        )
                      }
                    />
                    <label className="cif-label">
                      <i className="fa fa-upload"></i> Upload Kitchen Images
                    </label>
                  </div>
                  <div className="row pdtVewWrpr">
                    {values.kitchen.map((fileName, index) => (
                      <div className="col-4" key={index}>
                        <div className="vwImWrpr">
                          <button
                            onClick={(e) =>
                              removeImage(
                                index,
                                "kitchen",
                                setFieldValue,
                                values.kitchen
                              )
                            }
                            className="btn material-icons clrBtn"
                          >
                            clear
                          </button>
                          <img src={URL.createObjectURL(fileName)} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group">
                  <label className="pl-label">Living Room</label>
                  <div className="cif-wrapper">
                    <input
                      name="living"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        onChangeFiles(
                          "living",
                          setFieldValue,
                          values.living,
                          e.currentTarget.files
                        )
                      }
                    />
                    <label className="cif-label">
                      <i className="fa fa-upload"></i> Upload Living Images
                    </label>
                  </div>
                  <div className="row pdtVewWrpr">
                    {values.living.map((fileName, index) => (
                      <div className="col-4" key={index}>
                        <div className="vwImWrpr">
                          <button
                            onClick={(e) =>
                              removeImage(
                                index,
                                "living",
                                setFieldValue,
                                values.living
                              )
                            }
                            className="btn material-icons clrBtn"
                          >
                            clear
                          </button>
                          <img src={URL.createObjectURL(fileName)} />
                        </div>
                      </div>
                    ))}
                  </div>
                          </div> */}
                {id !== undefined && (
                  <>
                    <div className="form-group">
                      <label className="pl-label">
                        Wanna say somthing about property?*
                      </label>
                      <textarea
                        value={values.description}
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.description &&
                          touched.description &&
                          "is-invalid"
                        }`}
                        rows="2"
                        placeholder=""
                        required
                      ></textarea>
                      {errors.description && touched.description ? (
                        <div className="invalid-feedback">
                          {errors.description}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pl-label">
                        What is the Property Style?
                      </label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          value={values.homestyle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="homestyle"
                        >
                          <option value="Mid-Century">Mid-Century</option>
                          <option value="Industrial">Industrial</option>
                          <option value="Nautical">Nautical</option>
                          <option value="Scandinavian">Scandinavian</option>
                          <option value="Bohemian">Bohemian</option>
                          <option value="Farm House">Farm House</option>
                          <option value="Urban Modern">Urban Modern</option>
                          <option value="Shabby Chic">Shabby Chic</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <CustomInput
                        label="Please provide Year of build"
                        value={values.Ybuild}
                        name="Ybuild"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.Ybuild && touched.Ybuild && "is-invalid"
                        }`}
                        placeholder="Year of build"
                        required
                      />
                      {errors.Ybuild && touched.Ybuild ? (
                        <div className="invalid-feedback">{errors.Ybuild}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pl-label">How many garage?</label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          value={values.garage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="garage"
                        >
                          <option value="N/A">No</option>
                          <option value="1">1 Garage</option>
                          <option value="2">2 Garage</option>
                          <option value="3">3 Garage</option>
                          <option value="4">4 Garage</option>
                          <option value="4+">4+ Garage</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <CustomInput
                        label="Please provide Lot SF"
                        value={values.lotsf}
                        name="lotsf"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.lotsf && touched.lotsf && "is-invalid"
                        }`}
                        placeholder="Lot SF"
                        required
                      />
                      {errors.lotsf && touched.lotsf ? (
                        <div className="invalid-feedback">{errors.lotsf}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pl-label">How many bed ?</label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          value={values.beds}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="beds"
                        >
                          <option value="1">1 Bed</option>
                          <option value="2">2 Beds</option>
                          <option value="3">3 Beds</option>
                          <option value="4">4 Beds</option>
                          <option value="4+">4+ Beds</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pl-label">How many bath ?</label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          value={values.bath}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="bath"
                        >
                          <option value="1">1 Baths</option>
                          <option value="2">2 Baths</option>
                          <option value="3">3 Baths</option>
                          <option value="4">4 Baths</option>
                          <option value="4+">4+ Baths</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pl-label">How many Stories ?</label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          value={values.stories}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="stories"
                        >
                          <option value="1">1 Stories</option>
                          <option value="2">2 Stories</option>
                          <option value="3">3 Stories</option>
                          <option value="4">4 Stories</option>
                          <option value="4+">4+ Stories</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pl-label">
                        Is heating is avalible?
                      </label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          name="heating"
                          value={values.heating}
                          onChange={handleChange}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="pl-label">
                        Is cooling is avalible?
                      </label>
                      <div className="selectWrapper">
                        <select
                          className="form-control"
                          name="cooling"
                          value={values.cooling}
                          onChange={handleChange}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <PrimaryButton label="Submit" type="submit" />
                <Dialog
                  open={modalopen}
                  onClose={() => {
                    setModalOpen(!setModalOpen);
                    props.history.push(`/buy`);
                  }}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default PropertyListing;
