import React, { useEffect, useState } from "react";
import CheckBox from "../../atoms/CheckBox";
import ReactPlayer from "react-player/lazy";

const GettingStartedCard = ({
  width,
  gettingStartedData,
  changeGettingStartedItemStatus,
}) => {
  const [viewItems, setViewItems] = useState([]);

  useEffect(() => {
    let data = [];
    if (gettingStartedData.length > 0) {
      gettingStartedData.map((item) =>
        data.push({ ...item, detailsOpen: false }),
      );
    }
    setViewItems(data);
  }, [gettingStartedData]);

  const handleViewDetailsClick = (index, item) => {
    let data = viewItems;
    data[index].detailsOpen = !item.detailsOpen;
    setViewItems(data);
  };

  const handleCompleteCheckboxClick = (e) => {
    changeGettingStartedItemStatus({ onboard_id: e.target.value });
  };

  return (
    <>
      {viewItems.length > 0
        ? viewItems.map((item, index) => (
            <div
              className={
                item.is_completed === "Yes"
                  ? "card prflCmntWrpr client-card disabled-item"
                  : "card prflCmntWrpr client-card"
              }
              style={{ paddingBottom: "0px" }}
            >
              <div className="row getting-started-summary">
                <div className="col-8 media-left">
                  <span className="getting-started-title">{item.title}</span>
                  <div>{item.preview}</div>
                </div>
                {width ? (
                  <div className="col-4">
                    <div className="action-button gtStActBtn">
                      <div className="myclients-buttons">
                        <div
                          className="buttonLeft"
                          style={{ marginRight: "20px" }}
                        >
                          <span
                            className="pv-collapse"
                            onClick={() =>
                              item.is_completed === "No" &&
                              handleViewDetailsClick(index, item)
                            }
                            style={{ width: "100%" }}
                          >
                            {item.detailsOpen ? (
                              <i className="fa fa-angle-up"></i>
                            ) : (
                              <i className="fa fa-angle-down"></i>
                            )}{" "}
                            View Details
                          </span>
                        </div>
                        <div className="completeCheckbox">
                          <CheckBox
                            label="Complete"
                            value={item.id}
                            checked={item.is_completed == "Yes"}
                            onchange={handleCompleteCheckboxClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-4" style={{ padding: "0px" }}>
                    <div className="action-button gtStActBtn">
                      <div className="myclients-buttons">
                        <div className="row completeCheckbox">
                          <CheckBox
                            label="Complete"
                            value={item.id}
                            checked={item.is_completed == "Yes"}
                            onchange={handleCompleteCheckboxClick}
                          />
                        </div>
                        <div
                          className="row buttonLeft"
                          style={{ marginLeft: "-30px" }}
                        >
                          <span
                            className="pv-collapse"
                            onClick={() =>
                              item.is_completed === "No" &&
                              handleViewDetailsClick(index, item)
                            }
                            style={{ width: "100%" }}
                          >
                            {item.detailsOpen ? (
                              <i className="fa fa-angle-up"></i>
                            ) : (
                              <i className="fa fa-angle-down"></i>
                            )}{" "}
                            View Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {item.detailsOpen && (
                <div className="row details-section">
                  {false ? (
                    <>
                      {width ? (
                        <>
                          <div className="col-8">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />{" "}
                          </div>
                          <div className="col-4">
                            <div className="video_player">
                              <ReactPlayer
                                url={item.video_link}
                                width="167px"
                                height="93px"
                                controls
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-12">
                          {/* <div className='row'> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />{" "}
                          {/* </div> */}
                          {/* <div className='row'> */}
                          <div className="video_player">
                            <ReactPlayer
                              url={item.video_link}
                              width="230px"
                              height="150px"
                            />
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col-12">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />{" "}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        : "No Getting Started Items Yet"}
    </>
  );
};

export default GettingStartedCard;
