import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../../components/molecules/AuthLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CheckBox from "../../../components/atoms/CheckBox";

const BidderRegistration = (props) => {
  const [open, setOpen] = useState(false);
  const store_id = window.location.pathname.split("/")[1];
  const BidderSchema = Yup.object({
    name: Yup.string().required("Enter your Name"),
    approveletter: Yup.string().required("Enter Your address"),
    broker: Yup.string().required("Enter Your address"),
    address: Yup.string().required("Enter Your address"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter your Email Id"),
    license: Yup.string().required("Enter license number"),
  });

  const history = useHistory();
  useEffect(() => {}, []);

  const handleSubmitdata = async (data) => {
    const { email, name, password } = data;
  };

  return (
    <AuthLayout title="Agent & Buyer Verification">
      <a className="dl-link">Do this Later ? </a>
      <Formik
        initialValues={{
          name: "",
          approveletter: "",
          broker: "",
          address: "",
          email: "",
          license: "",
        }}
        validationSchema={BidderSchema}
        onSubmit={(values) => handleSubmitdata(values)}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className="form m-0" onSubmit={handleSubmit} noValidate>
            <div className="form-group mt-2">
              <CustomInput
                label="Real Estate Agent"
                value={values.name}
                name="name"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.name && touched.name && "is-invalid"
                }`}
                placeholder="John Doe"
                required
              />
              {errors.name && touched.name ? (
                <div className="invalid-feedback">{errors.name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <CheckBox label="I don't have an agent" />
            </div>
            <div className="form-group">
              <CustomInput
                label="Upload Pre-Approval Letter"
                value={values.approveletter}
                name="approveletter"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                type="file"
                className={`hc-input ${
                  errors.approveletter && touched.approveletter && "is-invalid"
                }`}
                placeholder="Choose File"
                required
              />
              {errors.approveletter && touched.approveletter ? (
                <div className="invalid-feedback">{errors.approveletter}</div>
              ) : null}
            </div>
            <div className="form-group">
              <CustomInput
                label="Mortgage Broker"
                value={values.broker}
                name="broker"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.broker && touched.broker && "is-invalid"
                }`}
                placeholder="Mortgage Broker"
                required
              />
              {errors.broker && touched.broker ? (
                <div className="invalid-feedback">{errors.broker}</div>
              ) : null}
            </div>
            <div className="form-group">
              <CustomInput
                label="Address"
                value={values.address}
                name="address"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.address && touched.address && "is-invalid"
                }`}
                placeholder="1234 Brokerage Way"
                required
              />
              {errors.address && touched.address ? (
                <div className="invalid-feedback">{errors.address}</div>
              ) : null}
            </div>
            <div className="form-group">
              <CustomInput
                label="Email"
                value={values.email}
                name="email"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.email && touched.email && "is-invalid"
                }`}
                placeholder="mortgage@broker.com"
                required
              />
              {errors.email && touched.email ? (
                <div className="invalid-feedback">{errors.email}</div>
              ) : null}
            </div>
            <div className="form-group">
              <CustomInput
                label="License #"
                value={values.license}
                name="license"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.license && touched.license && "is-invalid"
                }`}
                placeholder="Enter License #"
                required
              />
              {errors.license && touched.license ? (
                <div className="invalid-feedback">{errors.license}</div>
              ) : null}
            </div>
            <PrimaryButton label="Submit" pb_type="submit" />
            <div className="auth-bottom mt-4 mb-2 text-center">
              <Link to={`/login`} className="blue-link">
                Save & Continue Later
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default BidderRegistration;
