import React, { useEffect, useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import moment from "moment";
import { onAvatarError } from "../../../utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ProfileCardNew = (props) => {
  const width = useMediaQuery("(min-width:768px)");
  const { userDetails } = useContext(GlobalContext);

  return (
    <div className="profile-card-wrapper pfrlCardNew">
      <div className="pcd-media prflNwCs">
        <div className="edit" onClick={props.edit}>
          <i className="fa fa-edit"></i> {width && "Edit"}
        </div>
        <div className="pfrlCntnr d-flex align-items-center w-100">
          <div className="media-left">
            <img
              src={
                userDetails.get["avatar"]
                  ? process.env.REACT_APP_IMAGE_URL +
                    "uploads/profile/" +
                    userDetails.get["avatar"]
                  : "/images/profile_avatar.png"
              }
              alt={userDetails.get["avatar"] || "no image"}
              onError={onAvatarError}
              className="profile-media-img"
            />
          </div>
          <div className="media-body">
            <div className="row">
              <div className="col-md-6">
                <div className="pcd-name pc-margin-bottom">
                  {userDetails.get["user_name"]}{" "}
                  {width && (
                    <span>
                      {userDetails.get["profiletype"] === "customer" ||
                      userDetails.get["profiletype"] === "bidder"
                        ? "General User"
                        : userDetails.get["profiletype"] === "agent"
                          ? "Realtor"
                          : "Other Real Estate Professional"}
                    </span>
                  )}
                </div>
                <div className="joined-date pc-margin-bottom">
                  {userDetails.get["created"]
                    ? `Joined ${moment(userDetails.get["created"]).format(
                        "MMM",
                      )}. ${moment(userDetails.get["created"]).format("YYYY")}`
                    : ""}
                </div>

                {width && (
                  <>
                    <div className="profile-ep-text pc-margin-bottom">
                      {userDetails.get["email"]}
                    </div>
                    <div className="profile-ep-text">
                      {userDetails.get["phone"]}
                    </div>
                  </>
                )}

                {!width && (
                  <div className="role-text">
                    {userDetails.get["profiletype"] === "customer" ||
                    userDetails.get["profiletype"] === "bidder"
                      ? "General User"
                      : userDetails.get["profiletype"] === "agent"
                        ? "Realtor"
                        : "Other Real Estate Professional"}
                  </div>
                )}
                {!width && (
                  <div className="about-box-mob">
                    <div className="profile-ep-text pc-margin-bottom">
                      {userDetails.get["email"]}
                    </div>
                    <div className="profile-ep-text">
                      {userDetails.get["phone"]}
                    </div>
                  </div>
                )}
              </div>
              {width && (
                <div className="col-md-6 text-md-right">
                  <div className="padr-txt pc-margin-bottom">
                    {userDetails.get["companyName"] || ""}
                  </div>
                  <div className="pc-margin-bottom">
                    {userDetails.get["address"] || ""}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {!width && (
        <div className="about-box pcd-media">
          <div className="profile-ep-text pc-margin-bottom">
            {userDetails.get["email"]}
          </div>
          <div className="profile-ep-text">{userDetails.get["phone"]}</div>
        </div>
      )} */}

      {/* {userDetails.get["profiletype"] === "customer" ||
      userDetails.get["profiletype"] === "bidder" ? (
        <>
          <div className="my-agent-in-profile">
            <div className="about-box pcd-media">
              <div className="am-head">My Agent</div>
              {Object.keys(props.userAgent).length > 0 ? (
                <>
                  {width ? (
                    <>
                      <div className="row">
                        <div className="col-2 media-left client-avatar">
                          <img
                            src={
                              props.userAgent.agent_avatar
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "uploads/profile/" +
                                  props.userAgent.agent_avatar
                                : ""
                            }
                            alt={props.userAgent.agent_avatar || "no image"}
                            onError={onAvatarError}
                            className="profile-media-img"
                          />
                        </div>
                        <div className="col-5">
                          <div
                            className="client-info"
                            style={{ paddingTop: "20px" }}
                          >
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              {`${props.userAgent.agent_first_name}  ${props.userAgent.agent_last_name}`}
                            </span>
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              {props.userAgent.agent_company}
                            </span>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="action-button">
                            <div className="myclients-buttons">
                              <div className="buttonRight">
                                <button
                                  className="btn btn-border register-to-comment"
                                  onClick={() =>
                                    props.history.push(
                                      `/view-account/${props.userAgent.agent_username}`
                                    )
                                  }
                                >
                                  View Agent Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-3 media-left client-avatar">
                          <img
                            src=""
                            alt=""
                            onError={onAvatarError}
                            className="profile-media-img"
                          />
                        </div>
                        <div className="col-9">
                          <div
                            className="client-info"
                            style={{ paddingTop: "20px" }}
                          >
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              {`${props.userAgent.agent_first_name}  ${props.userAgent.agent_last_name}`}
                            </span>
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              {props.userAgent.agent_company}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="action-button">
                            <div className="myclients-buttons">
                              <div
                                className="buttonRight"
                                style={{ width: "100%" }}
                              >
                                <button className="btn btn-border register-to-comment">
                                  View Agent Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                "No Agent Associated with your account"
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      <div className="about-box pcd-media">
        <div className="am-head">About me</div>
        <p className="am-text">{userDetails.get["aboutme"]}</p>
      </div>

      {!width &&
        (userDetails.get["address"] || userDetails.get["companyName"]) && (
          <div className="about-box pcd-media">
            <div className="padr-txt pc-margin-bottom">
              {userDetails.get["companyName"]}
            </div>
            <div className="pc-margin-bottom">{userDetails.get["address"]}</div>
          </div>
        )}
    </div>
  );
};

export default ProfileCardNew;
