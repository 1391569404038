import React, { useState, useEffect, useContext, useMemo } from "react";
import Layout from "../../../components/molecules/Layout";
import SearchMap from "../../../components/molecules/Search/SearchMap";
import SearchGrid from "../../../components/molecules/Search/SearchGrid";
import SearchCard from "../../../components/atoms/SearchCard";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";
import qs from "qs";
import "./search.css";
import { getSearch, saveSearch } from "../../../API/search";
import { toast } from "react-toastify";
import {
  Button,
  Drawer,
  IconButton,
  Slider,
  useForkRef,
} from "@material-ui/core";
import CheckBox from "../../../components/atoms/CheckBox";
import { indexOf } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useToggle from "../../../utils/useToggle";
import { stateCode } from "../../../utils";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import { numberWithCommas, callNumber } from "../../../utils";
import { getLocation } from "../../../API/userData";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocation, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Pagination from "../../../components/atoms/Pagination";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "293px",
    marginBottom: "10px",
    height: "45px",
  },
  mobileFormControl: {
    margin: theme.spacing(1),
    width: "163px",
    marginBottom: "5px",
  },
  formControldiv: {
    width: "300px",
  },
  mobileFormControldiv: {
    width: "170px",
    marginBottom: "3px",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 100;
  const left = 98;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Search = (props) => {
  const location = useLocation();

  const [drawSearch, setDrawSearch] = useState(false);
  const [viewProperties, setViewProperties] = useState([]);
  const [viewAllProperties, setViewAllProperties] = useState([]);
  const [viewPropertyLocations, setViewPropertyLocations] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [isBoundSet, setIsBoundSet] = useState(false);
  const [mobileSelectOpen, setMobileSelectOpen] = useState(false);
  const [mobilePropertyPopupOpen, setMobilePropertyPopupOpen] = useState(false);
  const [mobileSelectedPropertyId, setMobileSelectedPropetyId] = useState("");
  const [selectedLocationBound, setSelectedLocationBound] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const history = useHistory();
  const [sqt, setSqt] = useState({
    minSize: "",
    maxSize: "",
  });
  const [beds, setBeds] = useState({
    minBeds: "",
    maxBeds: "",
  });

  const [baths, setBaths] = useState({
    minBaths: "",
    maxBaths: "",
  });
  const [body, setBody] = useState(undefined);
  const [getCount, setCount] = useState(0);
  const [propertyLoc, setPropertyLoc] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [page, setPage] = useState(1);
  const { userLocation, userLoggedIn, headerLocation, storeDetails } =
    useContext(GlobalContext);
  const [state, setState] = useState({
    right: false,
  });
  const width = useMediaQuery("(min-width:992px)");
  const [map, toggleMap] = useToggle(false);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    if (site_id != (null || undefined)) {
      propertyLocation();
    }
  }, [site_id]);

  const propertyLocation = async () => {
    let data = await getLocation(site_id);

    if (data && data.length > 0) {
      setPropertyLoc(data);
    }
    // if (location && location.search === "") {
    //   props.history.replace({
    //     pathname: location.pathname,
    //     search: `?cities=${data[0].city}&state=${data[0].state}&page=1&sortby=1&status=accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium,Mobile / Manufactured&lat=${data[0].lat}&long=${data[0].lng}`,
    //   });
    // }
  };

  useEffect(() => {
    if (propertyLoc.length > 0 && Object.keys(selectedLocation).length > 0) {
      let headerLocationToBeSelected = propertyLoc.filter(
        (location) =>
          location.lat === selectedLocation.lat &&
          location.lng === selectedLocation.lng &&
          location.coming_soon === "false",
      )[0];
      if (headerLocationToBeSelected) {
        headerLocation.set(headerLocationToBeSelected);
      }
    }
  }, [propertyLoc, selectedLocation]);

  // useEffect(() => {
  //   if (Object.keys(userLocation.get).length !== 0) {
  //     setCenter([
  //       Number(userLocation.get["lon"]) || 0.0,
  //       Number(userLocation.get["lat"]) || 0.0,
  //     ]);
  //     if (!props.location.search) {
  //       props.history.replace({
  //         pathname: props.location.pathname,
  //         search: `?cities=${userLocation.get["city"]}&state=${userLocation.get["state"]}&page=1&sortby=1&status=accepting_offers,coming_soon`,
  //       });
  //     }
  //   }
  // }, [userLocation.get]);

  useEffect(() => {
    if (props.location.search) {
      let query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
      setPage(Number(query.page) || 1);
      setBody({
        page: query.page || 1,
        limit: 6,
        // cities: query.cities ? query.cities.split(",") : "",
        title: query.title || "",
        ids: query.ids ? query.ids.split(",") : "",
        sortby: query.sortby || "1",
        maxPrice: query.maxPrice || "",
        minPrice: query.minPrice || "",
        status: query.status ? query.status.split(",") : "",
        listing_type: query.listing_type ? query.listing_type.split(",") : "",
        occupency_type: query.occupency_type
          ? query.occupency_type.split(",")
          : "",
        minbeds: query.minBed ? query.minBed : "",
        maxbeds: query.maxBed ? query.maxBed : "",
        minbathroom: query.minBath ? query.minBath : "",
        maxbathroom: query.maxBath ? query.maxBath : "",
        minSize: query.minSize ? query.minSize.toString() : "",
        maxSize: query.maxSize ? query.maxSize.toString() : "",
        type: query.type ? query.type.split(",") : "",
        rPrice: query.rPrice,
        bound:
          query.north && query.south && query.east && query.west
            ? {
                north: query.north,
                south: query.south,
                east: query.east,
                west: query.west,
              }
            : {},
      });
      setSelectedLocationBound({
        north: query.north,
        south: query.south,
        east: query.east,
        west: query.west,
      });
    } else {
      console.log("+++++++++++++++++Search");
    }
  }, [props.location.search]);

  useEffect(() => {
    if (props.location && props.location.search) {
      let url = new URLSearchParams(location.search);
      let urlValue = {};
      urlValue["lat"] = url.get("lat");
      urlValue["lng"] = url.get("long");
      urlValue["title"] = url.get("title");
      urlValue["cities"] = url.get("cities");
      urlValue["state"] = url.get("state");
      urlValue["radius"] = url.get("radius");
      if (
        url.get("north") &&
        url.get("south") &&
        url.get("east") &&
        url.get("west") &&
        url.get("initial") === true
      ) {
        setIsBoundSet(true);
      } else {
        setIsBoundSet(false);
      }
      setSelectedLocation(urlValue);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (location.search == "") {
      props.history.replace({
        search: "?" + process.env.REACT_APP_DEFAULT_LOCATION,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (site_id != (null || undefined) && body !== undefined) {
      getAllProperties();
      let numberofFiltersApplied = 0;
      let isBedFilterApplied = false;
      let isBathroomFilterApplied = false;
      let isSizeFilterApplied = false;

      for (const filter in body) {
        console.log("i am here filter ", filter);
        if (filter === "status" && body[filter].length > 0) {
          numberofFiltersApplied += 1;
        }

        if (filter === "listing_type" && body[filter].length > 0) {
          numberofFiltersApplied += 1;
        }

        if (filter === "occupency_type" && body[filter].length > 0) {
          numberofFiltersApplied += 1;
        }

        if (filter === "type" && body[filter].length > 0) {
          numberofFiltersApplied += 1;
        }

        if (body[filter] && (filter === "minbeds" || filter === "maxbeds")) {
          if (!isBedFilterApplied) {
            numberofFiltersApplied += 1;
            isBedFilterApplied = true;
          }
        }
        if (
          body[filter] &&
          (filter === "minbathroom" || filter === "maxbathroom")
        ) {
          if (!isBathroomFilterApplied) {
            numberofFiltersApplied += 1;
            isBathroomFilterApplied = true;
          }
        }
        if (body[filter] && (filter === "minSize" || filter === "maxSize")) {
          if (!isSizeFilterApplied) {
            numberofFiltersApplied += 1;
            isSizeFilterApplied = true;
          }
        }
      }
      setFilterCount(numberofFiltersApplied);
    }
  }, [body, site_id]);

  const getChecked = (name, value) => {
    if (name === "rPrice") {
      if (body && Object.keys(body).length !== 0) {
        return body[name] == value;
      } else {
        return false;
      }
    } else {
      if (body && Object.keys(body).length !== 0) {
        let data = body[name] ? body[name] : [];
        return indexOf(data, value) !== -1;
      } else {
        return false;
      }
    }
  };
  const searchPageChange = (event, page) => {
    setPage(page);
    let url = new URLSearchParams(props.location.search);
    url.set("page", page);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };
  const save = async () => {
    if (userLoggedIn.get === false) {
      toast.warning("Login to save search");
      return;
    }
    let data = await saveSearch(body, site_id);
    if (data.status === true) {
      toast.success("Search saved successfully");
    } else {
      toast.error("Unable to save search");
    }
  };

  const getAllProperties = async () => {
    let property = { ...body };
    if (property.status === "") {
      property.status = ["coming_soon", "for_sale", "pending", "sold"];
    }
    if (property.listing_type === "") {
      property.listing_type = ["1", "2", "3"];
    }
    if (property.occupency_type_type === "") {
      property.occupency_type_type = ["1", "2"];
    }
    let data = await getSearch(property, site_id);
    // console.log(data, "productdetails");
    if (data.status === true) {
      setViewProperties([...data.result["productdetails"]]);
      setViewAllProperties([...data.result["mapProperties"]]);
      // if (body.page == 1) {
      //   setViewProperties([...data.result["productdetails"]]);
      // } else {
      //   setViewProperties((prevData) => [
      //     ...prevData,
      //     ...data.result["productdetails"],
      //   ]);
      // }
      setCount(data["total_count"]);
    }
  };

  const onSortBy = (e) => {
    let query = new URLSearchParams(props.location.search);
    query.set("page", 1);
    query.set("sortby", e.target.value);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
    return false;
  };

  const onChangePrice = (e) => {
    let query = new URLSearchParams(props.location.search);
    query.set("page", 1);
    query.set(e.target.name, e.target.value);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + query.toString(),
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onChange = (e) => {
    if (props.location.search) {
      console.log("i am here onchange ", e);
      if (e.target.name === "rPrice") {
        let url = new URLSearchParams(props.location.search);
        url.set("page", 1);
        url.set(e.target.name, e.target.checked ? e.target.value : "");
        props.history.replace({
          pathname: props.location.pathname,
          search: "?" + url.toString(),
        });
      } else {
        let query = qs.parse(props.location.search, {
          ignoreQueryPrefix: true,
        });
        let data = query[e.target.name] ? query[e.target.name].split(",") : [];
        if (e.target.checked) {
          data = [e.target.value, ...data];
        } else {
          const position = indexOf(data, e.target.value);
          position !== -1 && data.splice(position, 1);
        }
        let url = new URLSearchParams(props.location.search);
        url.set("page", 1);
        url.set(e.target.name, data.toString());
        props.history.replace({
          pathname: props.location.pathname,
          search: "?" + url.toString(),
        });
      }
    }
  };

  const onRangeChange = (e, newValue) => {
    let newSqt = { ...sqt };
    newSqt[e.target.name] = e.target.value;
    setSqt(newSqt);
    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minSize", newSqt.minSize.length !== 0 ? newSqt.minSize : "");
    url.set("maxSize", newSqt.maxSize.length !== 0 ? newSqt.maxSize : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const onchangeBedroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBedroom = { ...beds };
    newBedroom[e.target.name] = fieldValue;
    setBeds(newBedroom);

    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minBed", newBedroom.minBeds.length ? newBedroom.minBeds : "");
    url.set("maxBed", newBedroom.maxBeds.length ? newBedroom.maxBeds : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };
  const onChangeBathroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBathroom = { ...baths };
    newBathroom[e.target.name] = fieldValue;
    setBaths(newBathroom);
    let url = new URLSearchParams(props.location.search);
    url.set("page", 1);
    url.set("minBath", newBathroom.minBaths.length ? newBathroom.minBaths : "");
    url.set("maxBath", newBathroom.maxBaths.length ? newBathroom.maxBaths : "");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };
  const getRangeP = useMemo(() => {
    return body
      ? `${body.minSize ? body.minSize + " Sq.Ft" : "0 Sq.Ft"} to ${
          body.maxSize ? body.maxSize + " Sq.Ft" : "1000+ Sq.Ft"
        }`
      : "0 Sq.Ft to 1000+ Sq.Ft";
  }, [body]);

  const getRange = useMemo(() => {
    return body
      ? [
          body.minSize ? Number(body.minSize) / 10 : 0,
          body.maxSize ? Number(body.maxSize) / 10 : 100,
        ]
      : [0, 100];
  }, [body]);

  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    function onScroll() {
      let currentPosition =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 480;
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (viewAllProperties) {
      let mapLocations = [];
      viewAllProperties.length > 0 &&
        window.google &&
        viewAllProperties.map((property) =>
          mapLocations.push({
            id: property.id,
            city: property.loc_city,
            state: property.state,
            latlng: new window.google.maps.LatLng(
              Number(property.lat),
              Number(property.lng),
            ),
          }),
        );
      setViewPropertyLocations(mapLocations);
      viewAllProperties.length > 0 && setMobileSelectOpen(false);
    }
  }, [viewAllProperties, window.google]);
  // console.log(viewPropertyLocations, "this is all properties");

  const handleMapCenterChange = (newCenter, newBound, changeZoom) => {
    // console.log(
    //   newCenter,
    //   newBound,
    //   changeZoom,
    //   "newValues handle change executed onmap"
    // );
    let url = new URLSearchParams(props.location.search);
    url.set("north", newBound.north);
    url.set("south", newBound.south);
    url.set("east", newBound.east);
    url.set("west", newBound.west);
    url.set("lat", newCenter.lat);
    url.set("long", newCenter.lng);
    url.set("cities", "");
    url.set("state", "");
    // console.log("onmap location search url", location.search, changeZoom);
    if (changeZoom === true) {
      url.set("zoom", true);
    }
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  console.log(props.location.pathname);

  const calculateNewLatLong = (startingPoint, radiusMiles, bearing) => {
    //bearing for (n, e, s, w) = (0, 90, 180, 270)

    let numDegrees = Number(radiusMiles) / 69;

    if (bearing === 0) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 90) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 180) {
      return Number(startingPoint) - numDegrees;
    } else if (bearing === 270) {
      return Number(startingPoint) - numDegrees;
    }
  };

  const handleLocationChng = (e) => {
    let selectedValue = propertyLoc.filter((ele) => {
      if (ele.id === Number(e.target.value)) {
        return ele;
      }
    });

    let url = new URLSearchParams(location.search);
    url.set("cities", "");
    url.set("state", "");
    url.set("page", 1);
    url.set("title", selectedValue[0].title);
    url.set("lat", selectedValue[0].lat);
    url.set("long", selectedValue[0].lng);
    url.set(
      "north",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 0),
    );
    url.set(
      "south",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 180),
    );
    url.set(
      "east",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 90),
    );
    url.set(
      "west",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 270),
    );
    url.set("initial", true);
    url.set("load", false);
    headerLocation.set(selectedValue);
    setSelectedLocation(selectedValue);
    if (location.pathname.includes(`/search`)) {
      history.replace({
        pathname: location.pathname,
        search: "?" + url.toString(),
        state: {
          path: location.pathname,
        },
      });
    } else {
      history.push({
        pathname: `/search`,
        // search: `?cities=${selectedValue[0].city}&state=${selectedValue[0].state}&page=1&sortby=1&status=past,accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${selectedValue[0].lat}&long=${selectedValue[0].lng}`,
        search: `?cities=&state=&title=${
          selectedValue[0].title
        }&page=1&sortby=1&listing_type=1,2,3&occupency_type=1,2&status=coming_soon,for_sale,pending,sold&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${
          selectedValue[0].lat
        }&long=${selectedValue[0].lng}&&radius=${
          selectedValue[0].radius
        }&north=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          0,
        )}&south=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          180,
        )}&east=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          90,
        )}&west=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          270,
        )}&initial=true&zoom=false&load=false`,
        state: {
          path: location.pathname,
        },
      });
    }
  };

  const handleResetButtonClick = (e) => {
    let url = new URLSearchParams(props.location.search);
    url.set("minBath", "");
    url.set("maxBath", "");
    setBaths({
      minBaths: "",
      maxBaths: "",
    });
    url.set("minBed", "");
    url.set("maxBed", "");
    setBeds({
      minBeds: "",
      maxBeds: "",
    });
    url.set("minSize", "");
    url.set("maxSize", "");
    setSqt({
      minSize: "",
      maxSize: "",
    });
    url.set("status", "coming_soon,for_sale");
    url.set("listing_type", "1,2,3");
    url.set(
      "type",
      "Single Family,Multi Family,Condominium / Townhome ,Mobile / Manufactured",
    );
    url.set("occupency_type", "1,2");
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const handleModalClose = () => {
    setMobilePropertyPopupOpen(false);
  };

  return (
    <Layout>
      <div className="search-wrapper">
        <div
          className={`search-left ${
            !width && map
              ? "mobile-sg-wrapper show-map"
              : "mobile-sg-wrapper map-hide"
          }`}
        >
          {/* {console.log(
            "on map load check",
            "viewPropertyLocations ==> ",
            viewPropertyLocations,
            "drawSearch===>",
            drawSearch,
            "selectedLocation===>",
            selectedLocation,
            "mobileSelectedPropertyId===>",
            mobileSelectedPropertyId,
            "mobilePropertyPopupOpen===>",
            mobilePropertyPopupOpen,
            "selectedLocationBound===>",
            selectedLocationBound
          )} */}
          {selectedLocation && Object.keys(selectedLocation).length !== 0 && (
            <SearchMap
              location={viewPropertyLocations}
              isDraw={drawSearch}
              userLocation={selectedLocation}
              handleMapCenterChange={handleMapCenterChange}
              mobileSelectedPropertyId={mobileSelectedPropertyId}
              setMobileSelectedPropetyId={setMobileSelectedPropetyId}
              setMobilePropertyPopupOpen={setMobilePropertyPopupOpen}
              mobilePropertyPopupOpen={mobilePropertyPopupOpen}
              width={width}
              selectedLocationBound={selectedLocationBound}
            />
          )}
          {/* <div
            className="search-bottom"
            onClick={() => setDrawSearch((preSearch) => !preSearch)}
            style={{ cursor: "pointer" }}
          >
            <a>
              <i className="fa fa-pencil-alt"></i> Draw Boundary
            </a>
          </div> */}
        </div>
        <div
          className={`search-right ${!width && !map ? "show-map" : "map-hide"}`}
        >
          <div className="srt-wrapper">
            <div className="sr-top">
              {width && (
                <>
                  <h3 className="sr-top-head">
                    {Object.keys(selectedLocation).length !== 0 &&
                    !isBoundSet &&
                    selectedLocation["title"] !== null
                      ? `${selectedLocation["title"]} Homes for Sale`
                      : // `${selectedLocation["cities"]}, ${selectedLocation["state"]} Homes for Sale`
                        `Homes for Sale`}
                  </h3>
                  <div className="d-flex glv-wrap">
                    <div className="no-results">{`${getCount} Results`}</div>
                    <div className="selectWrapper ss-wrap">
                      <span>Sort by</span>
                      <select
                        value={body ? body.sortby : "1"}
                        onChange={onSortBy}
                        className="form-control"
                      >
                        <option value="1">Ending Soon</option>
                        <option value="2">Current Bid (High to Low)</option>
                        <option value="3">Current Bid (Low to High)</option>
                        <option value="4">Newest</option>
                        <option value="5">Most Comments</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="search-filters-wrap">
              {!width && (
                <FormControl
                  variant="outlined"
                  className={`${
                    !width ? classes.mobileFormControl : classes.formControl
                  } mblSrchFrm`}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={headerLocation.get["id"]}
                    onChange={handleLocationChng}
                    displayEmpty
                    style={{ height: "45px" }}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    className={classes.selectEmpty}
                    open={mobileSelectOpen}
                    onOpen={() => setMobileSelectOpen(true)}
                    onClose={() => setMobileSelectOpen(false)}
                  >
                    <MenuItem value="" disabled>
                      View Listings
                    </MenuItem>
                    {propertyLoc &&
                      propertyLoc.length > 0 &&
                      propertyLoc.map((ele) => (
                        <MenuItem
                          key={ele.id}
                          value={ele.id}
                          disabled={ele.coming_soon === "true" ? true : false}
                        >
                          {ele.coming_soon === "true" ? (
                            <em>{`${ele.title} (Coming Soon)`}</em>
                          ) : (
                            `${ele.title}`
                          )}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                // <div className="selectWrapper ooooooo">
                //   <select
                //     className="form-control wd-240"
                //     value={headerLocation.get["id"]}
                //     onChange={handleLocationChng}
                //   >
                //     <option>View Lisitngs</option>
                //     {propertyLoc &&
                //       propertyLoc.length > 0 &&
                //       propertyLoc.map((ele) => (
                //         <option
                //           key={ele.id}
                //           value={ele.id}
                //           disabled={ele.coming_soon === "true" ? true : false}
                //         >
                //           {ele.city},{stateCode(ele.state)}{" "}
                //           {ele.coming_soon === "true" ? "(Coming Soon)" : null}
                //         </option>
                //       ))}
                //   </select>
                // </div>
              )}
              <div className="suitable">
                <CustomInput
                  // label='Min'
                  value={
                    body
                      ? callNumber(body.minPrice.split(",").join("")) == "NaN"
                        ? ""
                        : Number(
                              callNumber(body.minPrice.split(",").join("")),
                            ) === 0
                          ? ""
                          : callNumber(body.minPrice.split(",").join(""))
                      : ""
                  }
                  name="minPrice"
                  size="small"
                  onChange={onChangePrice}
                  placeholder="Min. Price"
                  className={`hh-input`}
                  startadornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
                {/* <select
                  onChange={onChangePrice}
                  name='minPrice'
                  value={body ? body.minPrice : ''}
                  className='form-control'
                >
                  <option value=''>Min Price</option>
                  <option value='1000'>$1,000</option>
                  <option value='10000'>$10,000</option>
                  <option value='100000'>$1,00,000</option>
                  <option value='1000000'>$10,00,000</option>
                </select> */}
              </div>
              <p className="px-1 m-0">-</p>
              <div className="mr-2 suitable">
                {/* <PriceInput /> */}
                <CustomInput
                  value={
                    body
                      ? callNumber(body.maxPrice.split(",").join("")) == "NaN"
                        ? ""
                        : Number(
                              callNumber(body.maxPrice.split(",").join("")),
                            ) === 0
                          ? ""
                          : callNumber(body.maxPrice.split(",").join(""))
                      : ""
                  }
                  name="maxPrice"
                  size="small"
                  onChange={onChangePrice}
                  placeholder="Max. Price"
                  className={`hh-input`}
                  startadornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
                {/* <select
                  onChange={onChangePrice}
                  name='maxPrice'
                  className='form-control'
                  value={body ? body.maxPrice : ''}
                >
                  <option value=''>Max Price</option>
                  <option value='1000'>$1,000</option>
                  <option value='10000'>$10,000</option>
                  <option value='100000'>$1,00,000</option>
                  <option value='1000000'>$10,00,000</option>
                </select> */}
              </div>
              <div className="suitable">
                <a
                  className="btn btn-border"
                  onClick={toggleDrawer("right", true)}
                >
                  Filters{"  "}
                  {filterCount > 0 ? (
                    <span className="filterBadge">{filterCount}</span>
                  ) : (
                    ""
                  )}
                </a>
              </div>
              {width ? (
                <div className="ml-2">
                  <a className="btn btn-primary" onClick={save}>
                    Save Search
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            className={width ? "sg-wrapper" : "sg-wrapper mobile-sg-wrapper"}
          >
            <SearchGrid properties={viewProperties} />
          </div>
          {console.log(viewProperties, "viewProperties")}
          {!!viewProperties.length && getCount > 6 && (
            <div>
              <Pagination
                count={Math.ceil(getCount / 6)}
                page={page}
                onChange={searchPageChange}
                siblingCount={3}
                boundaryCount={2}
              />
            </div>
          )}
          {!width && !map ? (
            <div className={`map-sort ${scrollTop ? "bottom" : "top"}`}>
              <div className="map-toggle" onClick={toggleMap}>
                <i className="far fa-map"></i> Map
              </div>
              <div className="selectWrapper ss-wrap">
                <span className="sort-icon">
                  <i className="fas fa-sort-amount-down"></i>
                </span>
                <select
                  value={body ? body.sortby : "1"}
                  onChange={onSortBy}
                  className="form-control"
                >
                  <option value="1">Ending Soon</option>
                  <option value="2">Current Bid (High to Low)</option>
                  <option value="3">Current Bid (Low to High)</option>
                  <option value="4">Newest</option>
                  <option value="5">Most Comments</option>
                </select>
              </div>
            </div>
          ) : (
            <div
              className={`map-sort list-toggle ${scrollTop ? "bottom" : "top"}`}
            >
              <div className="map-toggle" onClick={toggleMap}>
                <i className="far fa-map"></i> List
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={mobilePropertyPopupOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {mobileSelectedPropertyId &&
          viewProperties.filter(
            (property) => property.id == mobileSelectedPropertyId,
          ).length > 0 ? (
            <SearchCard
              item={
                viewProperties.filter(
                  (property) => property.id == mobileSelectedPropertyId,
                )[0]
              }
            />
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        className="getEstimateDrawer"
      >
        <div className="searchFilter">
          <div className="row">
            <div className="col">
              <h2 className={width ? "filterPhoneDrawer" : ""}>Filters</h2>
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              {!width ? (
                <Button
                  className="clsDrBtn"
                  onClick={toggleDrawer("right", false)}
                  style={{ textTransform: "none", color: "var(--primColor)" }}
                >
                  <span className="pt-1">View Listings</span>
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="sfInner">
            <h5>Listing Type</h5>

            <CheckBox
              onchange={onChange}
              name="listing_type"
              value="1"
              label="Property"
              checked={getChecked("listing_type", "1")}
            />
            <CheckBox
              onchange={onChange}
              name="listing_type"
              value="2"
              label="Land"
              checked={getChecked("listing_type", "2")}
            />
            <CheckBox
              onchange={onChange}
              name="listing_type"
              value="3"
              label="Commercial"
              checked={getChecked("listing_type", "3")}
            />
          </div>

          <div className="sfInner">
            <h5>Status</h5>

            <CheckBox
              onchange={onChange}
              name="status"
              value="coming_soon"
              label="Coming Soon"
              checked={getChecked("status", "coming_soon")}
            />
            <CheckBox
              onchange={onChange}
              name="status"
              value="for_sale"
              label="For Sale"
              checked={getChecked("status", "for_sale")}
            />
            <CheckBox
              onchange={onChange}
              name="status"
              value="pending"
              label="Pending"
              checked={getChecked("status", "pending")}
            />
            <CheckBox
              onchange={onChange}
              name="status"
              value="sold"
              label="Sold"
              checked={getChecked("status", "sold")}
            />
          </div>
          <div className="sfInner">
            <h5>Beds</h5>
            <div className="row">
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Min'
                  value={beds.minBeds}
                  name="minBeds"
                  size="small"
                  onChange={onchangeBedroom}
                  // onBlur={handleBlur}
                  // className={`hh-input ${
                  //   errors.cardname && touched.cardname && "is-invalid"
                  // }`}
                  placeholder="Min"
                  className={`hh-input`}
                />
              </div>
              <p className="m-0 pt-2">-</p>
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Max'
                  value={beds.maxBeds}
                  name="maxBeds"
                  size="small"
                  onChange={onchangeBedroom}
                  // onBlur={handleBlur}
                  // className={`hh-input ${
                  //   errors.cardname && touched.cardname && "is-invalid"
                  // }`}
                  placeholder="Max"
                  className={`hh-input`}
                />
              </div>
            </div>
          </div>
          <div className="sfInner">
            <h5>Baths</h5>
            <div className="row">
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Min'
                  value={baths.minBaths}
                  name="minBaths"
                  size="small"
                  onChange={onChangeBathroom}
                  // onBlur={handleBlur}
                  // className={`hh-input ${
                  //   errors.cardname && touched.cardname && "is-invalid"
                  // }`}
                  placeholder="Min"
                  className={`hh-input`}
                />
              </div>
              <p className="m-0 pt-2">-</p>
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Max'
                  value={baths.maxBaths}
                  name="maxBaths"
                  size="small"
                  onChange={onChangeBathroom}
                  // onBlur={handleBlur}
                  // className={`hh-input ${
                  //   errors.cardname && touched.cardname && "is-invalid"
                  // }`}
                  placeholder="Max"
                  className={`hh-input`}
                />
              </div>
            </div>
          </div>
          <div className="sfInner">
            <h5>Size</h5>
            {/* <CheckBox label="1000sqft - 2000sqft" />
            <CheckBox label="2000sqft - 4000sqft" />
            <CheckBox label="4000sqft and above" /> */}
            {/* <p>{getRangeP}</p> */}
            {/* <Slider
              value={getRange}
              aria-labelledby="range-slider"
              onChange={onRangeChange}
            /> */}

            <div className="row">
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Min'
                  value={
                    callNumber(sqt.minSize.split(",").join("")) == "NaN"
                      ? ""
                      : Number(callNumber(sqt.minSize.split(",").join(""))) ===
                          0
                        ? ""
                        : callNumber(sqt.minSize.split(",").join(""))
                  }
                  name="minSize"
                  size="small"
                  onChange={onRangeChange}
                  // onBlur={handleBlur}
                  // className={`hh-input ${
                  //   errors.cardname && touched.cardname && "is-invalid"
                  // }`}
                  className={`hh-input`}
                  placeholder="Min"
                  endadornment={
                    <InputAdornment position="end">Sq.Ft.</InputAdornment>
                  }
                />
              </div>
              <p className="m-0 pt-2">-</p>
              <div className="col-md-4 col-5 form-group">
                <CustomInput
                  // label='Max'
                  value={
                    callNumber(sqt.maxSize.split(",").join("")) == "NaN"
                      ? ""
                      : Number(callNumber(sqt.maxSize.split(",").join(""))) ===
                          0
                        ? ""
                        : callNumber(sqt.maxSize.split(",").join(""))
                  }
                  name="maxSize"
                  size="small"
                  onChange={onRangeChange}
                  // onBlur={handleBlur}
                  className={`hh-input`}
                  placeholder="Max"
                  endadornment={
                    <InputAdornment position="end">Sq.Ft.</InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
          <div className="sfInner">
            <h5>Type</h5>
            <CheckBox
              checked={getChecked("type", "Single Family")}
              onchange={onChange}
              name="type"
              value="Single Family"
              label="Single Family"
            />
            <CheckBox
              checked={getChecked("type", "Multi Family")}
              onchange={onChange}
              name="type"
              value="Multi Family"
              label="Multi Family"
            />
            <CheckBox
              checked={getChecked("type", "Condominium / Townhome")}
              onchange={onChange}
              name="type"
              value="Condominium / Townhome"
              label="Condominium / Townhome"
            />
            <CheckBox
              checked={getChecked("type", "Mobile / Manufactured")}
              onchange={onChange}
              name="type"
              value="Mobile / Manufactured"
              label="Mobile / Manufactured"
            />
          </div>

          <div className="sfInner">
            <h5>Occupency Type</h5>
            <CheckBox
              checked={getChecked("occupency_type", "1")}
              onchange={onChange}
              name="occupency_type"
              value="1"
              label="Owner Occupency"
            />
            <CheckBox
              checked={getChecked("occupency_type", "2")}
              onchange={onChange}
              name="occupency_type"
              value="2"
              label="Investor Occupency"
            />
          </div>

          <div style={{ width: "100px", marginTop: "20px" }}>
            <button className="btn btn-border" onClick={handleResetButtonClick}>
              Reset
            </button>
          </div>
        </div>
      </Drawer>
    </Layout>
  );
};

export default Search;
