import React, { useEffect, useState } from "react";
import "./Cart.css";
import Layout from "../../../components/molecules/Layout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { confirmCheckout, getCheckout } from "../../../API/propertyDetails";
import {
  setDecimalPoint,
  showSuccess,
  showWaring,
  alertWithButton,
} from "../../../utils";
import { useHistory, useParams } from "react-router-dom";
import CustomDialog from "../../../components/atoms/CustomDialog";

const Cart = () => {
  const [checkoutProduct, setCheckoutProduct] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const [confirmPopup, setConfirmPopup] = useState(false);

  useEffect(() => {
    getCheckoutProduct(id);
  }, []);

  const getCheckoutProduct = async (id) => {
    try {
      let res = await getCheckout(id, site_id);
      if (res && res.status === true) {
        if (res.data.length > 0) {
          setCheckoutProduct(res.data[0]);
        }
      } else if (res && res.status === false) {
        showWaring(res.message);
        history.push("/");
      }
    } catch (err) {
      console.log(err, "add tour err");
    }
  };

  const handleCheckout = async (id) => {
    try {
      let res = await confirmCheckout(id, site_id);
      if (res && res.status === true) {
        // showSuccess("Property Purchased Successfully");
        // history.push("/");
        setConfirmPopup(true);
      } else if (res && res.status === false) {
        showWaring(res.message);
        history.push("/");
      }
    } catch (err) {
      console.log(err, "add tour err");
    }
  };

  return (
    <Layout>
      {checkoutProduct ? (
        <div className="container py-5">
          <div className="cartWrapper">
            <h2 className="mb-4">Checkout</h2>
            <div className="cartInnerWrapper">
              <div className="cartCardWrapper">
                <div className="cartCard">
                  <div className="cartImgWrpr">
                    <img
                      src={
                        checkoutProduct.avatar != ""
                          ? process.env.REACT_APP_IMAGE_URL +
                            `uploads/product/${checkoutProduct.avatar}`
                          : "/images/doorseyimageholder.png"
                      }
                    />
                  </div>
                  <div className="cartPdtInfo">
                    <h5>{checkoutProduct.loc_address.split(",")[0]}</h5>
                    <p className="cartPdtPrice">
                      ${setDecimalPoint(checkoutProduct.bprice)}
                    </p>
                    <p className="mb-0">
                      {checkoutProduct.loc_address.split(",")[1]}
                      <br />
                      {checkoutProduct.loc_address.split(",")[2]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="cartPriceWrapper">
                <div className="cartPriceCard">
                  <h3>Payment Info</h3>
                  <ul className="paymentInfoDetails">
                    <li>
                      <label>Price</label>
                      <span>${setDecimalPoint(checkoutProduct.bprice)}</span>
                    </li>
                    <li>
                      <label>Fee (1%)</label>
                      <span>${setDecimalPoint(checkoutProduct.fee)}</span>
                    </li>
                  </ul>
                  <hr />
                  <ul className="paymentInfoDetails mb-0">
                    <li className="mt-0">
                      <label>Total Payment</label>
                      <span className="totalFeeAmt">
                        ${setDecimalPoint(checkoutProduct.total_amount)}
                      </span>
                    </li>
                  </ul>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <PrimaryButton
                        label="Buy Now"
                        onClick={() => handleCheckout(id)}
                      />
                    </div>
                  </div>
                  {/* <div className="cartPayCard mt-4">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <CustomInput
                          label="Card Number"
                          placeholder="Enter your card number"
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <CustomInput label="MM" placeholder="MM" />
                      </div>
                      <div className="col-md-3 mt-3">
                        <CustomInput label="YYYY" placeholder="YYYY" />
                      </div>
                      <div className="col-md-6 mt-3">
                        <CustomInput label="CVV" placeholder="Enter CVV" />
                      </div>
                      <div className="col-md-12 mt-3">
                        <CustomInput
                          label="Card Holder Name"
                          placeholder="Enter card holder name"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <PrimaryButton label="Make Payment" />
                      </div>
                    </div>
                  </form>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <CustomDialog open={confirmPopup}>
        <div className="confimBuyPopup">
          <div className="spIcn">
            <span className="material-icons">check_circle</span>
          </div>
          <h2>Buy Now Confirmation</h2>
          <p>
            You’re almost there. The seller will be in touch with you shortly.
          </p>
          <PrimaryButton label="Submit" onClick={() => history.push("/")} />
        </div>
      </CustomDialog>
    </Layout>
  );
};

export default Cart;
