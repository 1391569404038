import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FavoriteCheckbox from "../FavoriteCheckbox";
import ListingCard from "../ListingCard";

const MyListingCard = ({ item, props }) => {
  return (
    <div className="aw-card">
      <ListingCard item={item} from="mylisting" />
      {/* <div className="awc-bottom">
        {item.market_status == "open" ||
        item.market_status == "closed" ||
        item.market_status == "sold" ? (
          <>
            <div className="clearfix aw-steps">
              <div className="clearfix aw-step completed">
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Auction Live</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  Number(item.rprice) <= Number(item.wprice)
                    ? "completed"
                    : "active"
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Reserve Met</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.paid === "true"
                    ? "completed"
                    : Number(item.rprice) <= Number(item.wprice)
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Buyers Payment</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.docu_signed === "true"
                    ? "completed"
                    : item.paid === "true"
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Sign Documents</div>
              </div>
              <div
                className={`clearfix ${
                  item.under_contract === "true"
                    ? "completed"
                    : item.docu_signed === "true"
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Under Contract</div>
              </div>
            </div>
            <p>
              Please check you email for the standardized signatures documents
              sent by our team
            </p>
          </>
        ) : (
          <>
            <div className="clearfix aw-steps">
              <div className="clearfix aw-step completed">
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Submit House</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.admin_approved ? "completed" : "active"
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Approved</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.transactionId
                    ? "completed"
                    : item.admin_approved == 1
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Payment</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.ownerPdf
                    ? "completed"
                    : item.transactionId
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Create Listing</div>
              </div>
              <div
                className={`clearfix aw-step ${
                  item.market_status == "open"
                    ? "completed"
                    : item.ownerPdf
                    ? "active"
                    : ""
                }`}
              >
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Seller Approval</div>
              </div>
              <div className="clearfix">
                <div className="step-circle">
                  <i className="fa fa-check"></i>
                </div>
                <div>Auction Live</div>
              </div>
            </div>
            <p>
              Review the listing that we have sent to you for final approval
              before your home goes live.
            </p>
          </>
        )}
      </div> */}
    </div>
  );
};

export default MyListingCard;
