import React, { useState, useContext, useRef, useEffect } from "react";
import moment from "moment";
import RuumrModal from "../Modal";
import BiddingForm from "./BiddingForm";
import GlobalContext from "../../../context/GlobalContext";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setDecimalPoint } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  customFontSize: {
    fontSize: "13px",
  },
}));

const PropertyBids = ({
  data,
  timer,
  nextBidAmount,
  setNextBidAmount,
  seyProductDetails,
  bidContainer,
  width,
  isMobile,
  myClientsList,
  myAgent,
  from,
  setSubmittedPracticeOffer,
  submittedPracticeOffer,
  setModelopen,
  modalopen,
  submittingBid,
  highestBidder,
}) => {
  // const [modalopen, setModelopen] = useState(false);

  const { userLoggedIn, userDetails } = useContext(GlobalContext);
  const classes = useStyles();

  const mobileWidth = useMediaQuery("(max-width:766px)");

  // useEffect(() => {
  //   const scrollTo = window.location.href.slice(
  //     window.location.href.indexOf("#")
  //   );
  //   scrollTo === "#bids" &&
  //     bidContainer.current.scrollIntoView({ behavior: "smooth" });
  // }, [window.location.href]);

  const toggleModal = () => {
    setModelopen(!modalopen);
  };
  return (
    <div className="bids-box-wrapper mb-5" ref={bidContainer}>
      {from === "practice" ? (
        ""
      ) : (
        <div className="bo-property-head">Submit a Bid</div>
      )}

      <div className="bids-box">
        <div className="bids-content">
          {!mobileWidth && (
            <>
              <div>
                <div className="bc-head">
                  ${data.wprice === 1 ? " -" : setDecimalPoint(data.wprice)}
                </div>
                <div>Current Bid</div>
              </div>
              <div>
                <div className="pvd-tc no-wrap">
                  {Object.keys(timer).length !== 0 && (
                    <>
                      {timer.isCompleted === true ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          Closed
                        </span>
                      ) : timer.text === "Bidding starts" ? (
                        <span className="bc-head" style={{ color: "#000" }}>
                          {moment(data.date_added).format("MM/DD/YY") +
                            " at " +
                            moment(data.date_added).format("LT")}
                        </span>
                      ) : (
                        <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                      )}
                    </>
                  )}
                </div>
                <div className="pl-text">
                  {Object.keys(timer).length !== 0 &&
                    timer.isCompleted === false &&
                    timer.text}
                </div>
              </div>
            </>
          )}
          {mobileWidth && from === "practice" ? (
            <>
              <div>
                <div className="bc-head">
                  ${data.wprice === 1 ? " -" : setDecimalPoint(data.wprice)}
                </div>
                <div>Current Bid</div>
              </div>
              <div>
                <div className="pvd-tc">
                  {Object.keys(timer).length !== 0 && (
                    <>
                      {timer.isCompleted === true ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          Closed
                        </span>
                      ) : timer.text === "Bidding starts" ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          {moment(data.date_added).format("MM/DD/YY") +
                            " at " +
                            moment(data.date_added).format("LT")}
                        </span>
                      ) : (
                        <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                      )}
                    </>
                  )}
                </div>
                <div className="pl-text">
                  {Object.keys(timer).length !== 0 &&
                    timer.isCompleted === false &&
                    timer.text}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {from === "practice" && mobileWidth ? (
            ""
          ) : (
            <div>
              <div className="bc-head">{data.bidcount}</div>
              <div className="bc-text">Bids</div>
            </div>
          )}

          {mobileWidth && from !== "practice" ? (
            <div>
              <div className="bc-head">{data.views || 0}</div>
              <div className="bc-text">Views</div>
            </div>
          ) : (
            ""
          )}
          <div>
            <div className="bc-head no-wrap">
              {moment(data.date_closed).format("MM/DD/YY") +
                " at " +
                moment(data.date_closed).format("LT")}
            </div>
            <div className="bc-text">Ending on</div>
          </div>
        </div>
        <div className="bidSts">
          {(data.market_status === "open" || "sold") &&
          data.wprice != 1 &&
          data.rprice != 0 &&
          data.admin_approved == 1 &&
          data.show_min_price_indicator === "true" &&
          data.bidcount > 0 ? (
            <>
              {data.wprice >= data.rprice
                ? `Minimum Sale Price Achieved`
                : `Minimum Sale Price Not Met`}
            </>
          ) : null}
        </div>
        <button className="btn btn-primary w-100" onClick={submittingBid}>
          {userLoggedIn.get
            ? userDetails.get["id"] == data.user_id
              ? "Can't bid on own property"
              : timer.isCompleted === false &&
                  timer.text === "Bid Due In" &&
                  highestBidder &&
                  !!Object.keys(userDetails.get).length &&
                  userDetails.get["id"] == highestBidder
                ? "You are High Bidder"
                : timer.isCompleted === false && timer.text === "Bid Due In"
                  ? //&& userDetails.get["bid_approval"] === "true"
                    "Submit a Bid"
                  : timer.isCompleted === true
                    ? "Bidding has closed"
                    : "ACCEPTING BIDS SOON"
            : "Register to Bid"}
        </button>
        {from === "practice" ? (
          ""
        ) : (
          <p className="property-head text-center my-2">
            <Tooltip
              title="The home will be accepting bids in real-time, online on the Live Bidding date. Before you can submit a bid, you must register to bid. The timer resets to 3 minutes for each bid received after the auction end time. When bidding closes and the seller's minimum has been reached, the highest bidder will be contacted by the seller to complete the transaction."
              classes={{ tooltip: classes.customFontSize }}
              arrow
              placement="top"
            >
              <span className="how-comments-work">How Bidding Works</span>
            </Tooltip>
          </p>
        )}
      </div>
      <RuumrModal
        open={modalopen}
        handleClose={toggleModal}
        modalClass="biddingModal"
      >
        <BiddingForm
          data={data}
          timer={timer}
          limit="unlimited"
          customerid={
            userDetails.get.customerid ? userDetails.get.customerid : ""
          }
          nextBidAmount={nextBidAmount}
          setNextBidAmount={setNextBidAmount}
          seyProductDetails={seyProductDetails}
          handleClose={toggleModal}
          width={width}
          isMobile={isMobile}
          highestBidder={highestBidder}
          myClientsList={myClientsList}
          myAgent={myAgent}
          from={from}
          setSubmittedPracticeOffer={setSubmittedPracticeOffer}
          submittedPracticeOffer={submittedPracticeOffer}
        />
      </RuumrModal>
    </div>
  );
};

export default PropertyBids;
