import React, { useState, useEffect, useContext, useMemo } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import AuctionLostList from "../../../components/molecules/Dashboard/AuctionLostList";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";
import { setDecimalPoint } from "../../../utils";

const Mybids = (props) => {
  const [winningBids, setWinningBids] = useState([]);
  const [activeBid, setActiveBid] = useState(0);
  const [losingBids, setLosingBids] = useState([]);
  const { userDetails } = useContext(GlobalContext);

  useEffect(() => {
    if (Object.keys(userDetails.get).length !== 0) {
      getData();
    }
  }, [userDetails.get]);

  const dryPrice = useMemo(
    () =>
      Object.keys(userDetails.get).length !== 0
        ? userDetails.get["limit"] - activeBid
        : 0,
    [activeBid],
  );

  const getData = async () => {
    const { data } = await axios.post(
      "myBids",
      { status: "open" },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      let win = [];
      let lose = [];
      let amount = 0;
      data.result.forEach((element) => {
        if (element.wUser == userDetails.get["id"]) {
          element["winning"] = true;
          if (element.pBid > element.nBid) {
            amount = amount + element["pBid"];
          } else {
            amount = amount + element["nBid"];
          }
          win.push(element);
        } else {
          element["winning"] = false;
          lose.push(element);
        }
      });
      setWinningBids([...win]);
      setActiveBid(amount);
      setLosingBids([...lose]);
    }
  };

  const store_id = window.location.pathname.split("/")[1];
  return (
    <DBLayout>
      {userDetails.get["profiletype"] === "agent" ? (
        ""
      ) : (
        <div className="my-bids-boxWrap">
          <div
            className="edit"
            onClick={() => props.history.push(`/editBidder`)}
          >
            <i className="fa fa-edit"></i> Edit
          </div>
          <div className="my-bids-box">
            <div>
              <div className="mbb-data">${setDecimalPoint(activeBid)}</div>
              <div className="mbb-label">Active Bid</div>
            </div>
            <div>
              <div className="mbb-data">${setDecimalPoint(dryPrice)}</div>
              <div className="mbb-label">Remaining Bid Power</div>
            </div>
            <div>
              <div className="mbb-data">
                ${setDecimalPoint(userDetails.get["limit"])}
              </div>
              <div className="mbb-label">Purchasing Power</div>
            </div>
          </div>
        </div>
      )}

      <div className="clearfix">
        <h2 className="pp-head float-left">
          Active Bids <span>{winningBids.length} Properties</span>
        </h2>
      </div>
      <div className="ms-wrapper">
        <AuctionLostList data={winningBids} />
      </div>
      <div className="clearfix mt-4">
        <h2 className="pp-head float-left">
          Losing Bids <span>{losingBids.length} Properties</span>
        </h2>
      </div>
      <div className="ms-wrapper">
        <AuctionLostList data={losingBids} />
      </div>
      {/* <RecentlyAdded /> */}
    </DBLayout>
  );
};

export default Mybids;
