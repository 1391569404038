import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import "./dashboard.css";
import axios from "../../../utils/axiosconfig";

const MenuLinks = (props) => {
  const { userDetails, verificationStatus, agentVerification } =
    useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const [myListing, setMyListing] = useState([]);
  const [verificationCount, setVerificationCount] = useState(0);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const getData = async () => {
    const { data } = await axios.get("mylisting", {
      headers: { site_id },
    });
    if (data.status === true) {
      setMyListing(data.result.MyListings);
    }
  };

  const calculateVerificationCount = () => {
    let count = 0;
    let notificationStatusPayload =
      userDetails.get["profiletype"] === "agent"
        ? agentVerification.get
        : userDetails.get["profiletype"] === "customer" ||
            userDetails.get["profiletype"] === "builder"
          ? verificationStatus.get
          : "";
    if (notificationStatusPayload) {
      for (const property in notificationStatusPayload) {
        if (!notificationStatusPayload[property]) {
          count += 1;
        }
      }
    }
    setVerificationCount(count);
  };

  useEffect(() => {
    getData();
  }, [site_id]);

  useEffect(() => {
    // console.log(location.pathname, "ths is location");

    if (location.pathname.includes("/myclients")) {
      history.push(`/agentverification`);
    }
  }, [location]);

  useEffect(() => {
    if (userDetails && userDetails.get) {
      calculateVerificationCount();
    }
  }, [verificationStatus.get, agentVerification.get, userDetails.get]);

  return (
    <div className="cwhite-box dbw-box">
      <h5>Private Profile</h5>
      <ul className="db-menu">
        <li className="d-none">
          <NavLink to={`/getting-started`} activeClassName="active">
            Getting Started
          </NavLink>
        </li>
        {/* {userDetails.get.role === "1" ? ( */}
        <>
          {userDetails.get["profiletype"] === "bidder" ||
          userDetails.get["profiletype"] === "customer" ||
          userDetails.get["profiletype"] === "builder" ? (
            <>
              {" "}
              {/* <li>
                <NavLink to="/myagent" activeClassName="active">
                  My Agent
                </NavLink>
              </li> */}
              <li className="buyer-verification-link d-none">
                <NavLink to={`/buyerverification`} activeClassName="active">
                  Buyer Verification
                  {verificationCount ? <span>{verificationCount}</span> : ""}
                </NavLink>
              </li>
            </>
          ) : (
            ""
          )}
          {userDetails.get["profiletype"] === "agent" ? (
            <>
              <li className="buyer-verification-link d-none">
                <NavLink to={`/agentverification`} activeClassName="active">
                  Client and Verification
                  {verificationCount ? <span>{verificationCount}</span> : ""}
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/myclients" activeClassName="active">
                  My Clients
                </NavLink>
              </li> */}
            </>
          ) : (
            ""
          )}
          {myListing &&
          myListing.length > 0 &&
          myListing.some((listing) => listing.calStatus === "true") ? (
            <li className="d-none">
              <NavLink to={`/sellercalendar`} activeclassname="active">
                Seller Availability
              </NavLink>
            </li>
          ) : null}
          <li>
            <NavLink to={`/watchlist`} activeClassName="active">
              Following
            </NavLink>
          </li>
          <li className="d-none">
            <NavLink to={`/my-bids`} activeClassName="active">
              My Bids (Active Properties)
            </NavLink>
          </li>
          {/* {!userDetails.get.customerid || userDetails.get.customerid === "" ? (
            <li>
              <NavLink to="/editBidder" activeClassName="active">
                Register to Submit Offer
              </NavLink>
            </li>
          ) : (
            ""
          )} */}

          <li className="d-none">
            <NavLink to={`/upcoming`} activeClassName="active">
              Upcoming Showings
            </NavLink>
          </li>
          <li className="d-none">
            <NavLink to={`/auctions-won`} activeClassName="active">
              Properties Won
            </NavLink>
          </li>
        </>
        {/* // ) : (
        //   <>
        //     <li>
        //       <NavLink to="/active-auctions" activeclassname="active">
        //         Active Auctions
        //       </NavLink>
        //     </li>
        //     <li>
        //       <NavLink to="/sold-auctions" activeclassname="active">
        //         Sold Auctions
        //       </NavLink>
        //     </li>
        //     <li>
        //       <NavLink to="/unsold-auctions" activeclassname="active">
        //         Unsold Auction
        //       </NavLink>
        //     </li>
        //     <li>
        //       <NavLink to="/payment" activeclassname="active">
        //         Payment
        //       </NavLink>
        //     </li>
        //     <li>
        //       <NavLink to="/notifications" activeclassname="active">
        //         Notification
        //       </NavLink>
        //     </li>
        //   </>
        // )} */}
      </ul>
      <h5>Public Profile</h5>
      <ul className="db-menu">
        <li>
          <NavLink to={`/profile`} activeClassName="active">
            My Profile
          </NavLink>
        </li>
        <li>
          <NavLink to={`/comments`} activeClassName="active">
            My Questions & Comments
          </NavLink>
        </li>
        <li className="d-none">
          <NavLink to={`/listings`} activeClassName="active">
            My Listings
          </NavLink>
        </li>
        {userDetails.get.role === "1" && (
          <li className="d-none">
            <NavLink to={`/bids-closed`} activeClassName="active">
              My Bids (Closed Properties)
            </NavLink>
          </li>
        )}
      </ul>
      <h5 className="d-md-none">Resources</h5>
      <ul className="db-menu db-menu-mobile d-md-none">
        <li>
          <a href="https://www.doorsey.com/faqs">FAQ's</a>
        </li>
        <li>
          <a href="mailto:info@doorsey.com">Contact Us</a>
        </li>
      </ul>
    </div>
  );
};

export default MenuLinks;
