import React, { useContext, useEffect, useState } from "react";
import FavoriteCheckbox from "../FavoriteCheckbox";
import { useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import "./search-card.css";
import {
  numberWithCommas,
  setDecimalPoint,
  onImageError,
  onPropertyImageError,
  getTimeInterval,
} from "../../../utils";
import { abbrState } from "../../../utils/stateToAbbr";
import moment from "moment";

const SearchCard = ({ item, props }) => {
  let history = useHistory();
  let location = useLocation().search;
  let newUrl = new URLSearchParams(location);
  const { currentTime } = useContext(GlobalContext);
  const [timer, setTimer] = useState({});
  const store_id = window.location.pathname.split("/")[1];

  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          item.start_date,
          item.end_date,
          currentTime.get,
          item.market_status,
          item.offer_type,
        ),
      );
  }, [currentTime.get]);
  return (
    <div
      className="clearfix card grid-card mb-2"
      onClick={() => {
        if (item.site_id == global?.storeDetails?.site_id) {
          history.push({
            pathname: `/property/${item.id}`,
            search: "",
          });
        } else {
          window.open(
            `${
              item.domain !== "" ? item.domain : "/" + item.store_id
            }/property/${item.id}`,
          );
        }
      }}
      style={{ cursor: "pointer" }}
    >
      {item.buynow == 1 ? <div className="buyChip">Buy Now</div> : ""}
      <img
        src={
          item.avatar === null
            ? "/images/noimage.png"
            : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${item.avatar}`
        }
        alt=""
        onError={onPropertyImageError}
        className="grid-card-img"
      />
      <div className="nf-list">
        {/* {console.log(
          item.loc_address.split(",")[0] + "....",
          "Auction: " + Boolean(item.auction),
          "Buy now: " + Boolean(item.buynow),
          "Offer: " + Boolean(item.offer),
          "checkStatus"
        )}
        {console.log(item, "checkItem")}
        {console.log(timer, "checktimer")} */}
        <div className="grid-timer">
          {!timer.isCompleted && <i className="far fa-clock"></i>}{" "}
          {Object.keys(timer) !== 0 && timer.isCompleted ? (
            <span className="text-capitalize">
              {item.market_status === "open" ? "Ended" : item.listing_status}
            </span>
          ) : timer.text === "Bidding starts" ? (
            <>
              {timer.status +
                " " +
                moment(item?.start_date).format("MM/DD/YY") +
                " at " +
                moment(item?.start_date).format("LT")}
            </>
          ) : (
            `${timer.status} ${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="grid-bottom">
          <div className="itmPrc">
            $
            {item.offer_type === "Auction" && item.auction === 1
              ? item.bidCount
                ? setDecimalPoint(item.wprice)
                : setDecimalPoint(item.sprice)
              : item.offer_type === "Buynow" && item.buynow === 1
                ? setDecimalPoint(item.bprice)
                : item.offer_type === "Offer" && item.offer === 1
                  ? setDecimalPoint(item.oprice)
                  : "-"}
          </div>
          {/* <div className="itmAdrs">{item.title},</div> */}
          <div className="itmAdrs">{item.loc_address.split(",")[0]},</div>
          <div className="itmCty">
            {item.loc_city},{" "}
            {abbrState(item.state, "abbr")
              ? abbrState(item.state, "abbr")
              : item.state}{" "}
            {item.zipcode || ""}
          </div>
          <div className="clearfix itmSpecs">
            {item.listing_type !== 2 && item.bedroom_type !== ""
              ? `${item.bedroom_type} Bed | `
              : null}
            {item.listing_type !== 2 && item.bathroom !== ""
              ? `${item.bathroom} Bath | `
              : null}
            {item.listing_type && item.sqrtarea
              ? `${numberWithCommas(item.sqrtarea)} ${
                  item.listing_type == 2 ? "Acres" : "sq. ft."
                }`
              : null}
            {/* {item.listing_type !== 2
              ? `${item.bedroom_type} Bed | ${item.bathroom} Bath | `
              : null} */}
            {/* {item.sqrtarea ? numberWithCommas(item.sqrtarea) : 0}{" "}
            {item.listing_type == 2 ? "Acres" : "sq. ft."} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
