import React, { useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import RadioBox from "../../atoms/RadioBox";
import PrimaryButton from "../../atoms/PrimaryButton";
import Radio from "@material-ui/core/Radio";
import "./scheduleTour.css";
import CustomInput from "../../atoms/Inputs/CustomInput";
import moment from "moment";
import isAuthenticate from "../../../utils/auth";
import GlobalContext from "../../../context/GlobalContext";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(5),
  },
}))(MuiDialogContent);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, data, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const PropertyTimePopup = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    data,
    onClickConfirmTour,
    selectSlot,
    setEmailAddressChng,
    agentEmailAdd,
    seletedTab,
  } = props;

  const [radioValue, setRadioValue] = useState("");
  const { userLoggedIn, userDetails } = useContext(GlobalContext);

  const handleRadioValueChange = (e) => {
    setRadioValue(e.target.value);
    if (radioValue == "2") {
      setEmailAddressChng("");
    }
  };

  const handleClose = () => {
    onClose(selectedValue);
    setEmailAddressChng("");
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const agentEmailAddressChange = (e) => {
    setEmailAddressChng(e.target.value, radioValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle onClose={handleClose} />
      <DialogContent>
        <div className="text-center">
          <p>Your tour:</p>
          <p>
            {data.loc_address}, {data.loc_city}, {data.state} {data.zipcode}
          </p>
          <p>
            {moment(selectSlot.start).format("dddd,MMMM DD,YYYY")} at{" "}
            {moment(selectSlot.start).format("LT")}
          </p>

          {isAuthenticate() !== undefined && seletedTab === 0 ? (
            <>
              <p>
                Offerers must be accompanied by a real estate agent to set a
                private tour. We'll send out calendar invites and notify the
                seller.
              </p>
              <div>
                <div className="text-left">
                  <Radio
                    value="1"
                    checked={radioValue === "1"}
                    onChange={handleRadioValueChange}
                  />
                  <label>I already have an agent</label>
                </div>
                {radioValue === "1" ? (
                  <div className="agent-email">
                    <CustomInput
                      label={"Your Agent's Email"}
                      value={agentEmailAdd}
                      onChange={agentEmailAddressChange}
                    />
                  </div>
                ) : null}
                <div className="text-left">
                  <Radio
                    value="2"
                    checked={radioValue === "2"}
                    onChange={handleRadioValueChange}
                  />
                  <label>Tour with listing agent or representative</label>
                </div>
              </div>
            </>
          ) : null}
          {isAuthenticate() === undefined && seletedTab === 1 ? (
            <CustomInput
              label={"Please enter your email address to book the slot"}
              value={agentEmailAdd}
              onChange={agentEmailAddressChange}
              className="my-2"
            />
          ) : null}
          <div className="confrm-tour">
            <PrimaryButton label="Confirm Tour" onClick={onClickConfirmTour} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PropertyTimePopup;
