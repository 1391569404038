import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PrimaryButton from "../PrimaryButton";
import CustomInput from "../Inputs/CustomInput";
import * as Yup from "yup";
import axios from "../../../utils/axiosconfig";
import { normalizePhoneNumberInput } from "../../../utils/FormikUtils";
import "./dialog.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [emailOptions, setEmailOptions] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    if (props.isResetOld) {
      resetOldAgentField();
      props.setIsResetOld(false);
    }
  }, [props.isResetOld]);

  useEffect(() => {
    if (props.isResetNew) {
      resetNewAgentField();
      props.setIsResetNew(false);
    }
  }, [props.isResetNew]);
  const site_id = localStorage.getItem("site_id");

  const suggestAgentEmail = async (event, val) => {
    val = val.trim();
    setInputVal(val);
    if (val !== "" && val !== null) {
      const queryStr = `search=${val}`;
      const { data } = await axios.get(
        `/${props.type.toLowerCase()}EmailSuggests?` + queryStr,
        {
          headers: { site_id },
        },
      );
      setEmailOptions(data.map((obj) => (obj.name ? obj.name : obj.email)));
    }
  };

  const InviteSchema = Yup.object({
    email_new: Yup.string()
      .required(`Enter ${props.type} Email`)
      .email("Enter Valid Email"),
    first_name: Yup.string()
      .required("Enter your First Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    last_name: Yup.string()
      .required("Enter your Last Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
  });

  const inviteSchemaOld = Yup.object({
    name: Yup.string().nullable(),
    email: Yup.string().email("Enter Valid Email").nullable(),
  });

  const resetNewAgentField = () => {
    formik.setFieldValue("email_new", "");
    formik.setFieldValue("first_name", "");
    formik.setFieldValue("last_name", "");
    formik.setFieldValue("phone", "");
  };

  const resetOldAgentField = () => {
    formikOld.setFieldValue("email", "");
    formikOld.setFieldValue("name", "");
  };

  const formikOld = useFormik({
    initialValues: { name: "", email: "", type: props.type },
    validationSchema: inviteSchemaOld,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (props.type === "Client") {
        props.sendInviteOld({
          email: values.email,
          type: values.type,
        });
      } else {
        const { data } = await axios.get(
          `/${props.type.toLowerCase()}EmailSuggests?search=${values.name.replace(
            /\s+/g,
            "",
          )}`,
          {
            headers: { site_id },
          },
        );
        let agentEmail = data[0].email;
        props.sendInviteOld({
          email: agentEmail,
          type: values.type,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email_new: "",
      first_name: "",
      last_name: "",
      phone: "",
      type: props.type,
    },
    validationSchema: InviteSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      props.sendInviteNew({
        email: values.email_new,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        phone: values.phone,
        type: values.type,
        redirectURL: `${window.location.origin}/signup`,
      });
    },
  });

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="text-center">
          <span
            style={{ color: "gray" }}
          >{`INVITE YOUR ${props.type.toUpperCase()}`}</span>
          <button
            type="button"
            onClick={() => {
              props.close();
            }}
            className="btn p-0 float-right"
          >
            <span className="material-icons">clear</span>
          </button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-left"
          >
            <span style={{ color: "black", fontSize: "13px" }}>
              {`Search for your ${props.type.toLowerCase()}. If they have a Doorsey account, their name
                will appear in the field below.`}
            </span>
          </DialogContentText>
          <form onSubmit={formikOld.handleSubmit}>
            {props.type === "Agent" ? (
              <>
                <Autocomplete
                  name="name"
                  getOptionLabel={(option) => option}
                  value={formikOld.values.name}
                  onChange={(event, val) =>
                    formikOld.setFieldValue("name", val)
                  }
                  options={emailOptions}
                  inputValue={inputVal}
                  onInputChange={suggestAgentEmail}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <CustomInput
                      {...params}
                      onKeyDown={(event) => {
                        if (
                          event &&
                          event.type === "keydown" &&
                          (event.key === "Tab" || event.key === "Enter") &&
                          event.target.value
                        ) {
                          formikOld.setFieldValue("name", event.target.value);
                        }
                      }}
                      label={`${props.type} Name`}
                      placeholder={`Begin typing ${props.type.toLowerCase()}'s name`}
                      size="small"
                      className={`hh-input ${
                        formikOld.errors.name &&
                        formikOld.touched.name &&
                        "is-invalid"
                      }`}
                    />
                  )}
                />
                {formikOld.errors.name && formikOld.touched.name ? (
                  <div className="invalid-feedback">
                    {formikOld.errors.name}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <CustomInput
                  label={`${props.type} Email`}
                  value={formikOld.values.email}
                  name="email"
                  size="small"
                  onChange={formikOld.handleChange}
                  onBlur={formikOld.handleBlur}
                  className={`hh-input ${
                    formikOld.errors.email &&
                    formikOld.touched.email &&
                    "is-invalid"
                  }`}
                  placeholder={`${props.type.toLowerCase()}'s email`}
                  required
                />
                {formikOld.errors.email && formikOld.touched.email ? (
                  <div className="invalid-feedback">
                    {formikOld.errors.email}
                  </div>
                ) : null}
              </>
            )}

            <div className="oldAgentActionButtons">
              <div className="no-decoration">
                <button
                  className="btn btn-border"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNewUser(true);
                  }}
                >
                  {props.type === "Client"
                    ? `My ${props.type.toLowerCase()} needs an account`
                    : `I can't find my ${props.type.toLowerCase()}`}
                </button>
              </div>
              <div className="no-decoration">
                <PrimaryButton
                  btnSize="small"
                  type="submit"
                  disabled={
                    props.type === "Client"
                      ? !formikOld.values.email
                      : !formikOld.values.name
                  }
                >
                  Send Invite
                </PrimaryButton>
              </div>
            </div>
          </form>
          <form onSubmit={formik.handleSubmit}>
            {isNewUser ? (
              <div className="newAgentSection">
                <DialogContentText
                  id="alert-dialog-slide-description"
                  className="text-left"
                >
                  <span style={{ color: "black", fontSize: "13px" }}>
                    {props.type === "Client"
                      ? `Invite your ${props.type.toLowerCase()} to sign up on Doorsey.`
                      : `Can't find your ${props.type.toLowerCase()}? Send them an invite.`}
                  </span>
                </DialogContentText>
                <div className="row mt-2">
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="First Name"
                      value={formik.values.first_name}
                      name="first_name"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.first_name &&
                        formik.touched.first_name &&
                        "is-invalid"
                      }`}
                      placeholder="John"
                      required
                    />
                    {formik.errors.first_name && formik.touched.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="Last Name"
                      value={formik.values.last_name}
                      name="last_name"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.last_name &&
                        formik.touched.last_name &&
                        "is-invalid"
                      }`}
                      placeholder="Doe"
                      required
                    />
                    {formik.errors.last_name && formik.touched.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label={`${props.type} Email`}
                      value={formik.values.email_new}
                      name="email_new"
                      size="small"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.email_new &&
                        formik.touched.email_new &&
                        "is-invalid"
                      }`}
                      placeholder="john@mail.com"
                      required
                    />
                    {formik.errors.email_new && formik.touched.email_new ? (
                      <div className="invalid-feedback">
                        {formik.errors.email_new}
                      </div>
                    ) : null}
                  </div>
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="Phone Number"
                      type="tel"
                      value={formik.values.phone}
                      name="phone"
                      size="small"
                      inputProps={{ maxlength: 14 }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "phone",
                          normalizePhoneNumberInput(
                            e.target.value,
                            formik.values.phone,
                          ),
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.phone &&
                        formik.touched.phone &&
                        "is-invalid"
                      }`}
                      placeholder="(555) 123 - 4567"
                    />
                    {formik.errors.phone && formik.touched.phone ? (
                      <div className="invalid-feedback">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="newAgentActionButton">
                  <div className="no-decoration">
                    <PrimaryButton btnSize="small" type="submit">
                      Send Invite
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
