import React, { useState, useEffect } from "react";
import useToggle from "../../../utils/useToggle";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import AuctionLostList from "../../../components/molecules/Dashboard/AuctionLostList";
import AuctionLostGrid from "../../../components/molecules/Dashboard/AuctionLostGrid";
import RecentlyAdded from "../../../components/molecules/Dashboard/RecentlyAdded";

const AuctionsLost = (props) => {
  const [list, toggleList] = useToggle(false);

  return (
    <DBLayout>
      <div className="clearfix">
        <h2 className="pp-head float-left">
          Auctions Lost <span>5 Properties</span>
        </h2>
        <div className="glv-wrap float-right">
          <a className={`glv-link ${list && "active"}`} onClick={toggleList}>
            <i className="fa fa-list"></i> List
          </a>
          <a className={`glv-link ${!list && "active"}`} onClick={toggleList}>
            <i className="fa fa-th-large"></i> Grid
          </a>
        </div>
      </div>
      <div className="ms-wrapper">
        {list ? <AuctionLostList /> : <AuctionLostGrid />}
        {/* <RecentlyAdded /> */}
      </div>
    </DBLayout>
  );
};

export default AuctionsLost;
