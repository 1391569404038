import React, { useRef, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "../../../utils/axiosconfig";
import { showSuccess, showWaring } from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import { checkUser } from "../../../API/userData";
import { toast } from "react-toastify";
import Loader from "../../../components/atoms/Loader";

const AutoLogin = (props) => {
  const history = useHistory();

  const location = useLocation();

  const { userLoggedIn, loading, userDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  const handleLogin = async ({ licencekey, email }) => {
    loading.set(true);
    let { data } = await axios.post(
      "/api/singleSignin",
      { licencekey, email },
      {
        headers: {
          site_id,
        },
      },
    );
    if (data.success === true) {
      showSuccess("Sucessfully Signin");
      localStorage.setItem("userToken", data.userDetails.token);
      userLoggedIn.set(true);
      loading.set(false);
      history.push(
        location &&
          location.state &&
          !!Object.keys(location.state).length &&
          location.state?.pathname?.split("/")[1] === "property"
          ? location.state.pathname
          : `/search?${process.env.REACT_APP_DEFAULT_LOCATION}`,
      );
      // }, 3000);
    } else {
      showWaring(data.reason || "Something went wrong");
      loading.set(false);
    }
  };

  const handleEmailData = async (formData) => {
    const body = {
      email: window.location.pathname.split("/")[3],
    };
    loading.set(true);
    if (site_id != (null || undefined)) {
      let data = await checkUser(body, site_id);
      if (data.success === true) {
        if (data.profileCompleted == false) {
          history.push({
            pathname: `/signup`,
            state: {
              action: "userUpdate",
              user_id: data.user_id,
              profileType: data.profiletype,
              values: {
                email: window.location.pathname.split("/")[3],
              },
            },
          });
        } else {
          handleLogin({
            licencekey: process.env.REACT_APP_LICENCE_KEY,
            email: window.location.pathname.split("/")[3],
          });
        }
        loading.set(false);
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            values: {
              email: window.location.pathname.split("/")[3],
            },
          },
        });
        toast.warning(data.message);
        loading.set(false);
      }
      return true;
    }
  };

  useEffect(() => {
    if (userLoggedIn) {
      localStorage.removeItem("userToken");
      userLoggedIn.set(false);
    }
    handleEmailData();
  }, []);

  return loading && <Loader />;
};

export default AutoLogin;
