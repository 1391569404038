import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import GridCard from "../../atoms/GridCard";

const WatchlistGrid = (props) => {
  return (
    <div className="row grid-row">
      {props.data.map((item, index) => {
        return (
          <div className="col-sm-6 col-md-4 grid-col" key={index}>
            <GridCard item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default WatchlistGrid;
