import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import ProfileForm from "./ProfileForm";
import ProfileFormMobile from "./ProfileFormMobile";
import ProfileCardNew from "./ProfileCardNew";
import useToggle from "../../../utils/useToggle";
import "react-toastify/dist/ReactToastify.css";
import { getUserData2 } from "../../../API/userData";
import GlobalContext from "../../../context/GlobalContext";
import "./profile.css";

const Profile = (props) => {
  const width = useMediaQuery("(min-width:768px)");
  const [edit, toggleEdit] = useToggle(false);
  const { userDetails } = useContext(GlobalContext);
  const [userAgent, setUserAgent] = useState({});
  const history = useHistory();
  const site_id = global?.storeDetails?.site_id;

  useEffect(() => {
    if (userDetails.get["user_name"]) {
      getUserAgent();
    }
  }, [userDetails.get["user_name"]]);

  const getUserAgent = async () => {
    let result = await getUserData2(userDetails.get["user_name"], site_id);
    if (result && result.User_Agent && result.User_Agent.length > 0) {
      setUserAgent(result.User_Agent[0]);
    }
  };

  return (
    <DBLayout>
      {edit ? (
        width ? (
          <ProfileForm edit={toggleEdit} />
        ) : (
          <ProfileFormMobile edit={toggleEdit} />
        )
      ) : (
        <ProfileCardNew
          edit={toggleEdit}
          userAgent={userAgent}
          history={history}
        />
      )}
    </DBLayout>
  );
};

export default Profile;
