import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PrimaryButton from "../PrimaryButton";
import CustomInput from "../Inputs/CustomInput";
import Checkbox from "@material-ui/core/Checkbox";
import * as Yup from "yup";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import "./dialog.css";
import { useFormik } from "formik";
import GlobalContext from "../../../context/GlobalContext";
import { set } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GreenCheckbox = withStyles({
  root: {
    color: "#DDE6EA",
    background: "#fff",
    borderRadius: 100,

    "& .MuiSvgIcon-root": {
      fontSize: "16px;",
      fontFamily: "Avenir-Book",
    },
    "&$checked": {
      color: "var(--primColor)",
      background: "transparent",

      "& .MuiSvgIcon-root": {
        fontSize: "18px",
        margin: "-1px 0 0 -2px",
      },
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function NoAgentDialogSlide(props) {
  const { userDetails } = useContext(GlobalContext);

  const [userInitialValues, setUserInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    contact_method: "",
  });

  useEffect(() => {
    if (Object.keys(userDetails.get).length > 0) {
      let mobileNumber = userDetails.get["phone"];
      if (mobileNumber.substring(0, 2) === "+1") {
        mobileNumber = mobileNumber.substring(3, mobileNumber.length);
      }
      setUserInitialValues({
        ...userInitialValues,
        first_name: userDetails.get["first_name"],
        last_name: userDetails.get["last_name"],
        phone: mobileNumber,
        email: userDetails.get["email"],
      });
    }
  }, [userDetails.get]);

  useEffect(() => {
    if (props.isResetNoAgent) {
      formik.resetForm();
      props.setIsResetNoAgent(false);
    }
  }, [props.isResetNoAgent]);

  const noAgentSchema = Yup.object({
    email: Yup.string().required(`Enter Email`).email("Enter Valid Email"),
    first_name: Yup.string()
      .required("Enter your First Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    last_name: Yup.string()
      .required("Enter your Last Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    phone: Yup.string()
      .required("Enter Mobile Number")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    contact_method: Yup.string().required("Select a preferred contact method"),
  });

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: noAgentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      props.handleNoAgentFormSubmit({
        ...values,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
      });
    },
  });

  const handleMyAgentClick = () => {
    props.redirectToMyAgentPage();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="text-center">
          <span style={{ color: "gray" }}>CONNECT WITH AN AGENT</span>
          <button
            type="button"
            onClick={() => {
              props.close();
            }}
            className="btn p-0 float-right"
          >
            <span className="material-icons">clear</span>
          </button>
        </DialogTitle>
        {props.isNoAgentSubmitted ? (
          <DialogContent>
            <DialogContentText>
              <span style={{ color: "black", fontSize: "15px" }}>
                <p>Thank you! Our team will be in touch soon.</p>
                <p>
                  Once you have found an agent, go to the{" "}
                  <span
                    className="navigate-to-myagent"
                    onClick={handleMyAgentClick}
                  >
                    My Agent
                  </span>{" "}
                  page in your dashboard and invite them to connect their
                  account with yours.
                </p>
                <p>
                  For any questions or concerns, please contact{" "}
                  <a
                    href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Doorsey%20Support%20Inquiry`}
                    style={{ textDecoration: "underline" }}
                  >
                    info@doorsey.com
                  </a>
                </p>
              </span>
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <div className="newAgentSection">
                <DialogContentText
                  id="alert-dialog-slide-description"
                  className="text-left"
                >
                  <span style={{ color: "black", fontSize: "13px" }}>
                    <p>
                      Buyers must be represented by an agent on Doorsey. Don't
                      have one yet? Our team will help you find one based on
                      your needs!
                    </p>
                    <div className="agentProfileImageRow">
                      <img src="images/agent1.png" />
                      <img src="images/agent2.png" />
                      <img src="images/agent3.png" />
                      <img src="images/agent4.png" />
                    </div>
                    <p>
                      Add or review your information below and out team will
                      reach out to help you connect with a qualified agent.
                    </p>
                  </span>
                </DialogContentText>
                <div className="row mt-2">
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="First Name"
                      value={formik.values.first_name}
                      name="first_name"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.first_name &&
                        formik.touched.first_name &&
                        "is-invalid"
                      }`}
                      placeholder="John"
                      required
                    />
                    {formik.errors.first_name && formik.touched.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="Last Name"
                      value={formik.values.last_name}
                      name="last_name"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.last_name &&
                        formik.touched.last_name &&
                        "is-invalid"
                      }`}
                      placeholder="Doe"
                      required
                    />
                    {formik.errors.last_name && formik.touched.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="Email"
                      value={formik.values.email}
                      name="email"
                      size="small"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.email &&
                        formik.touched.email &&
                        "is-invalid"
                      }`}
                      placeholder="john@mail.com"
                      required
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className={props.width ? "col-6" : "col-12"}>
                    <CustomInput
                      label="Phone Number"
                      type="tel"
                      value={formik.values.phone}
                      name="phone"
                      size="small"
                      inputProps={{ maxlength: 14 }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "phone",
                          normalizePhoneNumberInput(
                            e.target.value,
                            formik.values.phone,
                          ),
                        );
                      }}
                      onBlur={formik.handleBlur}
                      className={`hh-input ${
                        formik.errors.phone &&
                        formik.touched.phone &&
                        "is-invalid"
                      }`}
                      placeholder="(555) 123 - 4567"
                      required
                    />
                    {formik.errors.phone && formik.touched.phone ? (
                      <div className="invalid-feedback">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 ">
                    <div className="preferred-contact-method">
                      <span>Preferred Contact Method*</span>
                      <div className="form-group">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              value="Phone Call"
                              checked={
                                formik.values.contact_method === "Phone Call"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "contact_method",
                                  "Phone Call",
                                )
                              }
                            />
                          }
                          label="Phone call"
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              value="Text Message"
                              checked={
                                formik.values.contact_method === "Text Message"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "contact_method",
                                  "Text Message",
                                )
                              }
                            />
                          }
                          label="Text Message"
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              value="Email"
                              checked={formik.values.contact_method === "Email"}
                              onChange={(e) =>
                                formik.setFieldValue("contact_method", "Email")
                              }
                            />
                          }
                          label="Email"
                        />
                      </div>
                    </div>
                    {formik.errors.contact_method &&
                    formik.touched.contact_method ? (
                      <div className="invalid-feedback">
                        {formik.errors.contact_method}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="contactAgentActionButton">
                  <div className="no-decoration">
                    <PrimaryButton btnSize="small" type="submit">
                      Submit
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
