import React, { useState, useEffect, useContext, useRef } from "react";
import {
  onImageError,
  onPropertyImageError,
  setDecimalPoint,
} from "../../../utils";
import Calendar from "react-calendar";
import PropertyImageGallery from "./PropertyImageGallery";
import PaymentCalculator from "./PaymentCalculator";
import * as CronofyElements from "cronofy-elements";
import "./scheduleTour.css";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import CustomDialog from "../../atoms/CustomDialog";

import {
  getAvailability,
  getAvailSlots,
  getPrivateBlock,
  getOpenBookingBlock,
  getNewToken,
  getBidHistory,
  getOfferHistory,
} from "../../../API/propertyDetails";
import moment from "moment";
import { toast } from "react-toastify";
import PropertyTimePopup from "./ProprtyTimePopup";
import PropertyRight from "./PropertyRight";
import PropertyBids from "./PropertyBids";
import PropertyComments from "./PropertyComments";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import isAuthenticate from "../../../utils/auth";
import PropertyNotes from "./PropertyNotes";
import SearchMap from "../Search/SearchMap";
import GlobalContext from "../../../context/GlobalContext";
import { stubFalse } from "lodash";
import { Markup } from "interweave";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PropertyLeftTopMobile = ({
  data,
  matterport,
  showMatterport,
  lable,
  agentDetails,
  userDetails,
  openFile,
  note,
  propertyId,
  refreshNotes,
  width,
  comments,
  noComments,
  setComments,
  timer,
  nextBidAmount,
  setNextBidAmount,
  seyProductDetails,
  bidContainer,
  agentInfo,
  sellerTermsCon,
  myClientsList,
  myAgent,
  handleCommentEdit,
  handleCommentDelete,
  currentPropCity,
  toggleShare,
  setModelopen,
  modalopen,
  submittingBid,
  addFav,
  mobile,
  fav,
  clickSchedule,
  bookTour,
  commentsRef,
  clickAskQuestion,
  clickBidNumber,
  addTourCount,
  tourCount,
  highestBidder,
  heightChange,
  setHeightChange,
  setBeforePopup,
  setDocUri,
  setSubmittedPopup,
  submittedPopup,
  bidHistory,
}) => {
  const openNewWindow = (path) => window.open(path, "_blank");
  const [value, setValue] = React.useState(0);
  const [availableSlots, setAvailableSlots] = useState({});
  const [availOpenSlots, setAvailOpenSlots] = useState({});
  const [slotPopup, setSlotPopup] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [viewPropertyLocations, setViewPropertyLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [mobilePropertyPopupOpen, setMobilePropertyPopupOpen] = useState(false);
  const [mobileSelectedPropertyId, setMobileSelectedPropetyId] = useState("");
  const [drawSearch, setDrawSearch] = useState(false);
  const [agentEmail, setAgentEmail] = useState("");
  const [isBoundSet, setIsBoundSet] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isMaterportDescriptionExpanded, setIsMaterportDescriptionExpanded] =
    useState(false);
  const history = useHistory();
  const location = useLocation();
  const { userLoggedIn, headerLocation } = useContext(GlobalContext);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [dropDownShow, setDropDownShow] = useState(false);
  const [bidHistoryModal, setBidHistoryModal] = useState(false);
  const [offerHistoryModal, setOfferHistoryModal] = useState(false);
  const [offerHistoryData, setOfferHistoryData] = useState({});
  const imgRef = React.useRef(null);
  const compsDocs = useRef();
  const [clientHeight, setClientHeight] = useState("590px");
  const [currentHeight, setCurrentHeight] = useState("590px");
  const heightRef = useRef(null);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  /*Slot open pop up*/
  const slotOpenPopup = () => {
    setSlotPopup(true);
  };

  /*Slot close pop up*/
  const slotClosePopup = () => {
    setSlotPopup(false);
  };

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: data?.id + "" }, site_id);
    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  const handleOfferHistory = () => {
    setOfferHistoryModal(true);
    fetchOfferHistory();
  };

  const handleMapCenterChange = (newCenter, newBound) => {
    let url = new URLSearchParams(location.search);
    url.set("north", newBound.north);
    url.set("south", newBound.south);
    url.set("east", newBound.east);
    url.set("west", newBound.west);
    url.set("lat", newCenter.lat);
    url.set("long", newCenter.lng);
    url.set("cities", "");
    url.set("state", "");
    history.replace({
      pathname: location.pathname,
      search: "?" + url.toString(),
    });
    headerLocation.set({
      id: "",
      cities: "",
      state: "",
      lat: "",
      lng: "",
    });
    // const isWithinBound = (point, bound) => {
    //   var inLong = point.lng > bound.west && point.lng < bound.east;

    //   var inLat = point.lat > bound.south && point.lat < bound.north;
    //   return inLat && inLong;
  };

  /*Common Private Calendar API call*/
  const privateCalendarCall = () => {
    let availCronSlotOpen = {
      id: data.id,
      type: 0,
    };

    getCronAvailForProd(availCronSlotOpen)
      .then((res) => {
        if (res && res.status && res.data && typeof res.data !== "undefined") {
          /*Today's Date*/
          let todaysDate = new Date();
          let today = moment(todaysDate);

          /*Seventh day's Date*/
          let seventhDaysDate = new Date();
          seventhDaysDate.setDate(todaysDate.getDate() + 6);
          let seventhDate = moment(seventhDaysDate);
          let arrayOfDates = getDatesBetweenDates(today, seventhDate).map(
            (ele) => moment(ele).format("MM/DD/YYYY"),
          );

          let objCron = {};
          for (let i = 0; i < arrayOfDates.length; i++) {
            objCron[arrayOfDates[i]] = [];
          }

          for (let i = 0; i < res.data.available_periods.length; i++) {
            if (
              Object.keys(objCron).includes(
                moment(res.data.available_periods[i].start).format(
                  "MM/DD/YYYY",
                ),
              )
            ) {
              objCron[
                moment(res.data.available_periods[i].start).format("MM/DD/YYYY")
              ].push(res.data.available_periods[i]);
            }
          }
          setAvailableSlots(objCron);
        }
      })
      .catch((err) => {
        console.log("err====>", err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(Number(newValue));
    if (Number(newValue) === 0) {
      privateCalendarCall();
    } else if (Number(newValue) === 1) {
      let availCronSlotOpen = {
        id: data.id,
        type: 1,
      };

      getCronAvailForProd(availCronSlotOpen)
        .then((res) => {
          if (
            res &&
            res.status &&
            res.slots &&
            typeof res.slots !== "undefined"
          ) {
            /*Today's Date*/
            let todaysDate = new Date();
            let today = moment(todaysDate);

            /*Seventh day's Date*/
            let seventhDaysDate = new Date();
            seventhDaysDate.setDate(todaysDate.getDate() + 6);
            let seventhDate = moment(seventhDaysDate);
            let arrayOfDates = getDatesBetweenDates(today, seventhDate).map(
              (ele) => moment(ele).format("MM/DD/YYYY"),
            );

            let _objCron = {};
            for (let i = 0; i < arrayOfDates.length; i++) {
              _objCron[arrayOfDates[i]] = [];
            }

            for (let i = 0; i < res.slots.length; i++) {
              if (
                Object.keys(_objCron).includes(
                  moment(res.slots[i].start).format("MM/DD/YYYY"),
                )
              ) {
                _objCron[moment(res.slots[i].start).format("MM/DD/YYYY")].push(
                  res.slots[i],
                );
              }
            }

            setAvailOpenSlots(_objCron);
          }
        })
        .catch((err) => {
          console.log("err====>", err);
        });
    }
  };

  const blockedSlot = () => {
    if (Number(value) === 0) {
      onIndividualPrivateSlot(selectedSlot);
    } else if (Number(value) === 1) {
      onIndividualOpenSlot(selectedSlot);
    }
  };

  const selectedSlots = (data) => {
    if (isAuthenticate() !== undefined) {
      setSelectedSlot(data);
      setSlotPopup(true);
    } else {
      history.push(`/login`);
    }
  };

  const agentEmailAddressChng = (value) => {
    setAgentEmail(value);
  };

  /*API call for the Cronify Avail Slot*/
  const getCronAvailForProd = async (cronAvail) => {
    try {
      let data = await getAvailSlots(cronAvail, site_id);
      return data;
    } catch (error) {
      throw error;
    }
  };

  /* Array for seven days*/
  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };

  /*Saving the open house slot function */
  const onIndividualOpenSlot = async (invSlot) => {
    let params = {
      slot: invSlot,
    };
    let propertyDtls = {
      id: data.id,
      agentEmail: agentEmail && agentEmail.length > 0 ? agentEmail : data.email,
    };
    let data__ = await getNewToken(site_id);

    if (data__.status) {
      let data_ = await getOpenBookingBlock(propertyDtls, params, site_id);
      if (data_.status) {
        setSlotPopup(false);
        toast.success("Open houses slot has been booked successfully");
      }
    }
  };

  /*Saving the Private slot function */
  const onIndividualPrivateSlot = async (invSlot) => {
    let params = {
      slot: encodeURI(JSON.stringify(invSlot)),
    };
    let propertyDtls = {
      id: data.id,
      agentEmail: agentEmail && agentEmail.length > 0 ? agentEmail : data.email,
    };
    try {
      let data__ = await getNewToken(site_id);
      if (data__.status) {
        let data_ = await getPrivateBlock(propertyDtls, params, site_id);
        if (data_.status) {
          setSlotPopup(false);
          toast.success("Private tour slot has been booked successfully");
          privateCalendarCall();
        }
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  /*Initial page load API call for the private calendar useEffect*/
  // useEffect(() => {
  //   if (data && typeof data !== "undefined") {
  //     privateCalendarCall();
  //   }
  // }, [data]);
  useEffect(() => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      typeof data !== "undefined" &&
      window.google
    ) {
      privateCalendarCall();
      let singleProperty = {
        id: data.id,
        city: data.loc_city,
        state: data.state,
        latlng: new window.google.maps.LatLng(
          Number(data.lat),
          Number(data.lng),
        ),
      };
      setViewPropertyLocations([singleProperty]);
    }
    if (imgRef.current?.complete) {
      setIsImageLoaded(true);
    }
  }, [data, window.google]);
  useEffect(() => {
    if (location && location.search) {
      let url = new URLSearchParams(location.search);
      let pathnames = location.pathname.split("/");
      let urlValue = {};
      urlValue["lat"] = url.get("lat");
      urlValue["lng"] = url.get("long");
      urlValue["cities"] = url.get("cities");
      urlValue["state"] = url.get("state");
      if (
        url.get("north") &&
        url.get("south") &&
        url.get("east") &&
        url.get("west")
      ) {
        setIsBoundSet(true);
      } else {
        setIsBoundSet(false);
      }
      setSelectedLocation(urlValue);
    }
  }, [location]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable: false,
    swipe: true,
    arrows: false,
    beforeChange: (oldIn, curIn) => {
      if (!!curIn) {
        setIsMaterportDescriptionExpanded(false);
      } else {
        setIsDescriptionExpanded(false);
      }
    },
  };

  const mobileDropDown = () => {
    setDropDownShow(!dropDownShow);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dropDownShow &&
        compsDocs.current &&
        !compsDocs.current.contains(e.target)
      ) {
        setDropDownShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDownShow]);

  useEffect(() => {
    setClientHeight(heightRef?.current?.offsetHeight);
    console.log("clientHeight", clientHeight);
  }, [isDescriptionExpanded, isMaterportDescriptionExpanded]);

  useEffect(() => {
    console.log("clientHeight value outside", currentHeight);
    setHeightChange(clientHeight);
  }, [clientHeight]);

  return (
    <>
      <div className="pv-left-top">
        <span className="slidrWrapper" ref={heightRef}>
          <Slider {...settings}>
            <div
              className="item mb-5"
              onClick={() => {
                setIsDescriptionExpanded(!isDescriptionExpanded);
              }}
            >
              <div className="pv-banner-video clearfix">
                {isImageLoaded ? null : (
                  // <img src="/images/doorseypropertyloading.png" />
                  <div className="circular-loader">
                    <CircularProgress className="loading" />
                  </div>
                )}
                {Object.keys(data).length > 0 ? (
                  <img
                    ref={imgRef}
                    src={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${data.avatar}`}
                    alt="Take Virtual Tour"
                    className="pvb-img"
                    onError={(e) => onPropertyImageError(e)}
                    onLoad={() => setIsImageLoaded(true)}
                    style={isImageLoaded ? {} : { display: "none" }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="mob-slider-height">
                <div
                  className={` photoSlider ${
                    data.description?.length > 300
                      ? isDescriptionExpanded
                        ? ""
                        : "fixedHieght"
                      : ""
                  }`}
                >
                  <p className="propertyDesc">
                    <Markup content={data.description} />
                  </p>
                </div>
                {data.description?.length > 300 ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="fixedHeightToggle"
                      onClick={() => {
                        setIsDescriptionExpanded(!isDescriptionExpanded);
                      }}
                    >
                      {isDescriptionExpanded ? "view less" : "view more"}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {matterport &&
              matterport.map((vid, index) => (
                <div
                  className="item"
                  onClick={() => {
                    setIsMaterportDescriptionExpanded(
                      !isMaterportDescriptionExpanded,
                    );
                  }}
                >
                  <div className="room-img-wrap">
                    {vid.show_tour ? (
                      <a
                        className="room-virtual-tour"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          window.open(vid.video_url, "_blank");
                        }}
                      >
                        <span className="virtualTourButton">
                          <img
                            className="virtualTourIcon"
                            src="/images/door.png"
                          />
                          Step Inside
                        </span>
                      </a>
                    ) : (
                      ""
                    )}

                    <img
                      src={
                        vid.imgSrc[0]
                          ? `${process.env.REACT_APP_IMAGE_URL}uploads/product/${vid.imgSrc[0]}`
                          : ""
                      }
                      alt={vid.imgSrc[0]}
                      onError={onPropertyImageError}
                    />
                    <label className="room-label">{vid.asset_label}</label>
                  </div>
                  <div className="mob-slider-height">
                    <div
                      className={` photoSlider ${
                        vid.discribtion?.length > 300
                          ? isMaterportDescriptionExpanded
                            ? ""
                            : "fixedHieght"
                          : ""
                      }`}
                    >
                      <p className="propertyDesc">
                        <Markup content={vid.discribtion} />
                      </p>
                    </div>
                    {vid.discribtion?.length > 300 ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="fixedHeightToggle"
                          onClick={() => {
                            setIsMaterportDescriptionExpanded(
                              !isMaterportDescriptionExpanded,
                            );
                          }}
                        >
                          {isMaterportDescriptionExpanded
                            ? "view less"
                            : "view more"}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
          </Slider>
          <span className="swipeInstructions">{"Swipe >"}</span>
        </span>
        <>
          {data &&
          data.listing_video &&
          data.listing_video.length &&
          Object.values(data.listing_video[0]).length ? (
            <div className="item">
              <div className="room-img-wrap room-img-wrap-mobile">
                <iframe
                  allowFullScreen
                  src={`${Object.values(data.listing_video[0])[0]}`}
                  className="w-100"
                  height="500"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {showMatterport && (
            <>
              <div className="item">
                <div className="room-img-wrap room-img-wrap-mobile">
                  <a
                    className="room-virtual-tour"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.open(data.video_url, "_blank");
                    }}
                  >
                    <span className="virtualTourButton">
                      <img className="virtualTourIcon" src="/images/door.png" />
                      Step Inside
                    </span>
                  </a>
                  <iframe
                    allowFullScreen
                    className="w-100"
                    height="500"
                    src={data.video_url}
                  ></iframe>
                </div>
              </div>
            </>
          )}
          <div className="item">
            <div className="img-gallery-mobile">
              {lable && Object.keys(lable).length && (
                <PropertyImageGallery lable={lable} width={width} />
              )}
            </div>
          </div>
        </>
        {data && (
          <span className="lftTopInsde">
            <PropertyRight
              data={data}
              agentDetails={agentDetails}
              userDetails={userDetails}
              agentInfo={agentInfo}
              note={note}
              openFile={openFile}
              propertyId={propertyId}
              refreshNotes={refreshNotes}
              sellerTermsCon={sellerTermsCon}
              collapsed={false}
              propertyCollapse={true}
              hideNotes={true}
              addFav={addFav}
              mobile={mobile}
              fav={fav}
              toggleShare={toggleShare}
              noComments={noComments}
              clickAskQuestion={clickAskQuestion}
              clickBidNumber={clickBidNumber}
              tourCount={tourCount}
              popupState={true}
              setBeforePopup={setBeforePopup}
            />
          </span>
        )}
        <div className="pvs-xs-wrapper">
          {/* <div ref={bookTour}>
            {data.book_tour ? (
              <a href={data.book_tour} target="_blank">
                <img src="/images/calendar.png" alt="" /> Schedule Tour
              </a>
            ) : (
              "Contact agent for tour."
            )}
          </div>
          <div onClick={toggleShare}>
            <a>
              <img src="/images/share.png" alt="" /> Share
            </a>
          </div> */}
          {data && data.resource ? (
            <>
              <div
                className="list-inline-item pv-text dropdown nav-item w-100"
                ref={compsDocs}
              >
                <a className="moreLinkDrop d-inline-block">Comp & Documents</a>
                <span
                  style={{
                    textTransform: "capitalize",
                    float: "right",
                    color: "var(--primColor)",
                    fontWeight: "800",
                  }}
                  onClick={mobileDropDown}
                >
                  view
                </span>
                {dropDownShow && (
                  <div
                    className={`mltLnkDrop ${
                      dropDownShow ? "dropdown-menu" : ""
                    }`}
                  >
                    <div className="cwhite-box dbw-box dropdwn-menu-prop">
                      <ul style={{ padding: 0 }}>
                        {isAuthenticate() ? (
                          <>
                            {Object.keys(data.resource).map((ele) => {
                              if (Object.keys(ele).length > 0)
                                return (
                                  <li
                                    onClick={() => {
                                      window.open(
                                        encodeURI(data.resource[ele]),
                                      );
                                      mobileDropDown();
                                    }}
                                  >
                                    {ele}
                                  </li>
                                );
                            })}
                          </>
                        ) : (
                          <>
                            {Object.keys(data.resource).map((ele) => {
                              if (Object.keys(ele).length > 0)
                                return (
                                  <li
                                    onClick={() => {
                                      setDocUri(data.resource[ele]);
                                      setBeforePopup("view_docs");
                                      mobileDropDown();
                                    }}
                                  >
                                    {ele}
                                  </li>
                                );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
        {data && (
          <PropertyBids
            nextBidAmount={nextBidAmount}
            data={data}
            timer={timer}
            setNextBidAmount={setNextBidAmount}
            bidContainer={bidContainer}
            seyProductDetails={seyProductDetails}
            width={width}
            myClientsList={myClientsList}
            myAgent={myAgent}
            isMobile={true}
            setModelopen={setModelopen}
            modalopen={modalopen}
            submittingBid={submittingBid}
            highestBidder={highestBidder}
          />
        )}
        <PropertyComments
          comments={comments}
          noComments={noComments}
          setComments={setComments}
          width={width}
          propertyDetails={data}
          agentDetails={agentDetails}
          timer={timer}
          handleCommentEdit={handleCommentEdit}
          handleCommentDelete={handleCommentDelete}
          commentsRef={commentsRef}
          setBeforePopup={setBeforePopup}
          setSubmittedPopup={setSubmittedPopup}
          submittedPopup={submittedPopup}
        />

        <div className="about-sarea">
          <div className="asa-head">Location</div>
          <div className="pv-loc-map">
            {currentPropCity && Object.keys(currentPropCity).length !== 0 && (
              <SearchMap
                location={viewPropertyLocations}
                isDraw={drawSearch}
                userLocation={currentPropCity}
                handleMapCenterChange={handleMapCenterChange}
                mobileSelectedPropertyId={mobileSelectedPropertyId}
                setMobileSelectedPropetyId={setMobileSelectedPropetyId}
                setMobilePropertyPopupOpen={setMobilePropertyPopupOpen}
                mobilePropertyPopupOpen={mobilePropertyPopupOpen}
                width={width}
              />
            )}
          </div>
        </div>
        <div className="about-sarea">
          <div className="asa-head">About the Neighborhood</div>
          <img
            src={
              data.ownerImage
                ? `${process.env.REACT_APP_IMAGE_URL}uploads/product/${data.ownerImage}`
                : ""
            }
            alt=""
            className="from-owners-img"
          />
          <p dangerouslySetInnerHTML={{ __html: data.ownerDescription }}></p>
        </div>
        <PaymentCalculator data={data} />

        <PropertyNotes
          note={note}
          propertyId={propertyId}
          refreshNotes={refreshNotes}
          collapsed={true}
        />
      </div>

      <div className="bidHistoryCnt text-center">
        {Boolean(data?.auction) ? (
          <Button variant="outlined" onClick={() => setBidHistoryModal(true)}>
            <span className="material-icons">history</span>
            View Bid History
          </Button>
        ) : null}
        {Boolean(data?.offer) ? (
          <Button variant="outlined" onClick={handleOfferHistory}>
            <span className="material-icons-outlined">local_offer</span>
            View Offer History
          </Button>
        ) : null}
      </div>
      <CustomDialog
        title="Bid History"
        open={bidHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setBidHistoryModal(false)}
      >
        <div class="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Date</th>
                <th>Proposed amount</th>
                <th>Downpayment</th>
                <th>Financing</th>
              </tr>
            </thead>
            <tbody>
              {bidHistory?.length ? (
                <>
                  {bidHistory?.map((data, index) => (
                    <tr key={index}>
                      <td>{data.username}</td>
                      <td>
                        {moment(data.created_at).format("MMM DD")} at{" "}
                        {moment(data.created_at).format("hh:mm A")}
                      </td>
                      <td>
                        {data.proposed_amount
                          ? `$${setDecimalPoint(
                              parseInt(data.proposed_amount, 10),
                            )}`
                          : "-"}
                      </td>
                      <td>
                        {data.down_payment
                          ? `${setDecimalPoint(
                              parseInt(data.down_payment, 10),
                            )}%`
                          : "-"}
                      </td>
                      <td>
                        {data.fin_type === "no_fin_type"
                          ? "No Financing Type"
                          : data.fin_type}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CustomDialog>

      <CustomDialog
        title="Offer History"
        open={offerHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setOfferHistoryModal(false)}
      >
        <div class="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Date</th>
                <th>Proposed amount</th>
                <th>Downpayment</th>
                <th>Financing</th>
              </tr>
            </thead>
            <tbody>
              {offerHistoryData?.length ? (
                <>
                  {offerHistoryData?.map((data, index) => (
                    <tr key={index}>
                      <td>{data.username}</td>
                      <td>
                        {moment(data.created_at).format("MMM DD")} at{" "}
                        {moment(data.created_at).format("hh:mm A")}
                      </td>
                      <td>
                        {data.proposed_amount
                          ? `$${setDecimalPoint(
                              parseInt(data.proposed_amount, 10),
                            )}`
                          : "-"}
                      </td>
                      <td>
                        {data.down_payment
                          ? `${setDecimalPoint(
                              parseInt(data.down_payment, 10),
                            )}%`
                          : "-"}
                      </td>
                      <td>
                        {data.fin_type === "no_fin_type"
                          ? "No Financing Type"
                          : data.fin_type}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CustomDialog>

      {slotPopup ? (
        <PropertyTimePopup
          open={slotPopup}
          onClose={slotClosePopup}
          selectSlot={selectedSlot}
          data={data}
          setEmailAddressChng={agentEmailAddressChng}
          agentEmailAdd={agentEmail}
          onClickConfirmTour={blockedSlot}
        />
      ) : null}
      <div className="fixed-bottom-actionButtons">
        <div className="d-flex justify-content-around">
          {data.enable_inspection === "true" ? (
            <>
              {isAuthenticate() ? (
                <>
                  <div style={{ flexGrow: "1", textAlign: "center" }}>
                    <a
                      className="btn btn-invert sticky-btn"
                      onClick={(e) =>
                        openFile(`uploads/cerify_product/${data.certify_name}`)
                      }
                    >
                      Home Inspection
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ flexGrow: "1", textAlign: "center" }}>
                    <a
                      className="btn btn-invert sticky-btn"
                      onClick={() => setBeforePopup("home_inspection")}
                    >
                      Home Inspection
                    </a>
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}

          {data.book_tour ? (
            <>
              {isAuthenticate() ? (
                <>
                  <div style={{ flexGrow: "1", textAlign: "center" }}>
                    <a
                      className="btn btn-invert sticky-btn"
                      href={data.book_tour}
                      target="_blank"
                      onClick={() => addTourCount(data.id)}
                    >
                      Schedule Tour
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ flexGrow: "1", textAlign: "center" }}>
                    <a
                      className="btn btn-invert sticky-btn"
                      // href={data.book_tour}
                      target="_blank"
                      onClick={() => setBeforePopup("schedule_tour")}
                    >
                      Schedule Tour
                    </a>
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}

          <div style={{ flexGrow: "1", textAlign: "center" }}>
            <a className="btn btn-invert sticky-btn" onClick={clickAskQuestion}>
              Ask a Question
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyLeftTopMobile;
