import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import useToggle from "../../../utils/useToggle";
import { addNotes } from "../../../API/notes";
import Dialog from "../../atoms/Dialog";
import GlobalContext from "../../../context/GlobalContext";
import moment from "moment";
import { sanitizer } from "../../../utils";

function PropertyNotes(props) {
  const { userLoggedIn, storeDetails } = useContext(GlobalContext);
  const [modalopen, setModalOpen] = useState(false);
  const [notes, toggleNotes] = useToggle(true);
  const location = useLocation();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const addNote = async (text, setText, topic) => {
    let data = await addNotes(
      {
        project_id: props.propertyId,
        text: text,
        topic: topic,
      },
      site_id,
    );
    if (data.status === true) {
      props.refreshNotes();
      setText("");
      setModalOpen((prevModel) => !prevModel);
    }
  };

  useEffect(() => {
    if (props.collapsed) {
      toggleNotes(false);
    }
  }, []);

  return (
    <div>
      <div className="pv-white-box">
        <div
          className={`key-info-head ${notes ? "clearfix" : "pv-left clearfix"}`}
        >
          My Listing Notes{" "}
          {!notes && (
            <a className="pv-collapse" onClick={toggleNotes}>
              <i className="fa fa-angle-down"></i> Expand
            </a>
          )}
        </div>
        {notes && (
          <>
            {userLoggedIn.get === false ? (
              <div className="text-center">
                <Link
                  to={{
                    pathname: `/login`,
                    state: { pathname: location.pathname },
                  }}
                  className="van-link"
                >
                  Login To Add Notes
                </Link>
              </div>
            ) : props.note.length !== 0 ? (
              <div className="mb-4">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Add Notes
                </button>
                {props.note.map((data) => (
                  <div className="notes-box">
                    <div className="notes-date">
                      {moment(data.created_at).format("M/D/YY (LT)")}
                    </div>
                    <div className="notes-con">{data.text}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mb-4">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Add Notes
                </button>
                <div className="text-center mt-4">No Notes Found</div>
              </div>
            )}
            {/* {userLoggedIn.get === true && note.length !== 0 && (
              <div className="text-center">
                <Link to="/notes" className="van-link">
                  View All Notes
                </Link>
              </div>
            )} */}
            <a className="pv-collapse" onClick={toggleNotes}>
              <i className="fa fa-angle-up"></i> Collapse
            </a>
          </>
        )}
      </div>

      <Dialog
        mynotes
        open={modalopen}
        onClose={(text, setText, topic) =>
          text && sanitizer(text) && addNote(text, setText, topic)
        }
        close={(e) => setModalOpen((prev) => !prev)}
      />
    </div>
  );
}

export default PropertyNotes;
