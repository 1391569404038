import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { Link } from "react-router-dom";

const ActiveAuctions = (props) => {
  const auctions = [
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      timer: "05H : 24M : 32S",
      posted_on: "21/01/2021",
      higher_bidder: "Amanda Valdez",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      timer: "05H : 24M : 32S",
      posted_on: "21/01/2021",
      higher_bidder: "Amanda Valdez",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      timer: "05H : 24M : 32S",
      posted_on: "21/01/2021",
      higher_bidder: "Amanda Valdez",
    },
  ];

  return (
    <DBLayout>
      <div className="table-responsive">
        <table className="table table-inverse mt-34">
          <thead>
            <th>Auction Id</th>
            <th>Auction Name</th>
            <th>Bid Due In</th>
            <th>Posted On</th>
            <th>Higher Bidder</th>
            <th>Action</th>
          </thead>
          <tbody>
            {auctions.map((item, index) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.auction_name}</td>
                  <td>{item.timer}</td>
                  <td>{item.posted_on}</td>
                  <td>{item.higher_bidder}</td>
                  <td>
                    <Link to="">View</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DBLayout>
  );
};

export default ActiveAuctions;
