import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import CustomInput from "./CustomInput";
import axios from "axios";
import { useEffect } from "react";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(props.value);
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const site_id = localStorage.getItem("site_id");

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?v=3&sensor=false&libraries=places&key=AIzaSyBYgBSUFINX2Oln12E3V2-FX-0MxpL_67Y",
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );
  React.useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.AutocompleteService
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (event, newValue) => {
        if (newValue && newValue.place_id) {
          let { data } = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?place_id=${newValue.place_id}&key=AIzaSyBYgBSUFINX2Oln12E3V2-FX-0MxpL_67Y`,
            {
              headers: { site_id },
            },
          );
          if (
            data &&
            data.status === "OK" &&
            Array.isArray(data.results) &&
            data.results.length !== 0
          ) {
            let { formatted_address, geometry, address_components } =
              data.results[0];

            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            let city = "";
            let state = "";
            let country = "";
            let zip = "";
            if (Array.isArray(address_components)) {
              address_components.forEach((acData) => {
                if (acData.types.indexOf("locality") !== -1) {
                  city = acData.long_name;
                }
                if (
                  acData.types.indexOf("administrative_area_level_1") !== -1
                ) {
                  state = acData.long_name;
                }
                if (acData.types.indexOf("country") !== -1) {
                  country = acData.long_name;
                }
                if (acData.types.indexOf("postal_code") !== -1) {
                  zip = acData.short_name;
                }
              });
            }
            props.onChange({
              address: formatted_address,
              lat: geometry.location.lat,
              lng: geometry.location.lng,
              city: city,
              state: state,
              country: country,
              zip: zip,
            });
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <CustomInput
          className="p-0 mapValueInput"
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
