import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CCard = (props) => {
  return (
    <div className="col-sm-6 col-lg-4 paymentsSavedCard form-group">
      <div className="ccard-wrap">
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6 pt-1 text-right">
            <img src="/images/mastercard.png" alt="bank" />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <img src="/images/sim.png" alt="bank" />
          </div>
        </div>
        <div className="test">
          <div className="clearfix mt-2">
            <div className="float-left">
              <div>Sara Riley</div>
            </div>
            <div className="float-right">
              <div>Expires: 08/25 </div>
            </div>
          </div>
          <div className="card-num">
            <span>3011</span>
            <span>-</span>
            <span>5145</span>
            <span>-</span>
            <span>3566</span>
            <span>-</span>
            <span>3012</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCard;
