import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { notConstructive } from "../../../API/propertyDetails";
import { toast } from "react-toastify";

const Comment = ({
  getAllRply,
  data,
  setModalOpen,
  propertyDetails,
  agentDetails,
  handleCommentEdit,
  handleCommentDelete,
  width,
  from,
  firstId,
}) => {
  const { userLoggedIn, userDetails } = useContext(GlobalContext);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   if (data) {
  //     console.log("================== data =========================", data);
  //   }
  // }, [data]);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const flagNotAsConstructive = async (commentId) => {
    if (userLoggedIn.get === false) {
      history.push({ pathname: `/login`, state: location });
      return;
    }
    let data = await notConstructive(
      {
        property_id: id,
        comment_id: commentId,
      },
      site_id,
    );
    if (data.status === true) {
      toast.success("Comment added not as constructive");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="card-body inrCard">
      <div className="clearfix">
        <div className="float-left">
          <h5
            className="dbc-text"
            style={{ cursor: data.username && "pointer" }}
            onClick={() =>
              data.username && history.push(`/view-account/${data.username}`)
            }
          >
            <div className="comment-user-name">
              {data.username || "Anonymous"}
            </div>
            <span>
              {" "}
              {data.role == 2 ? (
                data.companyName && width ? (
                  data.companyName
                ) : (
                  ""
                )
              ) : data.role == 3 ? (
                data.companyName ? (
                  <span className="d-flex align-items-center ml-0">
                    <img className="mr-1 pb-1" src="/images/bbadge.svg" />
                    <span className="ml-0" style={{ fontWeight: "600" }}>
                      {data.companyName}
                    </span>
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </span>
          </h5>
        </div>
        <div className="float-right">
          {from === "practice"
            ? ""
            : data.user_id === propertyDetails.user_id && (
                <img
                  src="/images/sbadge.png"
                  style={{
                    width: "46px",
                    paddingBottom: ".3rem",
                    marginRight: "10px",
                  }}
                />
              )}
          {from === "practice"
            ? ""
            : data.user_id === agentDetails.agentUserId && (
                //   <img
                //   src="/images/abadge.png"
                //   style={{
                //     width: "46px",
                //     paddingBottom: ".3rem",
                //     marginRight: "10px",
                //   }}
                // />
                <span
                  style={{
                    color: "var(--primColor)",
                    fontSize: "12px",
                    display: "inline-block",
                    paddingRight: "10px",
                  }}
                >
                  <em>Listing Agent</em>{" "}
                </span>
              )}
          {from === "practice" ? (
            <span className="commentsCardDate">
              {moment(data.created_at).format("MMM DD")} at{" "}
              {moment(data.created_at).format("hh:mm A")}
            </span>
          ) : (
            // <span className="commentsCardDate">
            //   {moment
            //     .utc(moment(data.created_at).subtract({ hours: "02" }))
            //     .local()
            //     .format("MMM DD")}{" "}
            //   at{" "}
            //   {moment
            //     .utc(moment(data.created_at).subtract({ hours: "02" }))
            //     .local()
            //     .format("hh:mm A")}
            // </span>
            <span className="commentsCardDate">
              {moment(data.created_at).local().format("MMM DD")} at{" "}
              {moment(data.created_at).local().format("hh:mm A")}
            </span>
          )}
          {userLoggedIn.get && (
            <a
              className="reply-link"
              onClick={() =>
                userLoggedIn.get &&
                setModalOpen(() => {
                  let prv = data;
                  prv["isLastComment"] = 1;
                  return prv;
                })
              }
              style={{ cursor: "pointer " }}
            >
              <i className="fa fa-reply"></i> {userLoggedIn.get ? "Reply" : ""}
            </a>
          )}
        </div>
      </div>
      <p className="pc-descp">{data.comment}</p>
      <div className="clearfix">
        <div className="float-left">
          <a
            className="vr-link"
            onClick={() => {
              if (data.rplyCount !== 0) {
                if (from === "practice") {
                  setShow((prv) => !prv);
                } else {
                  if (data.rply === undefined) {
                    getAllRply(data.id, data.comment_id);
                    setShow(true);
                  } else {
                    setShow((prv) => !prv);
                  }
                }
              }
            }}
          >
            {show ? "Hide" : "Show"} replies ({data.rplyCount})
          </a>
        </div>

        {userLoggedIn.get &&
        userDetails.get["id"] !== parseInt(data.user_id) ? (
          <div className="float-right text-right red-links">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => flagNotAsConstructive(data.id)}
              className="red-text"
            >
              Flag as not constructive
            </a>
          </div>
        ) : (
          userDetails.get["id"] === parseInt(data.user_id) && (
            <div
              className={
                from === "practice"
                  ? "float-right text-right ed-links "
                  : "float-right text-right ed-links cnstrtFlg"
              }
            >
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  from === "practice"
                    ? handleCommentEdit({
                        firstId: firstId,
                        secondId: parseInt(data.id),
                        thirdId: "",
                        text: data.comment,
                      })
                    : handleCommentEdit({
                        id: parseInt(data.id),
                        text: data.comment,
                      })
                }
              >
                <img src="/images/edit.png" /> Edit
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  from === "practice"
                    ? handleCommentDelete({
                        firstId: firstId,
                        secondId: parseInt(data.id),
                        thirdId: "",
                      })
                    : handleCommentDelete(parseInt(data.id))
                }
              >
                <img src="/images/delete.png" /> Delete
              </a>
            </div>
          )
        )}
      </div>
      {data.rply &&
        show &&
        data.rply.map((rply) => (
          <>
            {rply.status === "true" && rply.isDeleted === "No" ? (
              <div className="card-body inrCard pr-0">
                <div className="clearfix">
                  <div className="float-left">
                    <h5
                      className="dbc-text"
                      style={{ cursor: rply.username && "pointer" }}
                      onClick={() =>
                        rply.username &&
                        history.push(`/view-account/${rply.username}`)
                      }
                    >
                      <div className="comment-user-name">
                        {rply.username || "Anonymous"}
                      </div>
                      <span>
                        {" "}
                        {rply.role == 2 ? (
                          rply.companyName && width ? (
                            rply.companyName
                          ) : (
                            ""
                          )
                        ) : rply.role == 3 ? (
                          rply.companyName ? (
                            <span className="d-flex align-items-center ml-0">
                              <img
                                className="mr-1 pb-1"
                                src="/images/bbadge.svg"
                              />
                              <span
                                className="ml-0"
                                style={{ fontWeight: "600" }}
                              >
                                {rply.companyName}
                              </span>
                            </span>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </h5>
                  </div>
                  <div className="float-right">
                    {from === "practice"
                      ? ""
                      : rply.user_id === propertyDetails.user_id && (
                          <img
                            src="/images/sbadge.png"
                            style={{
                              width: "46px",
                              paddingBottom: ".3rem",
                              marginRight: "10px",
                            }}
                          />
                        )}
                    {from === "practice"
                      ? ""
                      : rply.user_id === agentDetails.agentUserId && (
                          //   <img
                          //   src="/images/abadge.png"
                          //   style={{
                          //     width: "46px",
                          //     paddingBottom: ".3rem",
                          //     marginRight: "10px",
                          //   }}
                          // />
                          <span
                            style={{
                              color: "var(--primColor)",
                              fontSize: "12px",
                              display: "inline-block",
                              paddingRight: "10px",
                            }}
                          >
                            <em>Listing Agent</em>{" "}
                          </span>
                        )}
                    {from === "practice" ? (
                      <span className="commentsCardDate">
                        {moment(rply.created_at).format("MMM DD")} at{" "}
                        {moment(rply.created_at).format("hh:mm A")}
                      </span>
                    ) : (
                      // <span className="commentsCardDate">
                      //   {moment
                      //     .utc(
                      //       moment(rply.created_at).subtract({ hours: "02" })
                      //     )
                      //     .local()
                      //     .format("MMM DD")}{" "}
                      //   at{" "}
                      //   {moment
                      //     .utc(
                      //       moment(rply.created_at).subtract({ hours: "02" })
                      //     )
                      //     .local()
                      //     .format("hh:mm A")}
                      // </span>
                      <span className="commentsCardDate">
                        {moment(rply.created_at).local().format("MMM DD")} at{" "}
                        {moment(rply.created_at).local().format("hh:mm A")}
                      </span>
                    )}
                  </div>
                </div>
                <p className="pc-descp">{rply.comment}</p>
                <div className="clearfix">
                  {userLoggedIn.get &&
                  userDetails.get["id"] !== parseInt(rply.user_id) ? (
                    <div className="float-right text-right red-links">
                      <a className="red-text">Flag as not constructive</a>
                    </div>
                  ) : (
                    userDetails.get["id"] === parseInt(rply.user_id) && (
                      <div className="float-right text-right ed-links cnstrtFlg">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            from === "practice"
                              ? handleCommentEdit({
                                  firstId: firstId,
                                  secondId: parseInt(data.id),
                                  thirdId: parseInt(rply.id),
                                  text: rply.comment,
                                })
                              : handleCommentEdit({
                                  id: parseInt(rply.id),
                                  text: rply.comment,
                                })
                          }
                        >
                          <img src="/images/edit.png" /> Edit
                        </a>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            from === "practice"
                              ? handleCommentDelete({
                                  firstId: firstId,
                                  secondId: parseInt(data.id),
                                  thirdId: parseInt(rply.id),
                                })
                              : handleCommentDelete(parseInt(rply.id))
                          }
                        >
                          <img src="/images/delete.png" /> Delete
                        </a>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ))}
    </div>
  );
};

export default Comment;
