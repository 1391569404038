import React, { useState, useEffect } from "react";
import PrimaryButton from "../PrimaryButton";
import CustomInput from "../Inputs/CustomInput";
import * as Yup from "yup";
import axios from "../../../utils/axiosconfig";
import { normalizePhoneNumberInput } from "../../../utils/FormikUtils";
import "./dialog.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";

export default function InviteForm(props) {
  const [emailOptions, setEmailOptions] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const filter = createFilterOptions();
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    if (props.isResetOld) {
      resetOldAgentField();
      props.setIsResetOld(false);
    }
  }, [props.isResetOld]);

  useEffect(() => {
    if (props.isResetNew) {
      resetNewAgentField();
      props.setIsResetNew(false);
    }
  }, [props.isResetNew]);

  useEffect(() => {
    if (props.type === "Agent") {
      setIsNewUser(true);
    }
  }, [props.type]);

  const suggestAgentEmail = async (event, val) => {
    // val = val.trim();
    setInputVal(val);
    if (val !== "" && val !== null) {
      const queryStr = `search=${val}`;
      const { data } = await axios.get(
        `/${props.type.toLowerCase()}EmailSuggests?` + queryStr,
        {
          headers: { site_id },
        },
      );
      setEmailOptions(
        data.map((obj) =>
          obj.name || obj.email ? `${obj.name}, ${obj.email}` : "",
        ),
      );
    }
  };

  const InviteSchema = Yup.object({
    email_new: Yup.string()
      .max(100, "Maximum 100 characters")
      .required(`Enter ${props.type} Email`)
      .email("Enter Valid Email"),
    first_name: Yup.string()
      .max(30, "Maximum 30 characters")
      .required("Enter your First Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    last_name: Yup.string()
      .max(30, "Maximum 30 characters")
      .required("Enter your Last Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
  });

  const inviteSchemaOld = Yup.object({
    name: Yup.string().nullable(),
    email: Yup.string()
      .max(50, "Maximum 50 characters")
      .email("Enter Valid Email")
      .nullable(),
  });

  const resetNewAgentField = () => {
    formik.setFieldValue("email_new", "");
    formik.setFieldValue("first_name", "");
    formik.setFieldValue("last_name", "");
    formik.setFieldValue("phone", "");
  };

  const resetOldAgentField = () => {
    formikOld.setFieldValue("email", "");
    formikOld.setFieldValue("name", "");
  };

  const formikOld = useFormik({
    initialValues: { name: "", email: "", type: props.type },
    validationSchema: inviteSchemaOld,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (props.type === "Client") {
        props.sendInviteOld({
          email: values.email,
          type: values.type,
        });
      } else {
        // const { data } = await axios.get(
        //   `/${props.type.toLowerCase()}EmailSuggests?search=${values.name}`
        // );
        let agentEmail = values.name.split(",")[1].trim();
        props.sendInviteOld({
          email: agentEmail,
          type: values.type,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email_new: "",
      first_name: "",
      last_name: "",
      phone: "",
      type: props.type,
    },
    validationSchema: InviteSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      props.sendInviteNew({
        email: values.email_new,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        phone: values.phone,
        type: values.type,
        redirectURL: `${window.location.origin}/signup`,
      });
    },
  });

  return (
    <div>
      <div>
        <div id="alert-dialog-slide-description" className="text-left mb-3">
          <span style={{ color: "black", fontSize: "18px" }}>
            {`Invite an ${props.type.toLowerCase()} with a Doorsey account.`}
          </span>
        </div>
        <form onSubmit={formikOld.handleSubmit}>
          {props.type === "Agent" ? (
            <div className="search-agent">
              <Autocomplete
                name="name"
                getOptionLabel={(option) => option}
                value={formikOld.values.name}
                onChange={(event, val) => formikOld.setFieldValue("name", val)}
                options={emailOptions}
                inputValue={inputVal}
                onInputChange={suggestAgentEmail}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  filtered.push(
                    <PrimaryButton disabled={true}>
                      Can't find your agent? Invite them below
                    </PrimaryButton>,
                  );
                  return filtered;
                }}
                renderInput={(params) => (
                  <CustomInput
                    {...params}
                    onKeyDown={(event) => {
                      if (
                        event &&
                        event.type === "keydown" &&
                        (event.key === "Tab" || event.key === "Enter") &&
                        event.target.value
                      ) {
                        formikOld.setFieldValue("name", event.target.value);
                      }
                    }}
                    label={`${props.type} Name or Email`}
                    placeholder={`${props.type} name or email`}
                    size="small"
                    className={`hh-input ${
                      formikOld.errors.name &&
                      formikOld.touched.name &&
                      "is-invalid"
                    }`}
                  />
                )}
              />
              {formikOld.errors.name && formikOld.touched.name ? (
                <div className="invalid-feedback">{formikOld.errors.name}</div>
              ) : null}
            </div>
          ) : (
            <>
              <CustomInput
                label={`${props.type} Email`}
                value={formikOld.values.email}
                name="email"
                size="small"
                onChange={formikOld.handleChange}
                onBlur={formikOld.handleBlur}
                className={`hh-input ${
                  formikOld.errors.email &&
                  formikOld.touched.email &&
                  "is-invalid"
                }`}
                placeholder={`${props.type.toLowerCase()}'s email`}
                required
              />
              {formikOld.errors.email && formikOld.touched.email ? (
                <div className="invalid-feedback">{formikOld.errors.email}</div>
              ) : null}
            </>
          )}

          <div className="oldAgentActionButtons">
            {props.type === "Agent" ? (
              ""
            ) : (
              <div className="no-decoration">
                <button
                  className="btn btn-border"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNewUser(true);
                  }}
                >
                  {props.type === "Client"
                    ? `My ${props.type.toLowerCase()} needs an account`
                    : `Invite your ${props.type.toLowerCase()} to create a Doorsey account.`}
                </button>
              </div>
            )}

            <div className="no-decoration">
              <PrimaryButton
                btnSize="small"
                type="submit"
                disabled={
                  props.type === "Client"
                    ? !formikOld.values.email
                    : !formikOld.values.name
                }
              >
                Send Invite
              </PrimaryButton>
            </div>
          </div>
        </form>
        <form onSubmit={formik.handleSubmit}>
          {isNewUser ? (
            <div className="newAgentSection">
              <div
                id="alert-dialog-slide-description"
                className="text-left mb-3"
              >
                <span style={{ color: "black", fontSize: "18px" }}>
                  {props.type === "Client"
                    ? `Invite your ${props.type.toLowerCase()} to sign up on Doorsey.`
                    : `Invite your ${props.type.toLowerCase()} to create a Doorsey account.`}
                </span>
              </div>
              <div className="row mt-2">
                <div className={props.width ? "col-6" : "col-12 mb-3"}>
                  <CustomInput
                    label="First Name"
                    value={formik.values.first_name}
                    name="first_name"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`hh-input ${
                      formik.errors.first_name &&
                      formik.touched.first_name &&
                      "is-invalid"
                    }`}
                    placeholder="John"
                    required
                  />
                  {formik.errors.first_name && formik.touched.first_name ? (
                    <div className="invalid-feedback">
                      {formik.errors.first_name}
                    </div>
                  ) : null}
                </div>
                <div className={props.width ? "col-6" : "col-12"}>
                  <CustomInput
                    label="Last Name"
                    value={formik.values.last_name}
                    name="last_name"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`hh-input ${
                      formik.errors.last_name &&
                      formik.touched.last_name &&
                      "is-invalid"
                    }`}
                    placeholder="Doe"
                    required
                  />
                  {formik.errors.last_name && formik.touched.last_name ? (
                    <div className="invalid-feedback">
                      {formik.errors.last_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4">
                <div className={props.width ? "col-6" : "col-12 mb-3"}>
                  <CustomInput
                    label={`${props.type} Email`}
                    value={formik.values.email_new}
                    name="email_new"
                    size="small"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`hh-input ${
                      formik.errors.email_new &&
                      formik.touched.email_new &&
                      "is-invalid"
                    }`}
                    placeholder="john@mail.com"
                    required
                  />
                  {formik.errors.email_new && formik.touched.email_new ? (
                    <div className="invalid-feedback">
                      {formik.errors.email_new}
                    </div>
                  ) : null}
                </div>
                <div className={props.width ? "col-6" : "col-12"}>
                  <CustomInput
                    label="Phone Number"
                    type="tel"
                    value={formik.values.phone}
                    name="phone"
                    size="small"
                    inputProps={{ maxlength: 14 }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "phone",
                        normalizePhoneNumberInput(
                          e.target.value,
                          formik.values.phone,
                        ),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    className={`hh-input ${
                      formik.errors.phone &&
                      formik.touched.phone &&
                      "is-invalid"
                    }`}
                    placeholder="(555) 123 - 4567"
                  />
                  {formik.errors.phone && formik.touched.phone ? (
                    <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="newAgentActionButton">
                <div className="no-decoration">
                  <PrimaryButton
                    btnSize="small"
                    type="submit"
                    disabled={
                      !formik.values.first_name &&
                      !formik.values.last_name &&
                      !formik.values.email_new
                    }
                  >
                    Send Invite
                  </PrimaryButton>
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}
