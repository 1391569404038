const Constants = {
  ROLE: [
    { id: "1", description: "Register to Bid" },
    { id: "2", description: "Real Estate Vendor" },
    { id: "3", description: "Real Estate Agent" },
  ],
  SERVICE_TYPE: [
    "Handyman",
    "Plumbing",
    "Electrical",
    "Masonry",
    "Flooring",
    "General Contracting",
    "Landscaping",
    "Roofing",
    "HVAC",
    "Painting",
    "Cleaning",
    "Remodeling",
    "Mortgage or Lending",
    "Home Inspection",
    "Closing or Title",
    "Appraisal",
    "Photography or Videography",
    "Transaction Coordination",
    "Other",
  ],
};

export default Constants;
