import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Layout from "../../../components/molecules/Layout";
import CommentList from "../../../components/molecules/Dashboard/CommentList";
import BidsListing from "../../../components/molecules/Dashboard/BidsListing";
import AuctionLostList from "../../../components/molecules/Dashboard/AuctionLostList";
import { getUserData2 } from "../../../API/userData";
import { addComment } from "../../../API/propertyDetails";
import moment from "moment";
import Dialog from "../../../components/atoms/Dialog";
import "react-toastify/dist/ReactToastify.css";
import { onAvatarError } from "../../../utils";
import DOMPurify from "dompurify";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="mt-4">{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `view-account-${index}`,
    "aria-controls": `view-account-tabpanel-${index}`,
  };
}

const RealtorProfile = (props) => {
  const width = useMediaQuery("(min-width:768px)");
  const [value, setValue] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [userAgent, setUserAgent] = useState({});
  const [comments, setComments] = useState([]);
  const [listing, setListing] = useState([]);
  const history = useHistory();
  const { username } = props.match.params;
  const [open, setOpen] = useState({});
  const [bidding, setBidding] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const site_id = global?.storeDetails?.site_id;

  useEffect(() => getData(), [username, site_id]);

  console.log(site_id, "site_idFromRealtor");

  const getData = async () => {
    let data = await getUserData2(username, site_id);

    if (data) {
      setUserDetails(data.User_Profile);
      setComments(data.User_Comments);
      let agentListing = data.Agent_Listing ? data.Agent_Listing : [];
      if (data.User_Profile) {
        if (data.User_Profile.profiletype === "agent") {
          setListing([...agentListing]);
        } else {
          setListing([...data.User_Listing]);
        }
      }
      setBidding(data.User_Bidding);
      if (data.User_Agent && data.User_Agent.length > 0) {
        setUserAgent(data.User_Agent[0]);
      }
    } else {
    }
  };

  const postComment = async (isLastComment, text, commentId, topic) => {
    if (text && DOMPurify.sanitize(text)) {
      await addComment(
        {
          property_id: open.product_id,
          comment: DOMPurify.sanitize(text),
          comment_id: commentId,
          isLastComment: isLastComment,
          topic: topic,
        },
        site_id,
      );
      setOpen({});
    }
  };
  return (
    <Layout>
      <div className="container va-container">
        <div className="btp-link pt-2">
          <Link onClick={() => history.goBack()} className="back-link">
            <i className="fa fa-angle-left"></i> <span>Back</span>
          </Link>
        </div>
        <div className="profile-card-wrapper">
          <div className="media pcd-media">
            <div className="media-left">
              <img
                src={
                  userDetails["avatar"]
                    ? process.env.REACT_APP_IMAGE_URL +
                      "uploads/profile/" +
                      userDetails["avatar"]
                    : "/images/profile_avatar.png"
                }
                alt=""
                className="profile-media-img"
              />
            </div>
            <div className="media-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="pcd-name pc-margin-bottom">
                    {userDetails["username"]}{" "}
                    {width && (
                      <span>
                        {userDetails["profiletype"] === "customer" ||
                        userDetails["profiletype"] === "bidder"
                          ? "General User"
                          : userDetails["profiletype"] === "agent"
                            ? "Realtor"
                            : "Other Real Estate Professional"}
                      </span>
                    )}
                  </div>
                  <div className="joined-date pc-margin-bottom">
                    {userDetails["created_at"]
                      ? `Joined ${moment(userDetails["created_at"]).format(
                          "MMM",
                        )}. ${moment(userDetails["created_at"]).format("YYYY")}`
                      : ""}
                  </div>
                  {!width && (
                    <div className="role-text">
                      {userDetails["profiletype"] === "customer" ||
                      userDetails["profiletype"] === "bidder"
                        ? "General User"
                        : userDetails["profiletype"] === "agent"
                          ? "Realtor"
                          : "Other Real Estate Professional"}
                    </div>
                  )}
                  {/* {width && userDetails["role"] != 1 && (
                    <button className="btn btn-border contact-btn">
                      Contact
                    </button>
                  )} */}
                </div>
                {width && (
                  <div className="col-md-6 text-md-right">
                    <div className="padr-txt pc-margin-bottom">
                      {userDetails.companyName ? userDetails.companyName : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="about-box">
            <div className="am-head">About me</div>
            <p className="am-text">{userDetails.aboutme}</p>
          </div>
          <div className="about-box">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="view-account"
            >
              <Tab label={`Listings (${listing.length})`} {...a11yProps(0)} />
              <Tab label={`Comments (${comments.length})`} {...a11yProps(1)} />
              <Tab label={`Past Bids (${bidding.length})`} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <BidsListing data={listing} from="public" />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CommentList
                comments={comments}
                user={userDetails}
                from="open"
                postNewComment={(ed) => setOpen(ed)}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AuctionLostList data={bidding} from="public" />
            </TabPanel>
          </div>
        </div>
      </div>
      <Dialog
        reply
        open={Object.keys(open).length !== 0}
        onClose={(isLastCooment, commentId, text, setTexts, topic) => {
          postComment(isLastCooment, text, commentId, topic);
          setTexts("");
        }}
        close={(e) => setOpen({})}
        rply={open}
      />
    </Layout>
  );
};

export default RealtorProfile;
