import axios from "../utils/axiosconfig";

const addNotes = async (body, site_id) => {
  let { data } = await axios.post("addNotes", body, {
    headers: {
      site_id,
    },
  });
  return data;
};

const getAllNotes = async (body, site_id) => {
  let { data } = await axios.post("getNotes", body, {
    headers: {
      site_id,
    },
  });
  return data;
};

export { getAllNotes, addNotes };
