import valid from "card-validator";
import moment from "moment";
import PhoneNumber from "awesome-phonenumber";
import { Formik } from "formik";

const docFormats = ["application/pdf"];
const imageFormats = ["image/jpg", "image/jpeg", "image/png"];

const checkImageFormats = (value) => value && imageFormats.includes(value.type);

const checkDocFormat = (value) => value && docFormats.includes(value.type);

const checkCardNumber = (value) => value && valid.number(value).isValid;

const phoneNumberValid = (value) => {
  if (value) {
    let testedValue = `+1 ${value}`;
    let speratedNumber = PhoneNumber(testedValue).getNumber("significant");
    let regionCode = PhoneNumber(testedValue).getRegionCode();
    let countryCode = PhoneNumber(testedValue).getCountryCode();
    let valuesPhone = `+${countryCode}${speratedNumber}`;
    let validationPhone = PhoneNumber(valuesPhone, regionCode);

    return validationPhone.isValid() && validationPhone.isMobile();
  } else {
    return false;
  }
};

const checkCardHolderName = (value) =>
  value && valid.cardholderName(value).isValid;

const checkYear = (value) => {
  if (value && !isNaN(value)) {
    if (String(value).length === 4) {
      const currentYear = moment().format("YYYY");
      return value >= currentYear;
    } else if (String(value).length === 2) {
      const currentYear = moment().format("YY");
      return value >= currentYear;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const emailValidate = (mail) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(mail);
};
const normalizePhoneNumberInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6,
    )}-${currentValue.slice(6, 10)}`;
  }
};

const checkMonth = (value) => {
  return value != 0 && value <= 12;
};

const checkExpirationDate = (value) =>
  value && valid.expirationDate(value).isValid;

const checkCVV = (value) => {
  return value && (value.length === 3 || value.length === 4);
};

export {
  checkImageFormats,
  checkDocFormat,
  checkCardNumber,
  checkCardHolderName,
  checkExpirationDate,
  checkCVV,
  checkYear,
  checkMonth,
  phoneNumberValid,
  normalizePhoneNumberInput,
  emailValidate,
};
