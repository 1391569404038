import React, { useEffect } from "react";
import * as CronofyElements from "cronofy-elements";
import { alertWithButton } from "../../../utils";
import qs from "qs";

const Scheduling = (props) => {
  useEffect(() => {
    if (props.location.search) {
      let query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
      localStorage.setItem("cId", query.id);
      CronofyElements.CalendarSync({
        target_id: "cronofy-calendar",
        data_center: "us",
        authorization: {
          redirect_uri: `${window.location.origin}/schedulingsuccess`,
          client_id: "LWS-dDnMIVOkDNEk4k2rmGLAm-DgWpUV",
          scope: "read_write",
        },
      });
    } else {
      alertWithButton("Somethingwent wrong", () => props.history.push("/"));
    }
  }, []);

  return <div id="cronofy-calendar"></div>;
};

export default Scheduling;
