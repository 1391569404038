import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { useHistory } from "react-router-dom";
import MyInviteList from "../../../components/molecules/Dashboard/MyInviteList";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import InviteDialog from "../../../components/atoms/InviteDialog";
import NoAgentDialogSlide from "../../../components/atoms/NoAgentDialog";
import axios from "../../../utils/axiosconfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyInvites = (props) => {
  const [inviteAgentModalOpen, setInviteAgentModalOpen] = useState(false);
  const [noAgentModalOpen, setNoAgentModalOpen] = useState(false);
  const [isResetNoAgent, setIsResetNoAgent] = useState(false);
  const [isNoAgentSubmitted, setIsNoAgentSubmitted] = useState(false);
  const [isResetOld, setIsResetOld] = useState(false);
  const [isResetNew, setIsResetNew] = useState(false);
  const [sentInviteList, setSentInviteList] = useState([]);
  const [receivedInviteList, setReceivedInviteList] = useState([]);
  const width = useMediaQuery("(min-width:768px)");
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    getInvitesReceived();
    getInvitesSent();
  }, []);

  const getInvitesReceived = async () => {
    const res = await axios.get("api/my_invite_received", {
      headers: { site_id },
    });
    if (res.data) {
      if (res.data.status) {
        setReceivedInviteList(res.data.data);
      } else {
        setReceivedInviteList([]);
      }
    }
  };

  const getInvitesSent = async () => {
    const res = await axios.get("api/my_invite_sent", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        setSentInviteList(res.data.data);
      } else {
        setSentInviteList([]);
      }
    }
  };

  const handleSendInviteOld = async (payload) => {
    const res = await axios.post("api/invite_old_user", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        setInviteAgentModalOpen(false);
        toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        setIsResetOld(true);
      }
    } else {
      toast.error("Something went wrong");
      setIsResetOld(true);
    }
  };

  const handleSendInviteNew = async (payload) => {
    const res = await axios.post("api/invite_new_user", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        setInviteAgentModalOpen(false);
        toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        setIsResetNew(true);
      }
    } else {
      toast.error("Something went wrong");
      setIsResetNew(true);
    }
  };

  const handleInviteAction = async (payload) => {
    const res = await axios.post("api/invite_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        //toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleOfferAction = async (payload) => {
    const res = await axios.post("api/offer_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        //toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const redirectToMyAgentPage = () => {
    if (window.location.pathname.includes(`/myagent`)) {
      setNoAgentModalOpen(false);
      setIsResetNoAgent(true);
      setIsNoAgentSubmitted(false);
    } else {
      history.push(`/myagent`);
    }
  };

  const NoAgentSubmitHandler = async (payload) => {
    const res = await axios.post("api/help_find_agent", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data?.status) {
      setIsNoAgentSubmitted(true);
    }
  };

  return (
    <>
      {props.from === "editBidder" ? (
        <>
          {sentInviteList.length === 0 && receivedInviteList.length === 0 ? (
            <>
              <p>
                Add your agent below or in the My Agent page of your dashboard.
              </p>
              <div className="edit-bidder-invite-button">
                {props.type === "Agent" ? (
                  <button
                    className="btn btn-border no-agent-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setNoAgentModalOpen(true);
                    }}
                  >
                    I don’t have an agent
                  </button>
                ) : (
                  ""
                )}
                <PrimaryButton
                  label={
                    <>
                      <span className="material-icons">add</span>{" "}
                      {`Invite ${props.type}`}
                    </>
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.type === "Agent" && sentInviteList.length > 0
                      ? toast.warning("You can only invite one agent")
                      : setInviteAgentModalOpen(true);
                  }}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <MyInviteList
            type={props.type}
            from={props.from}
            width={false}
            sentInviteList={sentInviteList}
            receivedInviteList={receivedInviteList}
            handleOfferAction={handleOfferAction}
            handleInviteAction={handleInviteAction}
          />
          <InviteDialog
            type={props.type}
            width={width}
            open={inviteAgentModalOpen}
            onClose={() => {
              setInviteAgentModalOpen(false);
              setIsResetNew(true);
              setIsResetOld(true);
            }}
            close={() => {
              setInviteAgentModalOpen(false);
              setIsResetNew(true);
              setIsResetOld(true);
            }}
            sendInviteOld={handleSendInviteOld}
            sendInviteNew={handleSendInviteNew}
            isResetNew={isResetNew}
            setIsResetNew={setIsResetNew}
            isResetOld={isResetOld}
            setIsResetOld={setIsResetOld}
          />
          <NoAgentDialogSlide
            width={width}
            open={noAgentModalOpen}
            onClose={() => {
              setNoAgentModalOpen(false);
              setIsResetNoAgent(true);
              setIsNoAgentSubmitted(false);
            }}
            close={() => {
              setNoAgentModalOpen(false);
              setIsResetNoAgent(true);
              setIsNoAgentSubmitted(false);
            }}
            redirectToMyAgentPage={redirectToMyAgentPage}
            handleNoAgentFormSubmit={NoAgentSubmitHandler}
            isResetNoAgent={isResetNoAgent}
            setIsResetNoAgent={setIsResetNoAgent}
            isNoAgentSubmitted={isNoAgentSubmitted}
          />
        </>
      ) : (
        <DBLayout>
          <div className="row">
            <div className="col-4">
              <div className="clearfix">
                <h2 className="pp-head">
                  {props.type === "Client"
                    ? `My ${props.type}s`
                    : `My ${props.type}`}
                </h2>
              </div>
            </div>
            <div className="col-8">
              <div className="row invite-clients">
                {props.type === "Agent" &&
                sentInviteList.length === 0 &&
                receivedInviteList.length === 0 ? (
                  <button
                    className="btn btn-border no-agent-button"
                    onClick={() => setNoAgentModalOpen(true)}
                  >
                    I don’t have an agent
                  </button>
                ) : (
                  ""
                )}
                <PrimaryButton
                  label={
                    <>
                      <span className="material-icons">add</span>{" "}
                      {`Invite ${props.type}`}
                    </>
                  }
                  onClick={() => {
                    props.type === "Agent" && sentInviteList.length > 0
                      ? toast.warning("You can only invite one agent")
                      : setInviteAgentModalOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
          <MyInviteList
            type={props.type}
            width={width}
            sentInviteList={sentInviteList}
            receivedInviteList={receivedInviteList}
            handleOfferAction={handleOfferAction}
            handleInviteAction={handleInviteAction}
          />
          <InviteDialog
            type={props.type}
            width={width}
            open={inviteAgentModalOpen}
            onClose={() => {
              setInviteAgentModalOpen(false);
              setIsResetNew(true);
              setIsResetOld(true);
            }}
            close={() => {
              setInviteAgentModalOpen(false);
              setIsResetNew(true);
              setIsResetOld(true);
            }}
            sendInviteOld={handleSendInviteOld}
            sendInviteNew={handleSendInviteNew}
            isResetNew={isResetNew}
            setIsResetNew={setIsResetNew}
            isResetOld={isResetOld}
            setIsResetOld={setIsResetOld}
          />
          <NoAgentDialogSlide
            width={width}
            open={noAgentModalOpen}
            onClose={() => {
              setNoAgentModalOpen(false);
              setIsResetNoAgent(true);
              setIsNoAgentSubmitted(false);
            }}
            close={() => {
              setNoAgentModalOpen(false);
              setIsResetNoAgent(true);
              setIsNoAgentSubmitted(false);
            }}
            redirectToMyAgentPage={redirectToMyAgentPage}
            handleNoAgentFormSubmit={NoAgentSubmitHandler}
            isResetNoAgent={isResetNoAgent}
            setIsResetNoAgent={setIsResetNoAgent}
            isNoAgentSubmitted={isNoAgentSubmitted}
          />
        </DBLayout>
      )}
    </>
  );
};

export default MyInvites;
