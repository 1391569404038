import React, { useRef, useState, useEffect } from "react";
import Layout from "../../../components/molecules/Layout";
import CheckBox from "../../../components/atoms/CheckBox";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axiosconfig";
import { Link } from "react-router-dom";
import { showSuccess, showWaring, onImageError } from "../../../utils";
import listing from "./listing.css";

const SellerCheckout = (props) => {
  let { id } = props.match.params;
  const [getProductDetails, seyProductDetails] = useState({});
  const [isChecked, setChecked] = useState(false);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    const getData = async () => {
      let { data } = await axios.post(
        "getPropertyDetails",
        { id: id },
        {
          headers: { site_id },
        },
      );
      seyProductDetails(data.result.propertyDetails);
    };
    getData();
  }, []);

  const paynow = async (e) => {
    let { data } = await axios.post(
      "updatePaidStatus",
      {
        transactionId: "12334",
        id: id,
      },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      showSuccess("Payment succeed");
      props.history.push(`/watchlist`);
    } else {
      showWaring("Payment Failed");
    }
  };

  return (
    <Layout>
      <div className="post-listing-wrapper">
        <div className="container choose-planner-wrpper">
          <div className="row pt-5 sc-wrapper">
            <div className="col-md-8">
              <div className="yp-head">Your Plan</div>
              <div className="media sc-media">
                <div className="media-left">
                  <img
                    src={
                      getProductDetails.avatar === null
                        ? "/images/noimage.png"
                        : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${getProductDetails.avatar}`
                    }
                    alt={getProductDetails.title}
                    className="sc-img"
                    onError={onImageError}
                  />
                </div>
                <div className="media-body">
                  <div className="cl-head">{getProductDetails.title}</div>
                  <div className="clearfix">
                    <div className="float-left">
                      <div className="cc-options">
                        <p>Includes:</p>
                        {getProductDetails.home_page_listing_fee === 299 ? (
                          <ul>
                            <li>- Enhanced Listing</li>
                            <li> - Pre Inspection</li>
                          </ul>
                        ) : (
                          <ul>
                            <li>- 3rd party inspection </li>
                            <li>- Enhanced Listing Creation</li>
                            <li>- Marketing Resources</li>
                            <li>- Esign Offer Creation</li>
                            <li>
                              - Expert Community Helps to answer buyers
                              questions
                            </li>
                            <li>
                              - Expert Community Helps to answer buyers
                              questions
                            </li>
                            <li>- over 200 Photos</li>
                            <li>- Matterport 3D Home Tour </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="float-right cc-price">
                      ${getProductDetails.home_page_listing_fee}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sc-gbox">
                <button
                  className="btn btn-primary"
                  disabled={!isChecked}
                  onClick={paynow}
                >
                  Checkout
                </button>
                <CheckBox
                  label={
                    <a href={global?.storeDetails?.terms_link} target="_blank">
                      Terms and Conditions
                    </a>
                  }
                  value={isChecked}
                  onchange={(e) => setChecked((prev) => !prev)}
                />
                <div className="os-wrap">Order Summary :</div>
                <div className="sc-white-box">
                  <div className="sc-summary-text">
                    {getProductDetails.title}
                  </div>
                  <hr />
                  <div className="clearfix sc-summary-text">
                    <div className="float-left">Listing Price</div>
                    <div className="float-right">
                      ${getProductDetails.home_page_listing_fee}
                    </div>
                  </div>
                  <div className="clearfix sc-summary-text">
                    <div className="float-left">Total</div>
                    <div className="float-right">
                      ${getProductDetails.home_page_listing_fee}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerCheckout;
