import React, { useState, useEffect, useContext } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import MyListingsList from "../../../components/molecules/Dashboard/MyListingsList";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";

const MyListings = (props) => {
  const [myListing, setMylisting] = useState([]);
  const { userDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await axios.get("mylisting", {
      headers: {
        site_id,
      },
    });
    const res2 = await axios.get("myAgentlisting", {
      headers: {
        site_id,
      },
    });
    let listings = [];
    if (userDetails.get["profiletype"] === "agent") {
      if (res2.data.status === true) {
        listings.push(res2.data.result.MyListings);
      }
    } else {
      if (res.data.status === true) {
        listings.push(res.data.result.MyListings);
      }
    }
    setMylisting(listings.flat());
  };

  return (
    <DBLayout>
      <div className="auctions-won-wrapper clearfix db-mt-35">
        <h2 className="pp-head float-left">
          My Listings <span>{myListing.length} Properties</span>
        </h2>
      </div>
      <div className="ms-wrapper">
        <MyListingsList data={myListing} />
        {/* <RecentlyAdded /> */}
      </div>
    </DBLayout>
  );
};

export default MyListings;
