import React, { useState, useEffect, useContext } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import CommentList from "../../../components/molecules/Dashboard/CommentList";
import axios from "../../../utils/axiosconfig";
import Dialog from "../../../components/atoms/Dialog";
import { findIndex } from "lodash";
import { addComment } from "../../../API/propertyDetails";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DOMPurify from "dompurify";
import Pagination from "../../../components/atoms/Pagination";
const Comments = (props) => {
  const [getComments, setComments] = useState([]);
  const [total, setTotal] = useState(0);
  const [modalopen, setModalOpen] = useState({});
  const [open, setOpen] = useState({});
  const [page, setPage] = useState(1);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => getData({ limit: 10, page: page }), []);

  const getData = async (comment) => {
    let { data } = await axios.post("/mycomments", comment, {
      headers: { site_id },
    });
    if (data.status === true) {
      setComments(data.result.MyComments);
      setTotal(data.total_count);
    }
  };

  const postComment = async (isLastComment, text, commentId) => {
    if (text && DOMPurify.sanitize(text)) {
      await addComment(
        {
          property_id: open.product_id,
          comment: DOMPurify.sanitize(text),
          comment_id: commentId,
          isLastComment: isLastComment,
          topic: "General",
        },
        site_id,
      );
      setOpen({});
    }
  };

  const onEdit = async (id, text) => {
    if (text && DOMPurify.sanitize(text)) {
      let { data } = await axios.post(
        "editComment",
        {
          id,
          text: DOMPurify.sanitize(text),
        },
        {
          headers: { site_id },
        },
      );
      if (data.status === true) {
        const position = findIndex(getComments, { id: id });
        if (position !== -1) {
          toast.success("Comment updated successfully");
          let comments = getComments;
          let comment = comments[position];
          comment["comment"] = DOMPurify.sanitize(text);
          comments[position] = { ...comment };
          setComments([...comments]);
        } else {
          toast.success("Something went wrong");
        }
      } else {
        toast.success("Something went wrong");
      }
    }
  };

  const OnDelete = async (id) => {
    let { data } = await axios.post(
      "commentDelete",
      { id },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      const position = findIndex(getComments, { id: id });
      if (position !== -1) {
        toast.success("Comment deleted successfully");
        let comments = getComments;
        comments.splice(position, 1);
        setComments([...comments]);
        setTotal((prv) => prv - 1);
      } else {
        toast.success("Something went wrong");
      }
    } else {
      toast.success("Something went wrong");
    }
  };
  const handlePageChange = (event, page) => {
    setPage(page);
    getData({ limit: 10, page: page });
  };
  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head">
          My Questions & Comments <span>({total})</span>
        </h2>
      </div>
      <CommentList
        comments={getComments}
        OnDelete={OnDelete}
        onEdit={(edi) => setModalOpen({ ...edi })}
        postNewComment={(ed) => setOpen(ed)}
      />
      {getComments && total > 10 && (
        <div>
          <Pagination
            count={Math.ceil(total / 10)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      )}
      <Dialog
        editComment
        open={Object.keys(modalopen).length !== 0}
        onClose={(commentId, text, setTexts) => {
          if (text && DOMPurify.sanitize(text)) {
            onEdit(commentId, text);
            setTexts("");
            setModalOpen({});
          }
        }}
        close={(e) => setModalOpen({})}
        edit={modalopen}
      />
      <Dialog
        reply
        open={Object.keys(open).length !== 0}
        onClose={(isLastCooment, commentId, text, setTexts, topics) => {
          if (text && DOMPurify.sanitize(text)) {
            postComment(isLastCooment, text, commentId, topics);
            setTexts("");
          }
        }}
        close={(e) => setOpen({})}
        rply={open}
      />
    </DBLayout>
  );
};

export default Comments;
