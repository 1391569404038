import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FavoriteCheckbox from "../FavoriteCheckbox";
import ListingCard from "../ListingCard";

const AuctionWonCard = ({ item, props }) => {
  // completed - clearfix aw-step completed
  // next - clearfix aw-step active
  //not yet - clearfix
  return (
    <div className="aw-card">
      <ListingCard item={item} from="won" />
      {/* <div className="awc-bottom">
        <div className="clearfix aw-steps">
          <div className="clearfix aw-step completed">
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Auction won</div>
          </div>
          <div
            className={`clearfix aw-step ${
              item.paid === "true" ? "completed" : "active"
            }`}
          >
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Payment</div>
          </div>
          <div
            className={`clearfix aw-step ${
              item.docu_signed === "true"
                ? "completed"
                : item.paid === "true"
                ? "active"
                : ""
            }`}
          >
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Sign Documents</div>
          </div>
          <div
            className={`clearfix ${
              item.under_contract === "true"
                ? "completed"
                : item.docu_signed === "true"
                ? "active"
                : ""
            }`}
          >
            <div className="step-circle">
              <i className="fa fa-check"></i>
            </div>
            <div>Under Contract</div>
          </div>
        </div>
        <p>
          Standardized Purchase and sale Aggreements have been sent to both
          parties and respective agents. Once you review and sign, you'll be
          under contract.
        </p>
      </div> */}
    </div>
  );
};

export default AuctionWonCard;
