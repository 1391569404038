import axios from "../utils/axiosconfig";

const getSearch = async (body, site_id) => {
  let { data } = await axios.post("getAllProducts", body, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.log("err===>", err);
  }
};

const saveSearch = async (body, site_id) => {
  let { data } = await axios.post("saveSearch", body, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.log("err===>", err);
  }
};

export { getSearch, saveSearch };
