import React, { useContext, useEffect, useState } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyAgent from "../../../components/molecules/Dashboard/VerifyAgent";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";
import { getUserProfileData } from "../../../API/userData";
import { useHistory, useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OfferPractice from "../../../components/molecules/Dashboard/OfferPractice";
import CommentPractice from "../../../components/molecules/Dashboard/CommentPractice";
import PrimaryButton from "../../../components/atoms/PrimaryButton";

function AgentVerification(props) {
  const {
    agentVerification,
    sentInvites,
    receivedInvites,
    agentVerificationDetails,
    userDetails,
  } = useContext(GlobalContext);

  const [agentDetailInfo, setAgentDetailInfo] = useState({});
  const [verifyAgentLoading, setVerifyAgentLoading] = useState(false);
  const [sentInviteList, setSentInviteList] = useState([]);
  const [receivedInviteList, setReceivedInviteList] = useState([]);
  const width = useMediaQuery("(min-width:768px)");
  const history = useHistory();
  const { status } = useParams();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    if (Object.keys(agentVerificationDetails).length > 0) {
      setAgentDetailInfo(agentVerificationDetails.get);
    }
  }, [agentVerificationDetails.get]);

  useEffect(() => {
    setSentInviteList(sentInvites.get);
  }, [sentInvites.get]);

  useEffect(() => {
    setReceivedInviteList(receivedInvites.get);
  }, [receivedInvites.get]);

  const getInvitesReceived = async () => {
    const res = await axios.get("api/my_invite_received", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        receivedInvites.set(res.data.data);
      } else {
        receivedInvites.set([]);
      }
    }
  };

  const getInvitesSent = async () => {
    const res = await axios.get("api/my_invite_sent", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        sentInvites.set(res.data.data);
      } else {
        sentInvites.set([]);
      }
    }
  };

  useEffect(() => {
    getInvitesSent();
    getInvitesReceived();
  }, []);
  //! Verify Account Methods Start
  const verifyAccount = async (payload) => {
    setVerifyAgentLoading(true);
    const res = await axios.post("api/updateAgent", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data.status) {
      let { userDetails: userInfo } = await getUserProfileData(site_id);
      if (Object.keys(userInfo).length > 0) {
        agentVerificationDetails.set({
          brokerageOffice: userInfo.companyName,
          brokerageOfficeUrl: userInfo.brokerage_url,
          phone: userInfo.phone,
        });
        userDetails.set({
          ...userDetails.get,
          bid_approval: userInfo.bid_approval,
        });
      }
      setVerifyAgentLoading(false);
      toast.success(res.data.message);
    }
  };

  //! Verify Account Methods Ends
  const updateMyAgent = async () => {
    let { my_agent: newAgent } = await getUserProfileData(site_id);
    userDetails.set({
      ...userDetails.get,
      myAgent: newAgent,
    });
    return true;
  };

  const handleInviteAction = async (payload) => {
    const res = await axios.post("api/invite_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        getInvitesSent();
        getInvitesReceived();
        updateMyAgent();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleOfferAction = async (payload) => {
    const res = await axios.post("api/offer_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <DBLayout>
      {status === "confirmation" && userDetails.get.bid_approval ? (
        <>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <h3 className="auth-title" style={{ textAlign: "left" }}>
                VERIFICATION COMPLETE
              </h3>
              <p>
                Thank you for submitting your buyer verification details, Your
                account has been verified and you are ready to submit bids on
                Doorsey.
              </p>
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <OfferPractice
                width={width}
                history={history}
                userDetails={userDetails.get}
              />
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <CommentPractice width={width} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="verification-complete-button">
              <PrimaryButton
                btnSize="small"
                label="Invite My Clients"
                onClick={() => history.push(`/agentverification`)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <div className="clearfix">
                <h2 className="pp-head">Client and Verification</h2>
              </div>
            </div>
          </div>
          <VerifyAgent
            width={width}
            verifyAccount={verifyAccount}
            agentDetailInfo={agentDetailInfo}
            userDetails={userDetails.get}
            verifyAgentLoading={verifyAgentLoading}
            sentInviteList={sentInviteList}
            receivedInviteList={receivedInviteList}
            handleOfferAction={handleOfferAction}
            handleInviteAction={handleInviteAction}
            getInvitesSent={getInvitesSent}
            getInvitesReceived={getInvitesReceived}
            history={history}
          />
        </>
      )}
    </DBLayout>
  );
}

export default AgentVerification;
