import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../../components/molecules/Layout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import {
  checkCardNumber,
  checkCardHolderName,
  checkCVV,
  checkYear,
  checkMonth,
} from "../../../utils/FormikUtils";

const SLCheckout = (props) => {
  const [values, setValues] = useState({
    cardname: "",
    cardnumber: "",
    cardmonth: "",
    cardyear: "",
    cardcvc: "",
  });

  const cardSchema = Yup.object({
    cardname: Yup.string()
      .required("Enter name")
      .test("testName", "Enter valid name", checkCardHolderName),
    cardnumber: Yup.string()
      .required("Enter card details")
      .test("testCard", "Enter valid card number", checkCardNumber),
    cardmonth: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Month")
      .test("testMonth", "Enter valid month", checkMonth),
    cardyear: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Year")
      .test("testYear", "Enter valid year", checkYear),
    cardcvc: Yup.string()
      .required("Enter CVV")
      .test("testcvv", "Enter valid CVV", checkCVV),
  });

  return (
    <Layout>
      <div className="container slc-wrapper">
        <div className="btp-link pt-2 slc-back">
          <Link onClick={() => props.history.goBack()} className="back-link">
            <i className="fa fa-angle-left"></i> <span>Back</span>
          </Link>
          <span>Checkout</span>
        </div>
        <div className="syh-cp-box">
          <div>Confirm your selection or upgrade now!</div>
          <div className="">
            <div className="scp-title">Basic</div>
            <div className="scp-price">$99</div>
            <div className="scp-descp">
              The best place to sell a home, period. No other fees for the
              seller or agent.
            </div>
            <div className="scp-btn-wrap">
              <input
                type="radio"
                name="choose-plan"
                value="$99"
                defaultChecked
              />
              <label>Selected</label>
            </div>
          </div>
          <div className="">
            <div className="scp-title">Premium</div>
            <div className="scp-price">$299</div>
            <div className="scp-descp">
              The best place to sell a home, period. No other fees for the
              seller or agent.
            </div>
            <div className="slc-btm">
              <Link to="/sell" className="btn btn-border">
                View
              </Link>
              <div className="scp-btn-wrap">
                <input type="radio" name="choose-plan" value="$299" />
                <label>Select</label>
              </div>
            </div>
          </div>
          <div className="">
            <div className="scp-title">Lux</div>
            <div className="scp-price">$699</div>
            <div className="scp-descp">
              The best place to sell a home, period. No other fees for the
              seller or agent.
            </div>
            <div className="slc-btm">
              <Link to="/sell" className="btn btn-border">
                View
              </Link>
              <div className="scp-btn-wrap">
                <input type="radio" name="choose-plan" value="$699" />
                <label>Select</label>
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-bottom">
          <Formik
            initialValues={values}
            validationSchema={cardSchema}
            enableReinitialize
            validateOnChange={true}
            onSubmit={values}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className="form m-0"
                onSubmit={handleSubmit}
                noValidate
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="slc-white-box">
                      <div className="slc-bhead">Order Summary</div>
                      <div className="clearfix tc-text">
                        <div className="float-left">Basic Listinng</div>
                        <div className="float-right">$99.00</div>
                      </div>
                      <div className="clearfix tc-text">
                        <div className="float-left">Subtotal</div>
                        <div className="float-right">$99.00</div>
                      </div>
                      <div className="clearfix tc-text">
                        <div className="float-left">Taxes</div>
                        <div className="float-right">$9.80</div>
                      </div>
                      <div className="clearfix tc-text">
                        <div className="float-left">Total</div>
                        <div className="float-right">$108.80</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="slc-white-box">
                      <div className="slc-bhead">Next Steps</div>
                      <p>
                        Enter your payment information to begin the listing
                        creation step. We will contact you within 24 hours to
                        answer any questions and schedule any services included
                        in your chosen listing package.
                      </p>
                      <div className="text-right">
                        <Link to="" className="sc-learn-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slc-white-box">
                      <div className="row">
                        <div className="col-12 form-group">
                          <CustomInput
                            label="Name (as it appears on card)"
                            value={values.cardname}
                            name="cardname"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`hh-input ${
                              errors.cardname &&
                              touched.cardname &&
                              "is-invalid"
                            }`}
                            placeholder="John Doe"
                            required
                          />
                          {errors.cardname && touched.cardname ? (
                            <div className="invalid-feedback">
                              {errors.cardname}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 form-group">
                          <CustomInput
                            label="Card Number"
                            value={values.cardnumber}
                            name="cardnumber"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`hh-input ${
                              errors.cardnumber &&
                              touched.cardnumber &&
                              "is-invalid"
                            }`}
                            placeholder="1111 2222 3333 4444"
                            required
                          />
                          {errors.cardnumber && touched.cardnumber ? (
                            <div className="invalid-feedback">
                              {errors.cardnumber}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-6 col-sm-8">
                          <div className="expiration">
                            <div>
                              <CustomInput
                                label="Expiration"
                                value={values.cardmonth}
                                name="cardmonth"
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 2 }}
                                className={`hh-input ${
                                  errors.cardmonth &&
                                  touched.cardmonth &&
                                  "is-invalid"
                                }`}
                                placeholder="MM"
                                required
                              />
                              {errors.cardmonth && touched.cardmonth ? (
                                <div className="invalid-feedback">
                                  {errors.cardmonth}
                                </div>
                              ) : null}
                            </div>
                            <div>
                              <CustomInput
                                label=""
                                value={values.cardyear}
                                name="cardyear"
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 4 }}
                                className={`hh-input ${
                                  errors.cardyear &&
                                  touched.cardyear &&
                                  "is-invalid"
                                }`}
                                placeholder="YY"
                                required
                              />
                              {errors.cardyear && touched.cardyear ? (
                                <div className="invalid-feedback">
                                  {errors.cardyear}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <CustomInput
                            label="CVC"
                            value={values.cardcvc}
                            name="cardcvc"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`hh-input ${
                              errors.cardcvc && touched.cardcvc && "is-invalid"
                            }`}
                            placeholder="CVC"
                            required
                          />
                          {errors.cardcvc && touched.cardcvc ? (
                            <div className="invalid-feedback">
                              {errors.cardcvc}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="syh-checkout-btn text-right">
                  <PrimaryButton label="CHECKOUT" pb_type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default SLCheckout;
