import React, { useState, useContext, useRef, useEffect } from "react";
import { addComment, getRplyComment } from "../../../API/propertyDetails";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Comments from "../../atoms/Comments";
import {
  loanAmountCalculation,
  onAvatarError,
  sanitizer,
} from "../../../utils";
import { getProductWinner } from "../../../API/propertyDetails";
import moment from "moment";
import Dialog from "../../atoms/Dialog";
import GlobalContext from "../../../context/GlobalContext";
import { setDecimalPoint, showWaring } from "../../../utils";
import { findIndex, forEach } from "lodash";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DOMPurify from "dompurify";
const useStyles = makeStyles((theme) => ({
  customFontSize: {
    fontSize: "13px",
  },
}));

const PropertyComments = ({
  comments,
  noComments,
  setComments,
  width,
  propertyDetails,
  agentDetails,
  timer,
  handleCommentEdit,
  handleCommentDelete,
  from,
  commentsRef,
  setBeforePopup,
  setSubmittedPopup,
  submittedPopup,
}) => {
  const { id } = useParams();
  const { userLoggedIn, userDetails, propertyAwarded } =
    useContext(GlobalContext);
  const commentRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [text, setText] = useState("");
  const [offers, setOffers] = useState([]);
  const [modalopen, setModalOpen] = useState({});
  const classes = useStyles();
  const [textError, setTextError] = useState("");
  const [orderedComments, setOrderedComments] = useState(comments);
  const [bidWinner, setBidWinner] = useState([]);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  // useEffect(() => {
  //   const scrollTo = window.location.href.slice(
  //     window.location.href.indexOf("#")
  //   );
  //   scrollTo === "#comment" &&
  //     commentRef.current.scrollIntoView({ behavior: "smooth" });
  // }, [window.location.href]);

  const findNextBid = (bidAmount, id, arr) => {
    let tempAmount = [];

    // console.log(bidAmount, id, arr, 'check1')
    arr.forEach((obj2, id2, arr2) => {
      if (obj2.proposed_amount) {
        if (obj2.proposed_amount > bidAmount) {
          //  console.log(obj2.proposed_amount, bidAmount,id, 'check2')
          tempAmount = obj2;
        }
      }
    });
    return tempAmount;
  };
  const orderComments = (unorderedComments) => {
    unorderedComments.forEach((obj, id, arr) => {
      if (obj.proposed_amount) {
        let temp2 = findNextBid(obj.proposed_amount, id, arr);
        // console.log(temp2, 'check4')
        if (temp2) {
          let insertIndex = arr.indexOf(temp2);
          // console.log('insert index: ', insertIndex, 'current index: ', id, 'insert obj ',  temp2, 'current obj: ', obj,  'array : ', arr)
          if (insertIndex !== id) {
            arr.splice(insertIndex + 1, 0, obj);
            let currentIndex = arr.indexOf(obj);
            arr.splice(currentIndex, 1);
          }
        }
      }
    });

    //unorderedComments.sort(orderByBidAmount);

    setOrderedComments(unorderedComments);
  };

  // const orderByBidAmount = (a, b) => {
  //   if (a.proposed_amount === b.proposed_amount) {
  //     return a.created_at > b.created_at ? -1 : 1;
  //   } else {
  //     return a.proposed_amount > b.proposed_amount ? -1 : 1;
  //   }
  // };

  useEffect(() => {
    setOffers(comments.filter((data) => data.from !== "comments"));
    // setOrderedComments(orderComments(comments))
    orderComments(comments);
  }, [comments]);

  useEffect(() => {
    if (submittedPopup === "ask_question") {
      postComment(0, text, "", "General");
      setSubmittedPopup(false);
    }
  }, [submittedPopup]);

  const getAllRply = async (id, comment_id) => {
    let data = await getRplyComment({ comment_id: id }, site_id);
    if (data.status === true) {
      if (comment_id === undefined) {
        let position = findIndex(comments, { id: id, from: "comments" });
        if (position !== -1) {
          let existingComments = comments;
          let selectedComment = existingComments[position];
          let rply = data.result;
          selectedComment["rply"] = rply;
          existingComments[position] = selectedComment;
          setComments([...existingComments]);
        }
      } else {
        let position = findIndex(comments, {
          id: comment_id,
          from: "comments",
        });
        if (position !== -1) {
          let existingComments = comments;
          let selectedComment = existingComments[position];
          let positionExistingComment = findIndex(selectedComment["rply"], {
            id: id,
          });
          if (positionExistingComment !== -1) {
            let rply = data.result;
            selectedComment["rply"][positionExistingComment]["rply"] = rply;
            existingComments[position] = selectedComment;
            setComments([...existingComments]);
          }
        }
      }
    }
  };

  const addPracticeReply = (isLastComment, text, commentId) => {
    if (text && sanitizer(text)) {
      let commentsClone = comments;
      if (isLastComment === 1) {
        let parentIndex = commentsClone.findIndex(
          (comment) => comment.rply[commentId].id === commentId,
        );
        if (commentsClone[parentIndex].rply[commentId].rply) {
          commentsClone[parentIndex].rply[commentId] = {
            ...commentsClone[parentIndex].rply[commentId],
            rplyCount: commentsClone[parentIndex].rply[commentId].rplyCount + 1,
            rply: [
              ...commentsClone[parentIndex].rply[commentId].rply,
              {
                from: "comments",
                status: "true",
                isDeleted: "No",
                comment: sanitizer(text),
                username: userDetails.get["user_name"],
                companyName: userDetails.get["companyName"],
                created_at: moment(),
                rplyCount: 0,
                user_id: userDetails.get["id"],
                id: commentsClone[parentIndex].rply[commentId].rply.length,
              },
            ],
          };
        } else {
          commentsClone[parentIndex].rply[commentId] = {
            ...commentsClone[parentIndex].rply[commentId],
            rplyCount: 1,
            rply: [
              {
                from: "comments",
                status: "true",
                isDeleted: "No",
                comment: sanitizer(text),
                username: userDetails.get["user_name"],
                companyName: userDetails.get["companyName"],
                created_at: moment(),
                rplyCount: 0,
                user_id: userDetails.get["id"],
                id: 0,
              },
            ],
          };
        }
      } else {
        if (commentsClone[commentId].rply) {
          commentsClone[commentId] = {
            ...commentsClone[commentId],
            rplyCount: commentsClone[commentId].rplyCount + 1,
            rply: [
              ...commentsClone[commentId].rply,
              {
                from: "comments",
                status: "true",
                isDeleted: "No",
                comment: sanitizer(text),
                username: userDetails.get["user_name"],
                companyName: userDetails.get["companyName"],
                created_at: moment(),
                rplyCount: 0,
                user_id: userDetails.get["id"],
                id: commentsClone[commentId].rply.length,
              },
            ],
          };
        } else {
          commentsClone[commentId] = {
            ...commentsClone[commentId],
            rplyCount: 1,
            rply: [
              {
                from: "comments",
                status: "true",
                isDeleted: "No",
                comment: sanitizer(text),
                username: userDetails.get["user_name"],
                companyName: userDetails.get["companyName"],
                created_at: moment(),
                rplyCount: 0,
                user_id: userDetails.get["id"],
                id: 0,
              },
            ],
          };
        }
      }

      setComments(commentsClone);
      setModalOpen({});
      toast.success("You commented successfully");
    }
  };

  const postComment = async (isLastComment, text, commentId, topic) => {
    if (text && DOMPurify.sanitize(text)) {
      if (from === "practice") {
        setComments([
          ...comments,
          {
            from: "comments",
            status: "true",
            isDeleted: "No",
            comment: DOMPurify.sanitize(text),
            username: userDetails.get["user_name"],
            companyName: userDetails.get["companyName"],
            created_at: moment(),
            rplyCount: 0,
            user_id: userDetails.get["id"],
            id: comments.length,
          },
        ]);
        toast.success("You commented successfully");
        setText("");
      } else {
        let datas = await addComment(
          {
            property_id: id,
            comment: DOMPurify.sanitize(text),
            comment_id: commentId,
            isLastComment: isLastComment,
            role: userDetails.get["role"],
            address: userDetails.get["address"],
            companyName: userDetails.get["companyName"],
            topic: topic,
          },
          site_id,
        );
        setText("");
        setTextError("");
        setModalOpen({});
        if (datas.status) {
          toast.success(
            "You commented successfully and comment is under review.",
          );
        }
      }
    }
  };

  const handleTextChange = (e) => {
    if (e.target.value.length > 1000) {
      setTextError("Character Limit is 1000");
    } else {
      setTextError("");
      setText(e.target.value);
    }
  };

  const getProductWinnerDtls = async () => {
    let res = await getProductWinner({ id: id }, site_id);
    if (res.status) {
      setBidWinner(res.highBidders);
    }
  };

  useEffect(() => {
    if (timer.isCompleted) {
      setTimeout(() => {
        getProductWinnerDtls();
      }, 5000);
    }
  }, [timer.isCompleted]);

  // useEffect(() => {
  //   if (timer.isCompleted) {
  //     if (
  //       Object.keys(propertyAwarded.get).length !== 0 &&
  //       propertyAwarded.get["project_id"] == id
  //     ) {
  //       setBidWinner([
  //         {
  //           proposed_amount: propertyAwarded.get["amount"],
  //           project_id: propertyAwarded.get["project_id"],
  //           username: propertyAwarded.get["username"],
  //         },
  //       ]);
  //     }
  //   }
  // }, [propertyAwarded, timer.isCompleted]);

  return (
    <>
      <div className="pv-comments-wrapper">
        {from === "practice" ? (
          ""
        ) : (
          <div className="property-head">
            {/* {noComments} Comments{" "} */}
            Ask a Question
            <Tooltip
              title="Comments are reviewed before they are posted. This process may take a few minutes."
              classes={{ tooltip: classes.customFontSize }}
              arrow
              placement="top"
            >
              <span className="how-comments-work">
                How questions & comments work
              </span>
            </Tooltip>
          </div>
        )}

        <div className="comment-form-box clearfix" ref={commentsRef}>
          <div className="form-group">
            <textarea
              rows="4"
              className="form-control"
              placeholder="Ask the seller a question or leave a comment"
              value={text}
              onChange={handleTextChange}
            ></textarea>
            {textError ? <span>{textError}</span> : ""}
          </div>
          <button
            className="btn btn-border register-to-comment"
            onClick={() =>
              userLoggedIn.get
                ? postComment(0, text, "", "General")
                : setBeforePopup("ask_question")
            }
            // onClick={() =>
            //   userLoggedIn.get
            //     ? postComment(0, text, "", "General")
            //     : history.push({ pathname: "/login", state: location })
            // }
          >
            {/* {userLoggedIn.get ? "Submit" : "Register to comment"} */}
            Submit
          </button>
        </div>

        {!!bidWinner.length && timer.isCompleted ? (
          <div
            className="bid-box highBidder"
            onClick={() =>
              history.push(`/view-account/${bidWinner[0]?.username}`)
            }
          >
            <div className="row">
              <div className={width ? "col-3" : "col-12 text-center"}>
                <span>CONGRATULATIONS!</span>
              </div>
              <div
                className={
                  width ? "col-9 text-right" : "col-12 text-center mt-3"
                }
              >
                {bidWinner[0]?.listingFormat == "auction" ? (
                  <>
                    HIGHEST BID OF{" "}
                    <span>
                      ${setDecimalPoint(bidWinner[0]?.highest_amount)}
                    </span>{" "}
                    BY {bidWinner[0]?.username}
                  </>
                ) : (
                  <>
                    OFFER HAS BEEN AWARDED AT{" "}
                    <span>
                      ${setDecimalPoint(bidWinner[0]?.highest_amount)}
                    </span>{" "}
                    TO {bidWinner[0]?.username}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {orderedComments.map((data) =>
          data.from === "comments" ? (
            data.status === "true" && data.isDeleted === "No" ? (
              <Comments
                data={data}
                getAllRply={getAllRply}
                setModalOpen={setModalOpen}
                setComments={setComments}
                propertyDetails={propertyDetails}
                agentDetails={agentDetails}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
                width={width}
                from={from}
              />
            ) : (
              ""
            )
          ) : (
            <>
              {/* <div className="bid-box">
                <div className="row" style={{ alignItems: "center" }}>
                  <div
                    className={width ? "col" : "col-4 bid-box-username"}
                    style={{ paddingRight: "2px" }}
                  >
                    {data.username}
                  </div>
                  <div className="col text-right">
                    <span className="cb-date">
                      {moment(data.created_at).local().format("MMM DD")} at{" "}
                      {moment(data.created_at).local().format("hh:mm A")}
                    </span>
                    <span>${setDecimalPoint(data.proposed_amount)}</span> <br />
                  </div>
                </div>
              </div> */}
            </>
          ),
        )}
        <Dialog
          reply
          open={Object.keys(modalopen).length !== 0}
          onClose={(isLastCooment, commentId, text, setTexts, topic) => {
            if (text && DOMPurify.sanitize(text)) {
              if (from === "practice") {
                addPracticeReply(isLastCooment, text, commentId);
              } else {
                postComment(isLastCooment, text, commentId, topic);
              }
              setTexts("");
            }
          }}
          close={(e) => setModalOpen({})}
          rply={modalopen}
        />
      </div>
    </>
  );
};

export default PropertyComments;
