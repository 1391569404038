import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddNewCard from "./AddNewCard";
import CCard from "./CCard";

const SavedCards = (props) => {
  return (
    <div className="row savedCardsSection">
      <div className="col-sm-6 col-md-4 form-group">
        <AddNewCard />
      </div>
      <CCard />
    </div>
  );
};

export default SavedCards;
