import React, { useState, useEffect } from "react";

const NotificationsList = (props) => {
  const notifications = [
    {
      title: "Your bid won the auctions for 4540 N Hollywood way",
      date: "Aug 4 at 6:44 PM",
      message:
        "Congratulations, you won the auction for 450 N Hollywood Way, Spokane, WA 99004. Please check your email for documents that require your signatture. View status and updates in the Auctions Won tab. Your card will be charged within 24 hours.",
    },
    {
      title: "Your bid won the auctions for 4540 N Hollywood way",
      date: "Aug 4 at 6:44 PM",
      message:
        "Congratulations, you won the auction for 450 N Hollywood Way, Spokane, WA 99004. Please check your email for documents that require your signatture. View status and updates in the Auctions Won tab. Your card will be charged within 24 hours.",
    },
    {
      title: "Your bid won the auctions for 4540 N Hollywood way",
      date: "Aug 4 at 6:44 PM",
      message:
        "Congratulations, you won the auction for 450 N Hollywood Way, Spokane, WA 99004. Please check your email for documents that require your signatture. View status and updates in the Auctions Won tab. Your card will be charged within 24 hours.",
    },
  ];

  return (
    <>
      {notifications.map((notification, index) => {
        return (
          <div className="card comments-card nf-card">
            <div className="card-body">
              <h5 className="dbc-text">{notification.title}</h5>
              <p className="pc-descp">{notification.message}</p>
              <div className="nf-date">{notification.date}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default NotificationsList;
