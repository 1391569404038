import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";

const UnSoldAuctions = (props) => {
  const auctions = [
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      posted_on: "21/01/2021",
      reserve_price: "$ 556781",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      posted_on: "21/01/2021",
      reserve_price: "$ 556781",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      posted_on: "21/01/2021",
      reserve_price: "$ 556781",
    },
  ];

  return (
    <DBLayout>
      <div className="table-responsive">
        <table className="table table-inverse mt-34">
          <thead>
            <th>Auction Id</th>
            <th>Auction Name</th>
            <th>Posted On</th>
            <th>Reserve Price</th>
            <th>Action</th>
          </thead>
          <tbody>
            {auctions.map((item, index) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.auction_name}</td>
                  <td>{item.posted_on}</td>
                  <td>{item.reserve_price}</td>
                  <td>
                    <Link to="">View</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DBLayout>
  );
};

export default UnSoldAuctions;
