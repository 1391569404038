import React from "react";
import "./UserVerficationStepLayout.css";

function UserVerificationStepLayout(props) {
  return (
    <div className="user-verfication-step">
      <div
        className="step-number"
        style={props.disable ? { background: "#B6B6B6" } : {}}
      >
        {props.step}
      </div>
      <div className="step-components">
        <div className="step-header">
          <h3 className="auth-title">{props.title}</h3>
          {props.isComplete ? (
            ""
          ) : (
            <div className="step-status-section">
              <div className="step-incomplete-status">Incomplete</div>
            </div>
          )}
        </div>

        {props.children}
      </div>
    </div>
  );
}

export default UserVerificationStepLayout;
