import React, { useState, useCallback, useEffect } from "react";
import UserVerificationStepLayout from "../../atoms/UserVerificationStepLayout";
import PrimaryButton from "../../atoms/PrimaryButton";
import CheckBox from "../../atoms/CheckBox";
import PriceInput from "../../atoms/Inputs/PriceInput";
import CustomInput from "../../atoms/Inputs/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import { setDecimalPoint } from "../../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDropzone } from "react-dropzone";
import axios from "../../../utils/axiosconfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FinanceVerification(props) {
  const [fileStatus, setFileStatus] = useState([]);
  const [hoverClassName, setHoverClassName] = useState("loading-hover");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const site_id = localStorage.getItem("site_id");

  const onDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles;
      let currentFileList = [...fileStatus];
      if (files.length > 0 && files.length + currentFileList.length <= 5) {
        setUploadedFileCount(files.length + currentFileList.length);
        if (currentFileList.length < 5) {
          let temp = [...currentFileList];
          files.map(async (file) => {
            if (currentFileList.length < 5) {
              let receivedData = await individualFileUpload(file);
              temp.push(receivedData);
            }
          });
          setUploadLoading(true);
          setFileStatus(temp);
        }
        // setUploadedFiles(currentFileList);
        setFieldValue("file", true, true);
        setFieldError("file", "");
        setFieldTouched("file", true, true);
      } else {
        setFieldValue("file", false, false);
        setFieldTouched("file", true, true);
        //setFieldError("file", "Select a document");
        if (files.length + currentFileList.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
    },
    [fileStatus],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.map((fileObject) => {
      fileObject.errors.map((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Please review the file type of ${fileObject.file.name}. File must be picture or document format`,
          );
        } else {
          console.log("Error is===========>", error);
          toast.error(
            `Please review the size of ${fileObject.file.name}. File size limit is 20MB`,
          );
        }
      });
    });
  }, []);

  useEffect(() => {
    if (fileStatus.length === uploadedFileCount) {
      setUploadLoading(false);
    }
  }, [fileStatus.length]);

  const individualFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("uploads", data);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        return res.data.result;
      }
    } catch (err) {
      console.log(err, "file upload err");
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept:
      ".pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,.csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip",
    maxSize: 20971520,
  });

  const FinancingFormSchema = Yup.object({
    lenderEmail: Yup.string().when("financing", {
      is: (finance) => finance !== "Cash",
      then: Yup.string()
        .email("Enter Valid Email")
        .max(100, "Maximum 100 characters")
        .required("Lender Email is required"),
    }),
    cashlimit: Yup.number()
      .typeError("Enter valid number")
      .test("amount", "Cash limit must be greater then zero", (value) => {
        if (value > 0) {
          return true;
        } else {
          return false;
        }
      })
      .test("length", "Maximum Amount 100,000,000", (value) => {
        if (value <= 100000000) {
          return true;
        } else {
          return false;
        }
      })
      .required("Cash limit is required"),
    financing: Yup.string().required("Financing type is required"),
    // phone: Yup.string()
    //   .required("Enter Mobile Number")
    //   .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      file: false,
      cashlimit: "",
      financing: "",
      //phone: "",
      lenderEmail: "",
    },
    validationSchema: FinancingFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!props.isFinanceLoading) {
        if (fileStatus.length === 0) {
          setFieldError("file", "Select a document");
          return;
        }
        let updateValue = { ...values };
        if (values.financing === "Cash") {
          updateValue.lenderEmail = "";
        }
        props.handleFinanceInformationUpdate({
          ...updateValue,
          uploadedFiles: fileStatus,
        });
      }
    },
  });

  const handleFileRemoval = (index) => {
    let currentFiles = fileStatus;
    currentFiles.splice(index, 1);
    setFileStatus(currentFiles);
  };

  const handleUpdateFinanceClick = () => {
    if (Object.keys(props.userFinanceInformation).length > 0) {
      setFieldValue("file", true);
      setFieldValue("cashlimit", props.userFinanceInformation.purchasePower);
      setFieldValue("financing", props.userFinanceInformation.financeType);
      // setFieldValue(
      //   "phone",
      //   props.userFinanceInformation.phoneNumber
      //     .substring(2, props.userFinanceInformation.phoneNumber.length)
      //     .trim()
      // );
      setFieldValue("lenderEmail", props.userFinanceInformation.lenderEmail);
      createFileObjects(props.userFinanceInformation.financeDocuments);
    }
    props.setShowFinanceForm(true);
  };

  const createFileObjects = (fileNameArray) => {
    let documents = [];
    fileNameArray.map(async (doc) => {
      if (doc) {
        let file = await fetch(
          `${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${doc}`,
        )
          .then((r) => r.blob())
          .then((blobFile) => new File([blobFile], doc, { type: "image/png" }));

        documents.push({ name: file.name, originalname: file.name });
      }
    });
    setFileStatus(documents);
  };

  const handlesubmitRegister = () => {
    return (
      <span className="update">
        Submit
        {props.isFinanceLoading ? (
          <span>
            <CircularProgress className={`loading ${hoverClassName}`} />
          </span>
        ) : (
          ""
        )}
      </span>
    );
  };

  const handleSubmitButtonEnter = () => {
    if (hoverClassName !== "loading-hover") {
      setHoverClassName("loading-hover");
    }
  };

  const handleSubmitButtonLeave = () => {
    if (hoverClassName !== "loading-non-hover") {
      setHoverClassName("loading-non-hover");
    }
  };

  return (
    <UserVerificationStepLayout
      step={props.step}
      title="FINANCING INFORMATION"
      isComplete={true}
      disable={
        Object.keys(props.userFinanceInformation).length === 0
          ? !props.isPreviousComplete
          : false
      }
    >
      <div className="finance-step-block">
        <div>
          <p>
            Add your pre-approval or proof of funds. Don't have one? We can help
            with that, too!
          </p>
        </div>
        <hr />

        {/* Step 1 and 2 */}
        {!props.showFinanceForm &&
        Object.keys(props.userFinanceInformation).length === 0 &&
        !props.isComplete ? (
          <div className="inv-agnt">
            <div className="inv-agnt-btns">
              <PrimaryButton
                btnSize="small"
                disabled={!props.isPreviousComplete}
                onClick={props.handleIHaveFinancingClick}
              >
                I have financing
              </PrimaryButton>

              <PrimaryButton
                btnSize="small"
                disabled={!props.isPreviousComplete}
                onClick={props.handleAddFinanceLater}
              >
                Add later
              </PrimaryButton>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Step 3 */}
        {props.showFinanceForm ? (
          <div className="finance-form-section">
            <div className="left-section">
              <form
                className="form m-0"
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                noValidate
              >
                <div className="auth-form-wrapper">
                  <div className={`rtb-form-wrapper `}>
                    <p className="mt-2 mt-md-3 ">
                      Upload your pre-approval letter. For cash buyers upload
                      your proof of funds statement.
                    </p>

                    <div className="mt-2 mb-4 text-center file-upload-reg">
                      <div style={{ color: "gray", fontSize: "13px" }}>
                        Max capacity is 5 documents
                      </div>
                      <div className="dropzone">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <p>
                              <span className="plus-icon">+</span>Drag files or{" "}
                              <span className="browse-text">browse</span>
                            </p>
                          )}
                        </div>
                      </div>
                      {uploadLoading ? (
                        <span className="update">
                          <CircularProgress className="loading-hover" />
                        </span>
                      ) : fileStatus.length > 0 ? (
                        fileStatus.map((file, index) => (
                          <div className="uploaded-files-box">
                            <div
                              className="row text-center mb-2 uploaded-files-box-item"
                              key={index}
                            >
                              <div className="col-10 uploaded-files">
                                <p>
                                  <a
                                    href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${file.name}`}
                                    target="_blank"
                                  >
                                    {file.originalname}
                                  </a>
                                </p>
                              </div>
                              <span
                                className="material-icons col-2"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => handleFileRemoval(index)}
                              >
                                close
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No file Selected</div>
                      )}
                      <div className="choose-file">
                        {/* <input
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={(e) => handleFileUploadChange(e)}
                      />
                      <label>Choose Documents</label> */}
                        {errors.file && touched.file ? (
                          <div className="invalid-feedback">{errors.file}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group mt-2 climit">
                      <PriceInput
                        label="Enter maximum pre-approval amount or cash limit"
                        value={values.cashlimit}
                        name="cashlimit"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.cashlimit && touched.cashlimit && "is-invalid"
                        }`}
                        required
                      />
                      {errors.cashlimit && touched.cashlimit ? (
                        <div className="invalid-feedback">
                          {errors.cashlimit}
                        </div>
                      ) : null}
                    </div>
                    <p>What type of financing will be used?*</p>
                    <div className="form-group mt-md-4">
                      <div className="finance-checkboxes">
                        <CheckBox
                          label="Cash"
                          value="Cash"
                          checked={values.financing === "Cash"}
                          onchange={(e) => setFieldValue("financing", "Cash")}
                        />
                        <CheckBox
                          label="Conventional"
                          value="Conventional"
                          checked={values.financing === "Conventional"}
                          onchange={(e) =>
                            setFieldValue("financing", "Conventional")
                          }
                        />
                        <CheckBox
                          label="FHA"
                          value="FHA"
                          checked={values.financing === "FHA"}
                          onchange={(e) => setFieldValue("financing", "FHA")}
                        />
                        <CheckBox
                          label="VA"
                          value="VA"
                          checked={values.financing === "VA"}
                          onchange={(e) => setFieldValue("financing", "VA")}
                        />
                        <CheckBox
                          label="Other"
                          value="Other"
                          checked={values.financing === "Other"}
                          onchange={(e) => setFieldValue("financing", "Other")}
                        />
                      </div>
                    </div>
                    {errors.financing && touched.financing ? (
                      <div className="invalid-feedback">{errors.financing}</div>
                    ) : null}
                    {values.financing !== "Cash" && (
                      <div className="form-group mt-2">
                        <CustomInput
                          label="Lender's email address"
                          value={values.lenderEmail}
                          placeholder="Lender's email"
                          name="lenderEmail"
                          size="small"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.lenderEmail &&
                            touched.lenderEmail &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.lenderEmail && touched.lenderEmail ? (
                          <div className="invalid-feedback">
                            {errors.lenderEmail}
                          </div>
                        ) : null}
                      </div>
                    )}
                    {/* <div className="form-group mt-2">
                      <CustomInput
                        label="Phone Number"
                        type="tel"
                        value={values.phone}
                        name="phone"
                        size="small"
                        inputProps={{ maxLength: 14 }}
                        onChange={(e) => {
                          setFieldValue(
                            "phone",
                            normalizePhoneNumberInput(
                              e.target.value,
                              values.phone
                            )
                          );
                        }}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.phone && touched.phone && "is-invalid"
                        }`}
                        placeholder="(555) 123 - 4567"
                        required
                      />
                      {errors.phone && touched.phone ? (
                        <div className="invalid-feedback">{errors.phone}</div>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="finance-button-section">
                    <div className="finance-update-submit">
                      <PrimaryButton
                        btnSize="small"
                        label={handlesubmitRegister()}
                        type="submit"
                        onMouseEnter={handleSubmitButtonEnter}
                        onMouseLeave={handleSubmitButtonLeave}
                      />
                    </div>
                    {Object.keys(props.userFinanceInformation).length > 0 ? (
                      <div className="finance-update-cancel">
                        <PrimaryButton
                          btnSize="small"
                          label="Cancel"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.setShowFinanceForm(false);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div
              className="question-section right-section mt-2 mt-md-3"
              style={{ textAlign: "left" }}
            >
              For questions or concerns about entering your financing
              information, please contact our support team by{" "}
              <span className="buyer-verf">
                <a
                  className="contact-us"
                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Financing Information Support Request`}
                >
                  clicking here
                </a>
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Step 4 */}
        {!props.showFinanceForm &&
        (Object.keys(props.userFinanceInformation).length > 0 ||
          props.isComplete) ? (
          <div className="display-financial-information">
            <div className="top-section">
              <div className="left-section">
                <span>Preapproval or proof of funds documents</span>
                {props.userFinanceInformation.financeDocuments ? (
                  props.userFinanceInformation.financeDocuments.map((doc) =>
                    doc ? (
                      <div>
                        <a
                          href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${doc}`}
                          target="_blank"
                        >
                          {doc}
                        </a>
                      </div>
                    ) : (
                      ""
                    ),
                  )
                ) : (
                  <div>Pending</div>
                )}
              </div>
              <div className="right-section">
                <div className="right-section-item">
                  <span>Type of Financing</span>
                  <div>
                    {props.userFinanceInformation.financeType
                      ? props.userFinanceInformation.financeType
                      : "Pending"}
                  </div>
                </div>
                <div className="right-section-item">
                  <span>Purchase Power</span>
                  <div>
                    {props.userFinanceInformation.purchasePower
                      ? `$ ${setDecimalPoint(
                          props.userFinanceInformation.purchasePower,
                        )}`
                      : "Pending"}
                  </div>
                </div>
                {props.userFinanceInformation.financeType !== "Cash" && (
                  <div className="right-section-item">
                    <span>Lender's email address</span>
                    <div>
                      {props.userFinanceInformation.lenderEmail
                        ? props.userFinanceInformation.lenderEmail
                        : "Pending"}
                    </div>
                  </div>
                )}
                {/* <div className="right-section-item">
                  <span>Your Phone Number</span>
                  <div>
                    {props.userFinanceInformation.phoneNumber
                      ? props.userFinanceInformation.phoneNumber
                      : "Pending"}
                  </div>
                </div> */}
              </div>
            </div>
            <div className="bottom-section">
              <div
                className={
                  props.showBillingForm ? "bottom-section-disabled" : ""
                }
              >
                <span
                  onClick={() =>
                    props.showBillingForm ? "" : handleUpdateFinanceClick()
                  }
                >
                  Update Financing Information
                </span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </UserVerificationStepLayout>
  );
}

export default FinanceVerification;
