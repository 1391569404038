import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  appleTouchIcon,
  favIcon,
  keywords,
  logo,
}) => {
  return (
    <>
      <Helmet>
        <title>{`${title || "Doorsey"}`}</title>
        <link rel="icon" id="favicon" href={favIcon} />
        <meta
          name="description"
          content={`${
            description ||
            "View due diligence, schedule tours, ask questions, and submit bids on Doorsey."
          }`}
        />
        <link rel="apple-touch-icon" href={appleTouchIcon} />
        <meta name="keywords" content={keywords} />
        <meta
          name="og:title"
          property="og:title"
          content={`${title || "Doorsey"}`}
        />
        <meta
          name="og:description"
          property="og:description"
          content={`${
            description ||
            "View due diligence, schedule tours, ask questions, and submit bids on Doorsey."
          }`}
        />
        <link rel="canonical" href={window?.location?.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${title || "Doorsey"}`} />
        <meta
          name="twitter:description"
          content={`${
            description ||
            "View due diligence, schedule tours, ask questions, and submit bids on Doorsey."
          }`}
        />
        <meta property="og:url" content="https://rummr.ecommerce.auction" />
        <meta name="og:image:alt" property="og:image:alt" content={logo} />
        <meta property="og:image" content={logo} />
        <meta property="og:image:secure_url" content={logo} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
      </Helmet>
    </>
  );
};

export default SEO;
