import React, { useState, useEffect, useContext } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import BidsListing from "../../../components/molecules/Dashboard/BidsListing";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";

const Bids = (props) => {
  const { userDetails } = useContext(GlobalContext);
  const [closedData, setClosedData] = useState([]);

  useEffect(() => {
    Object.keys(userDetails.get).length !== 0 && getData();
  }, [userDetails.get]);
  const site_id = localStorage.getItem("site_id");

  const getData = async () => {
    const { data } = await axios.post(
      "myBids",
      { status: "closed" },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      setClosedData(data.result);
    }
  };
  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head">
          My Bids (Closed Properties){" "}
          <span>{closedData.length} Properties</span>
        </h2>
      </div>
      <BidsListing data={closedData} from="bids" />
    </DBLayout>
  );
};

export default Bids;
