import axios from "../utils/axiosconfig";

const getUserProfileData = async (site_id) => {
  let { data } = await axios.get("userprofile/getProfileData", {
    headers: {
      site_id,
    },
  });
  if (
    data.userDetails &&
    Array.isArray(data.userDetails) &&
    data.userDetails.length !== 0
  ) {
    return {
      userDetails: data.userDetails[0],
      cardDetails: data.cardDetails,
      my_agent: data.my_agent,
    };
  } else {
    return false;
  }
};

const getUserInviteSent = async (site_id) => {
  let { data } = await axios.get("api/my_invite_sent", {
    headers: {
      site_id,
    },
  });
  if (data?.status) {
    return data.data;
  } else {
    return [];
  }
};

const getUserInviteReceived = async (site_id) => {
  let { data } = await axios.get("api/my_invite_received", {
    headers: {
      site_id,
    },
  });
  if (data?.status) {
    return data.data;
  } else {
    return [];
  }
};

const saveProfileData = async (body, site_id) => {
  let { data } = await axios.post("userprofile/saveprofiledata", body, {
    headers: {
      site_id,
    },
  });
  if (data.status === true) {
    return data.result;
  } else {
    return false;
  }
};

const getUserData = async (body, site_id) => {
  let { data } = await axios.get(
    "userDetails",
    { params: body },
    {
      headers: {
        site_id,
      },
    },
  );
  if (data.status === true) {
    return data;
  } else {
    return false;
  }
};

const getUserData2 = async (usename, site_id) => {
  console.log(site_id, "checksite_id");
  let { data } = await axios.get(`userDetails?username=${usename}`, {
    headers: {
      site_id,
    },
  });
  if (data.status === true) {
    return data;
  } else {
    return false;
  }
};

const logout = async () => {
  await axios.post("deletesessionn", {});
  return true;
};

const getAllReview = async (site_id) => {
  let { data } = await axios.get("allReview", {
    headers: {
      site_id,
    },
  });
  if (data.status === true) {
    return data.result;
  } else {
    return false;
  }
};

const getLocation = async (site_id) => {
  let { data } = await axios.get("api/getLocations", {
    headers: {
      site_id,
    },
  });
  if (data && data.status === true) {
    return data.data;
  } else {
    return false;
  }
};

const checkUser = async (payload, site_id) => {
  let { data } = await axios.post("userprofile/checkUserProfile", payload, {
    headers: {
      site_id,
    },
  });
  return data;
};

export {
  getUserProfileData,
  logout,
  saveProfileData,
  getUserData,
  getUserData2,
  getAllReview,
  getLocation,
  getUserInviteSent,
  getUserInviteReceived,
  checkUser,
};
