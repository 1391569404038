import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { NavLink } from "react-router-dom";
import "./payments.css";

const Payments = (props) => {
  const transactions = [
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      transaction_id: "MEDS-4523422456ds",
      transaction_on: "21/01/2021",
      amount: "$ 299",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      transaction_id: "MEDS-4523422456ds",
      transaction_on: "21/01/2021",
      amount: "$ 299",
    },
    {
      id: "A-12452",
      auction_name: "2495 Crescent Park East, Unit 2…",
      transaction_id: "MEDS-4523422456ds",
      transaction_on: "21/01/2021",
      amount: "$ 299",
    },
  ];

  return (
    <DBLayout>
      <ul className="list-inline payment-links text-right">
        <li className="list-inline-item">
          <NavLink to="/payment" activeClassName="active">
            Transaction
          </NavLink>
        </li>
        <li className="list-inline-item">
          <NavLink to="/cards" activeClassName="active">
            Cards
          </NavLink>
        </li>
      </ul>
      <div className="table-responsive">
        <table className="table table-inverse">
          <thead>
            <th>Auction Id</th>
            <th>Auction Name</th>
            <th>Transaction Id</th>
            <th>Transacted On</th>
            <th>Amount</th>
          </thead>
          <tbody>
            {transactions.map((item, index) => {
              return (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.auction_name}</td>
                  <td>{item.transaction_id}</td>
                  <td>{item.transaction_on}</td>
                  <td>{item.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DBLayout>
  );
};

export default Payments;
