import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import ListingCard from "../../atoms/ListingCard";

const UpcomingList = (props) => {
  return (
    <>
      {props.data.map((item, index) => {
        return <ListingCard item={item} upcoming from="upcoming" />;
      })}
    </>
  );
};

export default UpcomingList;
