import React, { useContext, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { toast } from "react-toastify";
import DropDown from "../../../components/atoms/DropDown";
import axios from "../../../utils/axiosconfig";
import "./sellerCalendar.css";
import { getAvailability, getAvailSlots } from "../../../API/propertyDetails";

const SellerCalendar = () => {
  let dropDownCalendar = [
    {
      id: 0,
      value: "Private Calendar",
    },
    {
      id: 1,
      value: "Open Calendar",
    },
  ];

  /*Private calenadr State */
  const [selectedSlot, setSelectedSolt] = useState([]);
  /*open calendar State */
  const [openSlot, setOpenSlot] = useState([]);
  const [selectCalender, setSelectCalendar] = useState(0);
  const [productList, setProductList] = useState([]);
  const [myListing, setMylisting] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [reloadProd, setReloadProd] = useState(0);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  /* Api call for the product listing*/
  const getData = async () => {
    const { data } = await axios.get("mylisting", {
      headers: { site_id },
    });
    if (data.status === true) {
      setReloadProd(1);
      setMylisting(data.result.MyListings);
    }
  };

  /*Random ID generator*/
  const myRnId = () => parseInt(Date.now() * Math.random());

  /*Full Calendar for private calendar*/
  const handleDateClick = (arg) => {
    let timeDiffFormat = "HH:mm:ss";
    let startingTime = moment("08:30:00", timeDiffFormat);
    let EndingTime = moment("18:30:00", timeDiffFormat);
    let startDate = moment(arg.startStr).format("HH:mm:ss");
    let endDate = moment(arg.endStr).format("HH:mm:ss");
    let startMin = moment(startDate, timeDiffFormat);
    let endMin = moment(endDate, timeDiffFormat);
    let timeDiff = moment.duration(endMin.diff(startMin));
    let timeDiffMin = timeDiff.asMinutes();

    if (timeDiffMin === 30 && selectCalender === 0) {
      let selectSlot = {
        id: myRnId(),
        start: arg.startStr,
        end: arg.endStr,
      };
      let copiedSlots = [...selectedSlot];
      copiedSlots.push(selectSlot);
      setSelectedSolt(copiedSlots);
    } else if (timeDiffMin > 30 && selectCalender === 0) {
      toast.warning("Time frame should be only 30 mins");
    } else if (selectCalender === 1) {
      let openSlots = {
        id: myRnId(),
        start: arg.startStr,
        end: arg.endStr,
      };
      let copiedOpenSlots = [...openSlot];
      copiedOpenSlots.push(openSlots);
      setOpenSlot(copiedOpenSlots);
    }
  };
  const handleClickEvent = (clickInfo) => {
    // clickInfo.event.remove();
    setSelectedSolt((old) => {
      let newEntry = old.filter((oldentry) => {
        return oldentry.id.toString() !== clickInfo.event.id.toString();
      });
      return newEntry;
    });
  };

  /* Dropdown change for calendar */
  const dropDownCalendarChange = async (value) => {
    setSelectCalendar(parseInt(value));
    if (parseInt(value) === 0) {
      let availCronSlotPriv = {
        id: selectedProduct,
        type: 0,
      };

      getSlotAvailability(availCronSlotPriv, site_id)
        .then((res) => {
          setSelectedSolt(res);
        })
        .catch((err) => {
          console.log("err====>", err);
        });
    } else if (parseInt(value) === 1) {
      try {
        let availCronSlotOpen = {
          id: selectedProduct,
          type: 1,
        };
        let data = await getAvailSlots(availCronSlotOpen, site_id);
        let availCronOpenSlot = data.slots.map((ele) => {
          let availSolt = {
            start: ele.start,
            end: ele.end,
            id: myRnId(),
          };
          return availSolt;
        });
        setOpenSlot(availCronOpenSlot);
      } catch (err) {
        console.log(err, "==> err");
      }
    }
  };

  /*  Dropdown change for property*/
  const dropDownPropertyChange = async (value) => {
    console.log(value + " dropdownpropertychange");
    setSelectedProduct(parseInt(value));
    if (selectCalender === 0) {
      let availCronSlotPriv = {
        id: parseInt(value),
        type: 0,
      };

      getSlotAvailability(availCronSlotPriv, site_id)
        .then((res) => {
          setSelectedSolt(res);
        })
        .catch((err) => {
          toast.error("Unable to Fetch Slots for this Property");
          setSelectedSolt([]);
          console.log("err====>", err);
        });
    } else if (selectCalender === 1) {
      try {
        let availCronSlotOpen = {
          id: parseInt(value),
          type: 1,
        };
        let data = await getAvailSlots(availCronSlotOpen, site_id);
        let availCronOpenSlot = data.slots.map((ele) => {
          let availSolt = {
            start: ele.start,
            end: ele.end,
            id: myRnId(),
          };
          return availSolt;
        });
        setOpenSlot(availCronOpenSlot);
      } catch (err) {
        toast.error("Unable to Fetch Slots for this Property");
        setSelectedSolt([]);
        console.log(err, "==> err");
      }
    }
  };

  /* Default Setting value for the dropdown and adding the extra key name and value for the property dropdown */
  useEffect(() => {
    if (
      myListing &&
      typeof myListing !== "undefined" &&
      myListing.length !== 0 &&
      reloadProd === 1
    ) {
      let productDropdownList = myListing
        .filter((ele) => ele.calStatus === "true")
        .map((ele) => {
          ele.value = ele.loc_address;
          return ele;
        });
      // {
      //   let addedValue = ele;
      //   addedValue.value = ele.title;
      //   return ele;
      // });
      setReloadProd(0);
      console.log(myListing[0].id);
      setSelectedProduct(myListing[0].id);
      setProductList(productDropdownList);
    }
  }, [myListing]);

  /*Getting the product listing */
  useEffect(() => {
    getData();
  }, []);

  const getSlotAvailability = async (availCronSlot) => {
    let data = await getAvailSlots(availCronSlot, site_id);
    let availCronOpenSlot = data.data.map((ele) => {
      return ele.available_periods;
    });

    let availCronPrivateSloted = singlePrivateCalendar(availCronOpenSlot);

    let cronPrivateSlotIdAdded = availCronPrivateSloted.map((ele) => {
      let availSolt = {
        start: ele.start,
        end: ele.end,
        id: myRnId(),
      };
      return availSolt;
    });
    return cronPrivateSlotIdAdded;
  };

  /*Default Calendar Loading UseEffect*/
  // useEffect(() => {
  //   if (
  //     selectCalender === 0 &&
  //     selectedProduct &&
  //     typeof selectedProduct !== "undefined"
  //   ) {
  //     let availCronSlotOpen = {
  //       id: selectedProduct,
  //       type: 0,
  //     };

  //     getSlotAvailability(availCronSlotOpen)
  //       .then((res) => {
  //         setSelectedSolt(res);
  //       })
  //       .catch((err) => {
  //         console.log("err====>", err);
  //       });
  //   }
  // }, [selectCalender, selectedProduct]);

  // const splitedArrayPrivateCalendar = () => {
  //   let splitedArray = [];

  //   let i,
  //     j,
  //     tempArray,
  //     arraySplit = 18;

  //   for (i = 0, j = selectedSlot.length; i < j; i += arraySplit) {
  //     tempArray = selectedSlot.slice(i, i + arraySplit);
  //     splitedArray.push(tempArray);
  //   }
  //   return splitedArray;
  // };

  /*Onclick save function for the private calendar*/
  const savePrivateCal = async () => {
    let privCalParm = {
      id: selectedProduct,
      type: 0,
      slots: selectedSlot,
    };

    let data = await getAvailability(privCalParm, site_id);
    if (data.status) {
      try {
        let data_ = await getAvailSlots(
          {
            id: selectedProduct,
            type: 0,
          },
          site_id,
        );

        let availCronPrivateSlot = data_.data.map((ele, index) => {
          return ele.available_periods;
        });

        let availCronPrivateSloted =
          singlePrivateCalendar(availCronPrivateSlot);

        let cronPrivateSlotIdAdded = availCronPrivateSloted.map((ele) => {
          let availSolt = {
            start: ele.start,
            end: ele.end,
            id: myRnId(),
          };
          return availSolt;
        });
        if (data_.status) {
          toast.success("Private slots has been saved successfully");
        }
        setSelectedSolt(cronPrivateSlotIdAdded);
      } catch (err) {
        toast.error("Unable to Save Slots, Something Happened");
        console.log(err, "==> err from get avalabitlity");
      }
    }
  };

  const singlePrivateCalendar = (arr) => {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? singlePrivateCalendar(toFlatten) : toFlatten,
      );
    }, []);
  };
  /*Onclick save function for the open calendar*/
  const saveOpenCal = async () => {
    let openCalParm = {
      id: selectedProduct,
      type: 1,
      slots: openSlot,
    };

    let data = await getAvailability(openCalParm, site_id);

    if (data.status) {
      let availCronSlotOpen = {
        id: selectedProduct,
        type: 1,
      };
      let data_ = await getAvailSlots(availCronSlotOpen, site_id);
      let availCronOpenSlot = data_.slots.map((ele) => {
        let availSolt = {
          start: ele.start,
          end: ele.end,
          id: myRnId(),
        };
        return availSolt;
      });
      if (data_.status) {
        toast.success("Open slots has been saved successfully");
      }
      setOpenSlot(availCronOpenSlot);
    }
  };

  /* Delete Event for the private calendar */
  const handleDateDeletePrivateCalendar = (arg) => {
    let copiedPrivateEvent = [...selectedSlot];
    let selectedSlotIndex = copiedPrivateEvent.findIndex(
      (a) => Number(a.id) === Number(arg.event.id),
    );
    copiedPrivateEvent.splice(selectedSlotIndex, 1);
    setSelectedSolt(copiedPrivateEvent);
  };

  /* Delete Event for the open calendar */
  const handleDateDeleteOpenCalendar = (arg) => {
    let copiedOpenedEvent = [...openSlot];
    let selectedSlotIndex = copiedOpenedEvent.findIndex(
      (a) => Number(a.id) === Number(arg.event.id),
    );
    copiedOpenedEvent.splice(selectedSlotIndex, 1);
    setOpenSlot(copiedOpenedEvent);
  };

  return (
    <DBLayout>
      <div className="seller-calendar-view">
        <DropDown
          initialOptionView={false}
          value={selectCalender}
          option={dropDownCalendar}
          setFilteredData={dropDownCalendarChange}
        />
        <DropDown
          initialOptionView={false}
          value={selectedProduct}
          option={productList}
          setFilteredData={dropDownPropertyChange}
        />
      </div>
      {selectCalender === 0 ? (
        <FullCalendar
          timeZone="UTC"
          initialView="timeGridWeek"
          headerToolbar={{
            left: "title",
            center: "",
            right: "savePrivateCalendar today prev,next",
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          selectable={true}
          events={selectedSlot}
          select={handleDateClick}
          customButtons={{
            savePrivateCalendar: {
              text: "Save Private Calendar",
              click: savePrivateCal,
            },
          }}
          eventClick={handleDateDeletePrivateCalendar}
          // weekends={false}
          validRange={{
            start: new Date(),
          }}
          editable={false}
          droppable={false}
        />
      ) : (
        <FullCalendar
          timeZone="UTC"
          initialView="timeGridWeek"
          headerToolbar={{
            left: "title",
            center: "",
            right: "saveOpenCalendar today prev,next",
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          selectable={true}
          events={openSlot}
          select={handleDateClick}
          eventClick={handleDateDeleteOpenCalendar}
          customButtons={{
            saveOpenCalendar: {
              text: "Save Open Calendar",
              click: saveOpenCal,
            },
          }}
          validRange={{
            start: new Date(),
          }}
          editable={false}
          droppable={false}
        />
      )}
    </DBLayout>
  );
};
export default SellerCalendar;
