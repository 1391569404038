import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DropDown = (props) => {
  const classes = useStyles();
  let {
    initialOptionView,
    value,
    option,
    setFilteredData,
    inputLabel,
    inital,
    selectName,
  } = props;
  const handleChange = (e) => {
    setFilteredData(e.target.value);
  };
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="age-native-helper">{inputLabel}</InputLabel>
      <NativeSelect
        value={value}
        onChange={handleChange}
        name={selectName}
        className={classes.selectEmpty}
        inputProps={{ "aria-label": { selectName } }}
      >
        {initialOptionView ? <option>{inital}</option> : null}
        {option.map((ele) => (
          <option key={ele.id} value={ele.id}>
            {ele.value}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
export default DropDown;
