import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import ListingCard from "../../atoms/ListingCard";

const WatchlistList = (props) => {
  return (
    <>
      {props.data.map((item, index) => {
        return <ListingCard item={item} key={index} from="wishlist" />;
      })}
    </>
  );
};

export default WatchlistList;
