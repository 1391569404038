import React from "react";
import Layout from "../../../components/molecules/Layout";
import AuthLayout from "../../../components/molecules/AuthLayout";
import SubmitListingForm from "../../../components/molecules/SubmitListingForm";

const SubmitListing = (props) => {
  return (
    <AuthLayout hide_logo={true}>
      <div className="syh-main-wrapper">
        <h1 className="sls-head">Sell Your Home with Doorsey</h1>
        <p className="sls-form-note">
          Fill out the form below to submit a property for review. Our team will
          take a look at the details of your property using the information you
          supply in addition to public data. Please expect to hear from us
          within 48 hours about your approval status.
        </p>
        <SubmitListingForm />
      </div>
    </AuthLayout>
  );
};

export default SubmitListing;
