import React, { useState, Fragment, useEffect } from "react";
import UserVerificationStepLayout from "../../atoms/UserVerificationStepLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../atoms/Inputs/CustomInput";
import MapInput from "../../atoms/Inputs/MapInput";
import PrimaryButton from "../../atoms/PrimaryButton";
import {
  checkCardNumber,
  checkCardHolderName,
  checkCVV,
  checkYear,
  checkMonth,
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

function BillingInformation(props) {
  const [hoverClassName, setHoverClassName] = useState("loading-hover");

  useEffect(() => {
    if (props.isPreviousComplete && props.userBillingInformation.length === 0) {
      props.setShowBillingForm(true);
    } else {
      props.setShowBillingForm(false);
    }
  }, [props.isPreviousComplete, props.userBillingInformation]);

  const BillingInformationSchema = Yup.object({
    zip: Yup.string()
      .required("Enter Zip")
      .matches(/^[0-9]+$/, "Numbers only")
      .min(5, "5 Numbers min")
      .max(5, "5 Numbers max"),
    cardname: Yup.string()
      .max(50, "Maximum 50 characters")
      .required("Enter name")
      .test("testName", "Enter valid name", checkCardHolderName),
    cardnumber: Yup.string()
      .required("Enter card details")
      .test("testCard", "Enter valid card number", checkCardNumber),
    cardmonth: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Month")
      .test("testMonth", "Enter valid month", checkMonth),
    cardyear: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Year")
      .test("testYear", "Enter valid year", checkYear),
    cardcvc: Yup.string()
      .required("Enter CVV")
      .test("testcvv", "Enter valid CVV", checkCVV),
    phone: Yup.string()
      .required("Enter Mobile Number")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      cardname: "",
      cardnumber: "",
      cardmonth: "",
      cardyear: "",
      cardcvc: "",
      phone: "",
    },
    validationSchema: BillingInformationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!props.isBillingLoading) {
        props.handleBillingInformationUpdate(values);
      }
    },
  });

  const handlesubmitRegister = () => {
    return (
      <span className="update">
        Complete Buyer Registration
        {props.isBillingLoading ? (
          <span>
            <CircularProgress className={`loading ${hoverClassName}`} />
          </span>
        ) : (
          ""
        )}
      </span>
    );
  };

  const handleSubmitButtonEnter = () => {
    if (hoverClassName !== "loading-hover") {
      setHoverClassName("loading-hover");
    }
  };

  const handleSubmitButtonLeave = () => {
    if (hoverClassName !== "loading-non-hover") {
      setHoverClassName("loading-non-hover");
    }
  };

  return (
    <UserVerificationStepLayout
      step={props.step}
      title="BILLING INFORMATION"
      isComplete={props.isComplete}
      disable={!props.isPreviousComplete}
    >
      <div className="finance-step-block">
        <div>
          <p>
            Add a card on file to complete verification. You will not be charged
            until giving our team approval to do so
          </p>
        </div>
        <hr />

        {/* Step 2 */}
        {props.showBillingForm ? (
          <div className="finance-form-section">
            <div className="left-section">
              <form
                className="form m-0"
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                noValidate
              >
                <div className="auth-form-wrapper">
                  <div
                    className={`rtb-form-wrapper ${
                      props.isBillingLoading ? "register-load" : ""
                    }`}
                  >
                    <p className="mt-2 mt-md-3">
                      All verified Doorsey Buyers must have a credit card on
                      file. For more information, click{" "}
                      <a
                        href="https://www.doorsey.com/how-it-works"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        here
                      </a>
                    </p>
                    <div className="row">
                      <div className="col-12 mt-3 mb-3">
                        <CustomInput
                          label="Name (as it appears on card)"
                          value={values.cardname}
                          name="cardname"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.cardname && touched.cardname && "is-invalid"
                          }`}
                          placeholder="John Doe"
                          required
                        />
                        {errors.cardname && touched.cardname ? (
                          <div className="invalid-feedback">
                            {errors.cardname}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 mb-3 crdNmbrWtIcon">
                        <div className="crdIcons">
                          <img src="/images/visa.png" />
                          <img src="/images/master.png" />
                          <img src="/images/discover.png" />
                          <img src="/images/amex.png" />
                        </div>
                        <CustomInput
                          label="Card Number"
                          value={values.cardnumber}
                          name="cardnumber"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.cardnumber &&
                            touched.cardnumber &&
                            "is-invalid"
                          }`}
                          placeholder="1111 2222 3333 4444"
                          required
                          inputProps={{
                            maxLength: 16,
                          }}
                        />
                        {errors.cardnumber && touched.cardnumber ? (
                          <div className="invalid-feedback">
                            {errors.cardnumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-5 mb-3">
                        <div className="expiration">
                          <div>
                            <CustomInput
                              label="Expiration*"
                              value={values.cardmonth}
                              name="cardmonth"
                              size="small"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 2 }}
                              className={`hh-input ${
                                errors.cardmonth &&
                                touched.cardmonth &&
                                "is-invalid"
                              }`}
                              placeholder="MM"
                            />
                            {errors.cardmonth && touched.cardmonth ? (
                              <div className="invalid-feedback">
                                {errors.cardmonth}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <CustomInput
                              label=""
                              value={values.cardyear}
                              name="cardyear"
                              size="small"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{ maxLength: 4 }}
                              className={`hh-input ${
                                errors.cardyear &&
                                touched.cardyear &&
                                "is-invalid"
                              }`}
                              placeholder="YY"
                              required
                            />
                            {errors.cardyear && touched.cardyear ? (
                              <div className="invalid-feedback">
                                {errors.cardyear}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mb-3 cvv">
                        <CustomInput
                          label="CVV"
                          value={values.cardcvc}
                          name="cardcvc"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.cardcvc && touched.cardcvc && "is-invalid"
                          }`}
                          placeholder="CVV"
                          required
                          type={"password"}
                          inputProps={{
                            maxLength: 4,
                          }}
                        />
                        {errors.cardcvc && touched.cardcvc ? (
                          <div className="invalid-feedback">
                            {errors.cardcvc}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-4 mb-3">
                        <CustomInput
                          label="Zip Code"
                          value={values.zip}
                          name="zip"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.zip && touched.zip && "is-invalid"
                          }`}
                          placeholder="Zip"
                          required
                        />
                        {errors.zip && touched.zip ? (
                          <div className="invalid-feedback">{errors.zip}</div>
                        ) : null}
                      </div>
                    </div>
                    <Fragment>
                      <div className="mb-3">
                        <MapInput
                          label="Billing Address *"
                          value={values.street}
                          name="street"
                          onChange={(data) => {
                            setFieldValue("street", data.address);
                            setFieldValue("city", data.city);
                            setFieldValue("country", data.country);
                            setFieldValue("state", data.state);
                          }}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.street && touched.street && "is-invalid"
                          }`}
                          placeholder="Address"
                          required
                        />
                        {errors.street && touched.street ? (
                          <div className="invalid-feedback">
                            {errors.street}
                          </div>
                        ) : null}
                      </div>
                    </Fragment>
                    <div className="mb-3">
                      <CustomInput
                        label="Phone Number"
                        type="tel"
                        value={values.phone}
                        name="phone"
                        size="small"
                        inputProps={{ maxLength: 14 }}
                        onChange={(e) => {
                          setFieldValue(
                            "phone",
                            normalizePhoneNumberInput(
                              e.target.value,
                              values.phone,
                            ),
                          );
                        }}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.phone && touched.phone && "is-invalid"
                        }`}
                        placeholder="(555) 123 - 4567"
                        required
                      />
                      {errors.phone && touched.phone ? (
                        <div className="invalid-feedback">{errors.phone}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="finance-button-section">
                    <div className="finance-update-submit mobile-lable">
                      <PrimaryButton
                        btnSize="small"
                        label={handlesubmitRegister()}
                        type="submit"
                        onMouseEnter={handleSubmitButtonEnter}
                        onMouseLeave={handleSubmitButtonLeave}
                      />
                    </div>
                    {Array.isArray(props.userBillingInformation) &&
                    Boolean(props.userBillingInformation.length) ? (
                      <div className="finance-update-cancel">
                        <PrimaryButton
                          btnSize="small"
                          label="Cancel"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.setShowBillingForm(false);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.billingErrorOccured ? (
                    <div style={{ color: "red" }} className="mt-3">
                      There was an error submitting your verification details.
                      Please refresh and try again or contact Doorsey support by
                      emailing info@doorsey.com
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
            <div className="question-section righ-section mt-2 mt-md-3">
              For questions or concerns about entering your credit card, please
              contact our support team by{" "}
              <span className="buyer-verf">
                <a
                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Billing Information Support Request`}
                  className="contact-us"
                >
                  clicking here
                </a>
              </span>
              <p className="mt-2">
                Payment security handled by{" "}
                <a href="https://stripe.com/en-in" target="blank">
                  <img src="/images/stripe_logo.png" />
                </a>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Step 3 */}

        {props.userBillingInformation &&
        Array.isArray(props.userBillingInformation) &&
        Boolean(props.userBillingInformation.length) &&
        !props.showBillingForm ? (
          <div className="finance-form-section">
            <div className="left-section">
              {props.width ? (
                <div>
                  If you make a winning bid on a listing, a Doorsey team member
                  will contact you within 24 to confirm your preferred payment
                  method{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="right-section">
              {Array.isArray(props.userBillingInformation) &&
                Boolean(props.userBillingInformation.length) &&
                props.userBillingInformation.map((stripeCard) => (
                  <div className="mb-2">
                    <span style={{ fontWeight: "bold" }}>
                      Card Ending in {stripeCard.last4} added on{" "}
                      {moment(props.userDetails.card_date).format(
                        "MMM DD, YYYY",
                      )}
                    </span>
                  </div>
                ))}
              <div className="mt-2 update-billing">
                <div
                  className={
                    props.showFinanceForm ? "bottom-section-disabled" : ""
                  }
                >
                  <span
                    onClick={() =>
                      props.showFinanceForm
                        ? ""
                        : props.handleUpdateBillingClick()
                    }
                  >
                    Update Card on File
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </UserVerificationStepLayout>
  );
}

export default BillingInformation;
