import React, { useEffect, useState, useContext } from "react";
import PropertyBids from "../Property/PropertyBids";
import moment from "moment";
import GlobalContext from "../../../context/GlobalContext";
import { getTimeInterval, setDecimalPoint } from "../../../utils";

function OfferPractice(props) {
  const [timer, setTimer] = useState({});
  const [submittedPracticeOffer, setSubmittedPracticeOffer] = useState([]);
  const [dummyData, setDummyData] = useState({
    wprice: 1,
    bidcount: 0,
    views: 10,
    date_closed: moment().add({ minutes: "3", seconds: "1" }),
    date_added: moment(),
    user_id: "",
    house_number: "",
    loc_address: "2222 Burberry Road, Irving, Texas 75039, USA",
    market_status: "open",
    sellerTerms: {
      Term1: "Term 1 set by Seller",
      Term2: "Term 2 set by Seller",
      Term3: "Term 3 set by Seller",
    },
  });
  const [nextBidAmount, setNextBidAmount] = useState(501);
  const [myAgent, setMyAgent] = useState({});
  const { currentTime } = useContext(GlobalContext);

  useEffect(() => {
    if (
      props.userDetails &&
      props.userDetails.myAgent &&
      props.userDetails.myAgent.length > 0
    ) {
      setMyAgent({
        agent_company: props.userDetails.myAgent[0].agent_company,
        agent_email: props.userDetails.myAgent[0].agent_email,
        agent_first_name: props.userDetails.myAgent[0].agent_first_name,
        agent_last_name: props.userDetails.myAgent[0].agent_last_name,
        agent_username: props.userDetails.myAgent[0].agent_username,
      });
    }
  }, [props.userDetails]);

  useEffect(() => {
    if (submittedPracticeOffer.length > 0) {
      setDummyData({
        ...dummyData,
        bidcount: submittedPracticeOffer.length,
        wprice:
          submittedPracticeOffer[submittedPracticeOffer.length - 1]
            .proposed_amount,
        date_closed: moment().add({ minutes: "3", seconds: "1" }),
      });
      setNextBidAmount(
        parseInt(
          submittedPracticeOffer[submittedPracticeOffer.length - 1]
            .proposed_amount,
        ) + 500,
      );
    }
  }, [submittedPracticeOffer]);

  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          dummyData.date_added,
          dummyData.date_closed,
          currentTime.get,
          dummyData.market_status,
        ),
      );
  }, [currentTime.get]);

  return (
    <div className="practice-card">
      <h3>Practice Submitting a Bid</h3>
      <div className="practice-box">
        {" "}
        <PropertyBids
          data={dummyData}
          timer={timer}
          nextBidAmount={nextBidAmount}
          from="practice"
          isMobile={false}
          setSubmittedPracticeOffer={setSubmittedPracticeOffer}
          submittedPracticeOffer={submittedPracticeOffer}
          width={props.width}
        />{" "}
      </div>

      <div>
        {submittedPracticeOffer.length > 0
          ? submittedPracticeOffer
              .slice(0)
              .reverse()
              .map((dummyOffer) => (
                <div className="bid-box">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div
                      className={props.width ? "col" : "col-4"}
                      style={{ paddingRight: "2px" }}
                    >
                      {dummyOffer.username}
                    </div>
                    <div className="col text-right">
                      <span className="cb-date">
                        {moment(dummyOffer.created_at).format("MMM DD")} at{" "}
                        {moment(dummyOffer.created_at).format("hh:mm A")}
                      </span>
                      <span>
                        ${setDecimalPoint(dummyOffer.proposed_amount)}
                      </span>{" "}
                      {/* {props.width ? "Offer placed by" : "By"}{" "} */}
                      <br />
                      {/* {props.width ? (
                        <span
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={() =>
                            Object.keys(myAgent).length > 0
                              ? props.history.push(
                                  `/view-account/${myAgent.agent_username}`
                                )
                              : ""
                          }
                          className="agent-info"
                        >
                          {`${
                            Object.keys(myAgent).length > 0
                              ? myAgent.agent_first_name +
                                " " +
                                myAgent.agent_last_name
                              : "Agent Pending"
                          }, ${
                            Object.keys(myAgent).length > 0
                              ? myAgent.agent_company
                              : "Brokerage Pending"
                          }`}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                  {/* {!props.width ? (
                    <div className="row mt-2">
                      <div className="col text-right">
                        <span
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={() =>
                            Object.keys(myAgent).length > 0
                              ? props.history.push(
                                  `/view-account/${myAgent.agent_username}`
                                )
                              : ""
                          }
                        >
                          {`${
                            Object.keys(myAgent).length > 0
                              ? myAgent.agent_first_name +
                                " " +
                                myAgent.agent_last_name
                              : "Agent Pending"
                          }, ${
                            Object.keys(myAgent).length > 0
                              ? myAgent.agent_company
                              : "Brokerage Pending"
                          }`}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              ))
          : ""}
      </div>
    </div>
  );
}

export default OfferPractice;
