import React from "react";
import { Route, Redirect } from "react-router";
import isAuthenticate from "../../utils/auth";
const store_id = window.location.pathname.split("/")[1];
const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("userToken") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  </>
);

const RestrictedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticate() === undefined ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export { PublicRoute, PrivateRoute, RestrictedRoute };
