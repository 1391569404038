import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import qs from "qs";
import socket from "../../../utils/socket";
import GlobalContext from "../../../context/GlobalContext";
import Swal from "sweetalert2";
import { alertWithButton } from "../../../utils";
import { useHistory } from "react-router-dom";

const SchedulingSuccess = (props) => {
  const { userDetails } = useContext(GlobalContext);
  const [status, setStatus] = useState(0);
  const history = useHistory();
  useEffect(() => {
    if (status === 0) {
      Swal.fire({
        title: "Getting profile details...",
        showCancelButton: false,
        showConfirmButton: false,
      });
      Swal.showLoading();
    } else if (status === 2) {
      Swal.fire({
        icon: "success",
        title: "Calendar Created Successfully.",
        showCancelButton: false,
        showConfirmButton: false,
      });
      history.push("/");
    } else if (status === 3) {
      Swal.fire({
        icon: "error",
        title: "Already the calendar is created.",
        showCancelButton: false,
        showConfirmButton: false,
      });
      history.push("/");
    }
  }, [status]);

  useEffect(() => {
    // if (Object.keys(userDetails.get).length !== 0) {
    socket.on("addAccountCalender", (cal) => {
      if (
        cal.status === true
        // cal.response.user_id == userDetails.get["id"]
      ) {
        Swal.hideLoading();
        setStatus(2);
        localStorage.removeItem("cId");
      } else {
        Swal.hideLoading();
        setStatus(3);
      }
    });
    if (props.location.search) {
      let query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (query && query.code) {
        const id = localStorage.getItem("cId");
        if (id) {
          axios.get(
            `${process.env.REACT_APP_API}addAccount/${id}/?code=` + query.code,
          );
        } else {
          alertWithButton("Somethingwent wrong", () => props.history.push("/"));
        }
      }
    }
    // }
  }, []);

  return (
    <>
      {status === 0 ? (
        <div>
          <p>Don't Refresh the page</p>
        </div>
      ) : null}
    </>
  );
};

export default SchedulingSuccess;
