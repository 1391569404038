import * as React from "react";
import { Pagination } from "@material-ui/lab";
import "./pagination.css";

const Page = (props) => {
  const { count, page, siblingCount, onChange, boundaryCount } = props;
  return (
    <div className="pagination">
      <Pagination
        count={count}
        page={page}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        onChange={onChange}
      />
    </div>
  );
};
export default Page;
