import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import MyListingCard from "../../atoms/MyListingCard";

const MyListingsList = ({ data }) => {
  return (
    <>
      {data.map((item, index) => {
        return <MyListingCard item={item} key={index} />;
      })}
    </>
  );
};

export default MyListingsList;
