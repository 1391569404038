import React, { useState, useEffect, useContext } from "react";
import { getPropertyDetails } from "../../../API/propertyDetails";
import "./rating.css";
import Layout from "../../../components/molecules/Layout";
import GridCard from "../../../components/atoms/GridCard";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import TextArea from "../../../components/atoms/Inputs/CustomTextArea";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import GlobalContext from "../../../context/GlobalContext";
import axios from "../../../utils/axiosconfig";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      fontFamily: "Avenir-book",
    },
    "& legend": {
      fontSize: "17px",
      fontWeight: "600",
      marginBottom: "8px",
    },
  },
}));

const Review = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const { userLocation } = useContext(GlobalContext);
  const [value, setValue] = useState(0);
  const [propertyData, setPropertyData] = useState({});
  const [text, setText] = useState("");
  const [errText, setErrText] = useState("");
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    if (Object.keys(userLocation.get).length !== 0 && id !== undefined) {
      getData();
    }
  }, [userLocation.get, id]);

  const getData = async () => {
    const data = await getPropertyDetails(
      id,
      site_id,
      userLocation.get["ipAddress"],
    );
    if (data && data.status === true) {
      setPropertyData(data.result.propertyDetails);
    }
  };

  const onSubmit = async () => {
    if (text.length === 0) {
      setErrText("Enter Review");
      return;
    }
    try {
      let { data } = await axios.post(
        "review",
        {
          project_id: id,
          subject: "",
          message: text,
          rating: value,
        },
        {
          headers: {
            site_id,
          },
        },
      );
      if (data.status === true) {
        toast.success("Review has been updated sucessfully");
        setTimeout(() => {
          props.history.push(`/`);
        }, 2500);
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="container py-4 rtngContainer">
        <h3 className="mb-4">Submit Your Rating</h3>
        <div className="rtngWrapper d-flex align-items-start">
          <div className="pdtCard">
            <GridCard item={propertyData} from="review" />
          </div>
          <div className="rtngWrpr ml-2 p-4">
            <Box
              className={`${classes.root}`}
              component="fieldset"
              mb={3}
              borderColor="transparent"
            >
              <Typography component="legend">Rating</Typography>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Box>
            <div className="rvwWrpr">
              <legend>Comments</legend>
              <TextArea
                rows={4}
                value={text}
                onChange={(e) => {
                  setErrText("");
                  setText(e.target.value);
                }}
              />
              {errText && <p className="text-danger mt-2">{errText}</p>}
              <PrimaryButton label="Submit" onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Review;
