import React, { useState, useEffect, useContext } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { toast } from "react-toastify";
import InviteUser from "../../../components/molecules/Dashboard/InviteUser";
import FinanceVerification from "../../../components/molecules/Dashboard/FinanceVerification";
import BillingInformation from "../../../components/molecules/Dashboard/BillingInformation";
import axios from "../../../utils/axiosconfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import GlobalContext from "../../../context/GlobalContext";
import { getUserProfileData } from "../../../API/userData";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import OfferPractice from "../../../components/molecules/Dashboard/OfferPractice";
import CommentPractice from "../../../components/molecules/Dashboard/CommentPractice";

function UserVerification(props) {
  const [showInviteFrom, setShowInviteForm] = useState(false);
  const [showFinanceForm, setShowFinanceForm] = useState(false);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [isResetOld, setIsResetOld] = useState(false);
  const [isResetNew, setIsResetNew] = useState(false);
  const [sentInviteList, setSentInviteList] = useState([]);
  const [receivedInviteList, setReceivedInviteList] = useState([]);
  const [userFinanceInformation, setUserFinanceInformation] = useState({});
  const [isFinanceLoading, setIsFinanceLoading] = useState(false);
  const [userBillingInformation, setUserBillingInformation] = useState([]);
  const [isBillingLoading, setIsBillingLoading] = useState(false);
  const [billingErrorOccured, setBillingErrorOccured] = useState(false);
  const width = useMediaQuery("(min-width:768px)");
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const {
    verificationStatus,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    userDetails,
  } = useContext(GlobalContext);

  const { status } = useParams();

  useEffect(() => {
    setUserBillingInformation(billingDetails.get);
  }, [billingDetails.get]);

  useEffect(() => {
    setSentInviteList(sentInvites.get);
  }, [sentInvites.get]);

  useEffect(() => {
    setReceivedInviteList(receivedInvites.get);
  }, [receivedInvites.get]);

  useEffect(() => {
    setUserFinanceInformation(financeDetails.get);
  }, [financeDetails.get]);

  //!Invite User Functions Starts Here

  const getInvitesReceived = async () => {
    const res = await axios.get("api/my_invite_received", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        receivedInvites.set(res.data.data);
      } else {
        receivedInvites.set([]);
      }
    }
  };

  const getInvitesSent = async () => {
    const res = await axios.get("api/my_invite_sent", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        sentInvites.set(res.data.data);
      } else {
        sentInvites.set([]);
      }
    }
  };

  const handleSendInviteOld = async (payload) => {
    const res = await axios.post("api/invite_old_user", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        setShowInviteForm(false);
        toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
        updateMyAgent();
      } else {
        toast.warning(res.data.message);
        setIsResetOld(true);
      }
    } else {
      toast.error("Something went wrong");
      setIsResetOld(true);
    }
  };

  const handleSendInviteNew = async (payload) => {
    const res = await axios.post("api/invite_new_user", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        setShowInviteForm(false);
        toast.success(res.data.message);
        getInvitesSent();
        getInvitesReceived();
        updateMyAgent();
      } else {
        toast.warning(res.data.message);
        setIsResetNew(true);
      }
    } else {
      toast.error("Something went wrong");
      setIsResetNew(true);
    }
  };

  const handleInviteAction = async (payload) => {
    const res = await axios.post("api/invite_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        getInvitesSent();
        getInvitesReceived();
        updateMyAgent();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleOfferAction = async (payload) => {
    const res = await axios.post("api/offer_action", payload, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        getInvitesSent();
        getInvitesReceived();
      } else {
        toast.warning(res.data.message);
        getInvitesSent();
        getInvitesReceived();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleAddAgentLater = async () => {
    const res = await axios.post("api/add_agent_later", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        if (res.data.userDetails[0].agent_exempt_request) {
          userDetails.set({
            ...userDetails.get,
            agent_exempt_request: res.data.userDetails[0].agent_exempt_request,
          });
        }
      }
    }
  };

  const updateMyAgent = async () => {
    let { my_agent: newAgent } = await getUserProfileData(site_id);
    userDetails.set({
      ...userDetails.get,
      myAgent: newAgent,
    });
    return true;
  };
  //!Invite User Functions End

  //!Finance Information Functions Start Here

  const handleFinanceInformationUpdate = async (values) => {
    let formData = new FormData();

    formData.append("fin_type", values.financing);
    formData.append("reserve_amount", values.cashlimit);
    // formData.append("phone", `+1 ${values.phone}`);
    formData.append("lenderEmail", values.lenderEmail);
    // values.uploadedFiles.map((ele) => formData.append("uploads", ele));
    formData.append(
      "fin_doc",
      values &&
        values.uploadedFiles[0] &&
        Object.keys(values.uploadedFiles[0]).length > 0
        ? values.uploadedFiles[0].name
        : "",
    );
    formData.append(
      "fin_doc2",
      values &&
        values.uploadedFiles[1] &&
        Object.keys(values.uploadedFiles[1]).length > 0
        ? values.uploadedFiles[1].name
        : "",
    );
    formData.append(
      "fin_doc3",
      values &&
        values.uploadedFiles[2] &&
        Object.keys(values.uploadedFiles[2]).length > 0
        ? values.uploadedFiles[2].name
        : "",
    );
    formData.append(
      "fin_doc4",
      values &&
        values.uploadedFiles[3] &&
        Object.keys(values.uploadedFiles[3]).length > 0
        ? values.uploadedFiles[3].name
        : "",
    );
    formData.append(
      "fin_doc5",
      values &&
        values.uploadedFiles[4] &&
        Object.keys(values.uploadedFiles[4]).length > 0
        ? values.uploadedFiles[4].name
        : "",
    );
    setIsFinanceLoading(true);
    const res = await axios.post("userprofile/updateFinance", formData, {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.success === "yes") {
        let { userDetails: newUserInfo } = await getUserProfileData(site_id);

        financeDetails.set({
          financeDocuments: [
            newUserInfo.fin_doc,
            newUserInfo.fin_doc2,
            newUserInfo.fin_doc3,
            newUserInfo.fin_doc4,
            newUserInfo.fin_doc5,
          ],
          purchasePower: newUserInfo.reserve_amount,
          lenderEmail: newUserInfo.lender_email,
          financeType: newUserInfo.fin_type,
          //phoneNumber: newUserInfo.phone,
        });
        setIsFinanceLoading(false);
        setShowFinanceForm(false);
      } else {
        setIsFinanceLoading(false);
      }
    } else {
      setIsFinanceLoading(false);
    }
  };

  const handleAddFinanceLater = async () => {
    const res = await axios.post("api/add_fin_later", {
      headers: {
        site_id,
      },
    });
    if (res.data) {
      if (res.data.status) {
        if (res.data.userDetails[0].finance_exempt_request) {
          userDetails.set({
            ...userDetails.get,
            finance_exempt_request:
              res.data.userDetails[0].finance_exempt_request,
          });
        }
      }
    }
  };

  //!Finance Information Functions End

  //!Billing Information Functions Start Here

  const handleBillingInformationUpdate = async (values) => {
    let formData = new FormData();

    //location for stripe
    formData.append("line1", values.street);
    formData.append("line2", "");
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("country", values.country);
    formData.append("zip", values.zip);
    formData.append("card_name", values.cardname);
    formData.append("cardNumber", values.cardnumber);
    formData.append("expiryMonth", values.cardmonth);
    formData.append("expiryYear", values.cardyear);
    formData.append("cvv", values.cardcvc);
    formData.append("phone", `+1 ${values.phone}`);

    setIsBillingLoading(true);
    const res = await axios.post("userprofile/updateBilling", formData, {
      headers: {
        site_id,
      },
    });

    if (res.data) {
      if (res.data.success === "yes") {
        let { cardDetails: newCardInfo, userDetails: userInfo } =
          await getUserProfileData(site_id);
        if (newCardInfo.length > 0) {
          billingDetails.set(newCardInfo);
        }
        if (Object.keys(userInfo).length > 0) {
          userDetails.set({
            ...userDetails.get,
            customerid: userInfo.customerid,
            card_name: userInfo.card_name,
            card_date: userInfo.card_updated,
            phone: userInfo.phone,
          });
        }
        setIsBillingLoading(false);
        setShowBillingForm(false);
        history.push(`/buyerverification/confirmation`);
      } else {
        setIsBillingLoading(false);
        toast.warning(res.data.reason);
      }
    } else {
      setIsBillingLoading(false);
      setBillingErrorOccured(true);
    }
  };

  //!Billing Information Functions End

  return (
    <DBLayout>
      {status === "confirmation" &&
      verificationStatus.get["billingInformation"] ? (
        <>
          <div className="row">
            <div className="col">
              <div className="clearfix">
                <h2 className="pp-head">Buyer Verification</h2>
              </div>
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <h3 className="auth-title" style={{ textAlign: "left" }}>
                VERIFICATION COMPLETE
              </h3>
              <p>
                Thank you for submitting your buyer verification details, Your
                account has been verified and you are ready to submit bids on
                Doorsey.
              </p>
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <OfferPractice
                width={width}
                history={history}
                userDetails={userDetails.get}
              />
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <CommentPractice width={width} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="verification-complete-button">
              <PrimaryButton
                btnSize="small"
                label="View Buyer Details"
                onClick={() => history.push(`/buyerverification`)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <div className="clearfix">
                <h2 className="pp-head">
                  {props.type === "Agent"
                    ? `Buyer Verification`
                    : props.type === "Client"
                      ? `Agent Verification`
                      : ""}
                </h2>
              </div>
            </div>
          </div>
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              {width ? (
                <p>
                  Submit your buyer verification details in three easy steps.
                  For questions or concerns, please{" "}
                  <a
                    className="contact-us"
                    href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Buyer Verification Support Request`}
                  >
                    contact us
                  </a>
                </p>
              ) : (
                ""
              )}
            </div>
            {verificationStatus.get["billingInformation"] ? (
              <div className="acc-vefr">
                <p>
                  Your account has been verified to submit bids. Happy
                  homebuying!
                </p>
                <p>
                  <a
                    className="verfied-link"
                    onClick={() =>
                      history.push(`/buyerverification/confirmation`)
                    }
                  >
                    Click here to practice submitting a bid
                  </a>
                </p>
              </div>
            ) : (
              <div className="acc-not-vefr">
                <p>
                  Your account has not been verified to submit bids. Please
                  complete the following steps to become a verified buyer.
                </p>
              </div>
            )}
          </div>

          <InviteUser
            step="1"
            type={props.type}
            width={width}
            sentInviteList={sentInviteList}
            receivedInviteList={receivedInviteList}
            handleOfferAction={handleOfferAction}
            handleInviteAction={handleInviteAction}
            handleInviteUserClick={() => {
              props.type === "Agent" && sentInviteList.length > 0
                ? toast.warning("You can only invite one agent")
                : setShowInviteForm(true);
            }}
            isComplete={verificationStatus.get["userConnection"]}
            handleSendInviteOld={handleSendInviteOld}
            handleSendInviteNew={handleSendInviteNew}
            handleAddAgentLater={handleAddAgentLater}
            isResetNew={isResetNew}
            setIsResetNew={setIsResetNew}
            isResetOld={isResetOld}
            setIsResetOld={setIsResetOld}
            showInviteForm={showInviteFrom}
          />
          <FinanceVerification
            step="2"
            isComplete={verificationStatus.get["financeInformation"]}
            isPreviousComplete={verificationStatus.get["userConnection"]}
            handleIHaveFinancingClick={() => setShowFinanceForm(true)}
            showFinanceForm={showFinanceForm}
            setShowFinanceForm={setShowFinanceForm}
            handleAddFinanceLater={handleAddFinanceLater}
            userFinanceInformation={userFinanceInformation}
            handleFinanceInformationUpdate={handleFinanceInformationUpdate}
            isFinanceLoading={isFinanceLoading}
            showBillingForm={showBillingForm}
          />
          <BillingInformation
            step="3"
            width={width}
            isComplete={verificationStatus.get["billingInformation"]}
            isPreviousComplete={verificationStatus.get["financeInformation"]}
            userBillingInformation={userBillingInformation}
            showBillingForm={showBillingForm}
            setShowBillingForm={setShowBillingForm}
            isBillingLoading={isBillingLoading}
            billingErrorOccured={billingErrorOccured}
            handleBillingInformationUpdate={handleBillingInformationUpdate}
            handleUpdateBillingClick={() => setShowBillingForm(true)}
            userDetails={userDetails.get}
            showFinanceForm={showFinanceForm}
          />
        </>
      )}
    </DBLayout>
  );
}

export default UserVerification;
