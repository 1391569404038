import openSocket from "socket.io-client";

const socket = openSocket(process.env.REACT_APP_API, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 10000,
  //reconnectionAttempts: 50,
  transports: ["websocket"],
});

socket.on("connect", function () {
  console.log("Socket Connected");
});
socket.on("disconnect", () => {
  console.log(socket.connected, "socket is disconnected");
});
export default socket;
