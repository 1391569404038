import React, { useState, useEffect } from "react";
import useToggle from "../../../utils/useToggle";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import WatchlistList from "../../../components/molecules/Dashboard/WatchlistList";
import WatchlistGrid from "../../../components/molecules/Dashboard/WatchlistGrid";
import axios from "../../../utils/axiosconfig";

const Watchlist = (props) => {
  const [list, toggleList] = useToggle(true);
  const [watchList, setWatchList] = useState([]);
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let { data } = await axios.get("/mywishlist", {
      headers: { site_id },
    });
    if (data.status === true) {
      setWatchList(data.result || []);
    }
  };

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">
          Following <span>{watchList.length} Properties</span>
        </h2>
        <div className="glv-wrap float-right">
          <a className={`glv-link ${list && "active"}`} onClick={toggleList}>
            <i className="fa fa-list"></i> List
          </a>
          <a className={`glv-link ${!list && "active"}`} onClick={toggleList}>
            <i className="fa fa-th-large"></i> Grid
          </a>
        </div>
      </div>
      <div className="ms-wrapper">
        {list ? (
          <WatchlistList data={watchList} />
        ) : (
          <WatchlistGrid data={watchList} />
        )}
        {/* <RecentlyAdded /> */}
      </div>
    </DBLayout>
  );
};

export default Watchlist;
