import React, { useContext, useEffect, useState } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import UpcomingList from "../../../components/molecules/Dashboard/UpcomingList";
import GlobalContext from "../../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { getSearch } from "../../../API/search";
import Pagination from "../../../components/atoms/Pagination";
const Upcoming = (props) => {
  const [page, setPage] = useState(1);
  const [totalProp, setTotalProp] = useState(0);
  const { resentProperty, userLocation } = useContext(GlobalContext);
  const site_id = localStorage.getItem("site_id");
  useEffect(() => {
    if (userLocation.get && Object.keys(userLocation.get).length) {
      getData(1);
    }
  }, [userLocation.get]);

  const getData = async () => {
    let searchData = await getSearch(
      {
        cities: [userLocation.get.city],
        page: page,
        limit: 10,
        status: "",
      },
      site_id,
    );
    searchData.status === true &&
      resentProperty.set(searchData.result.productdetails);
    setTotalProp(searchData.total_count);
  };
  const handlePageChange = (event, page) => {
    setPage(page);
    console.log(page, "ths is page");
    getData(page);
  };
  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">
          Upcoming Showings{" "}
          <span>
            {resentProperty.get ? resentProperty.get.length : 0} Properties
          </span>
        </h2>
      </div>
      <div className="ms-wrapper">
        <UpcomingList data={resentProperty.get} />
      </div>
      {/* <RecentlyAdded /> */}
      {resentProperty.get && totalProp > 10 && (
        <div>
          <Pagination
            count={Math.ceil(totalProp / 10)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      )}
    </DBLayout>
  );
};

export default Upcoming;
